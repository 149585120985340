import {
    Component,
    EventEmitter,
    Input,
    Output,
    ChangeDetectorRef,
    OnInit,
} from '@angular/core'
import {
    FilterEnum,
    FilterOptions,
    getFilterIcon,
} from '../../../state/models/filters.models'
import { Creator } from 'src/app/state/models/creator.models'
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/state/store'
import { selectLoggedInUser } from 'src/app/state/store/users/users.selectors'
import { LoggedInUser } from 'src/app/state/models/users.models'

export interface SelectedFilter {
    icon: string
    type: string
    value: string
}

@Component({
    selector: 'avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss'],
})
export class Avatar implements OnInit {
    private subscription = new Subscription()
    public loggedInUser: LoggedInUser | null = null

    @Output() filterAction: EventEmitter<any> = new EventEmitter()
    @Input() creator: Creator = {}
    @Input() avatarWidth: number = 24
    @Input() avatarHeight: number = 24
    @Input() fontSize: number = 10

    constructor(private store: Store<AppState>) {}

    public ngOnInit(): void {
        this.subscription.add(
            this.store.select(selectLoggedInUser).subscribe((loggedInUser) => {
                this.loggedInUser = loggedInUser
            })
        )
    }

    get userFullName(): string {
        const {
            username = '',
            first_name = '',
            last_name = '',
        } = (this.creator as Creator | LoggedInUser) || {}

        if (first_name && last_name) {
            return `${first_name} ${last_name}`
        } else if (first_name) {
            return first_name
        } else if (last_name) {
            return last_name
        } else {
            return username || ''
        }
    }

    public getInitials(user: Creator | LoggedInUser) {
        if (user?.first_name && user?.last_name) {
            return `${user?.first_name.charAt(0)}${user?.last_name.charAt(
                0
            )}`.toUpperCase()
        } else if (user?.first_name && !user?.last_name) {
            return `${user?.first_name.charAt(0)}${user?.first_name?.charAt(
                1
            )}`.toUpperCase()
        } else if (!user?.first_name && user?.last_name) {
            return `${user?.last_name.charAt(0)}${user?.last_name?.charAt(
                1
            )}`.toUpperCase()
        } else {
            return `${user?.username?.charAt(0)}${user?.username?.charAt(
                1
            )}`.toUpperCase()
        }
    }
}
