import { Component, OnDestroy, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { Store, select } from '@ngrx/store'
import { Subscription, filter } from 'rxjs'
import { LocalStorage } from '../state/local-storage'
import { AppState } from '../state/store'
import { login } from '../state/store/users/users.actions'
import {
    selectAccessToken,
    selectIsLoading,
} from '../state/store/users/users.selectors'
import { MatSnackBar } from '@angular/material/snack-bar'
import { selectAllProjects } from '../state/store/projects/projects.selectors'
import {
    getAllProjects,
    getOneProject,
    setActiveProject,
} from '../state/store/projects/projects.actions'

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
    private subscription = new Subscription()
    public isLoading: boolean = false

    constructor(
        private store: Store<AppState>,
        private router: Router,
        private snackbar: MatSnackBar
    ) {
        LocalStorage.clearUsersState()
    }

    public loginForm: FormGroup = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl('', [Validators.required]),
    })

    public loginRequest(): void {
        if (!this.loginForm.valid) {
            return
        }
        const formParams = this.loginForm.getRawValue()
        if (formParams.email && formParams.password)
            this.store.dispatch(
                login({
                    payload: {
                        username: formParams.email,
                        password: formParams.password,
                    },
                })
            )
        else
            this.snackbar.open(`Please enter email and password`, 'Ok', {
                duration: 1200,
            })
    }

    public goToForgotPassword(): void {
        this.router.navigate(['/forgot-password'])
    }

    ngOnInit(): void {
        this.subscription.add(
            this.store
                .pipe(
                    select(selectAccessToken),
                    filter((res) => !!res)
                )
                .subscribe(() => {
                    this.store.dispatch(getAllProjects())
                })
        )

        this.subscription.add(
            this.store
                .pipe(select(selectAllProjects))
                .subscribe((allProjects) => {
                    const projectToSelect = allProjects.find(
                        (project) => project.description === 'private'
                    )
                    if (projectToSelect) {
                        this.store.dispatch(
                            getOneProject({
                                payload: {
                                    id: projectToSelect.id,
                                    filters: { limit: 10 },
                                },
                            })
                        )
                        this.store.dispatch(
                            setActiveProject({ payload: projectToSelect })
                        )
                        this.router.navigate(['/top-attributes'])
                    }
                })
        )

        this.subscription.add(
            this.store
                .pipe(select(selectIsLoading))
                .subscribe((isLoading) => (this.isLoading = isLoading))
        )
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe()
    }
}
