import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    Renderer2,
    ViewChild,
} from '@angular/core'
import { ThemePalette } from '@angular/material/core'
import { MatDialog } from '@angular/material/dialog'
import { ProgressSpinnerMode } from '@angular/material/progress-spinner'
import { Store } from '@ngrx/store'
import { Subscription } from 'rxjs'
import { OmniSelectListI } from 'src/app/state/models/custom-components.models'
import { Item } from 'src/app/state/models/items.models'
import { Project } from 'src/app/state/models/projects.models'

import { Router } from '@angular/router'
import { Filters } from 'src/app/state/models/filters.models'
import { TopAttribute } from 'src/app/state/models/top-attributes.models'
import { AppState } from 'src/app/state/store'
import {
    getAllProjects,
    getOneItem,
    moveItemToProject,
    deleteItem,
} from 'src/app/state/store/projects/projects.actions'
import {
    selectActiveProject,
    selectAllProjects,
} from 'src/app/state/store/projects/projects.selectors'
import { environment } from 'src/environments/environment'
import { formatMLExplainText } from '../../helpers/string-formatters'
import { ConfirmationDialog } from '../../dialogs/confirmation.dialog/confirmation.dialog'
import { selectLoggedInUser } from 'src/app/state/store/users/users.selectors'
import { LoggedInUser } from 'src/app/state/models/users.models'
import { userFullName } from '../../helpers/utils'

@Component({
    selector: 'item-card',
    templateUrl: './item-card.component.html',
    styleUrls: ['./item-card.component.scss'],
})
export class ItemCardComponent implements OnDestroy, AfterViewInit {
    private subscription = new Subscription()
    public activeProject$: Project | null = null
    public thumbnailURL = ''
    public showElements = false
    public showMoreInfo = false
    public showRating = false
    public loggedInUser: LoggedInUser | null = null

    public allProjects$: Array<Project> = []
    public itemTooltip = ''
    public _activeProject$: Project | undefined
    public selectList: Array<OmniSelectListI> = []

    public helpTooltipPosition: any
    public ratingBarColor: ThemePalette = 'primary'
    public ratingBarMode: ProgressSpinnerMode = 'determinate'
    public ratingBarsDiameter: number = 60
    public mocRatingList: Array<{
        title: string
        rating: string
        ratingPerc: number
    }> = [
        { title: 'On Brand', rating: '4.4', ratingPerc: 20 * 4.4 },
        { title: 'On Trend', rating: '4.8', ratingPerc: 20 * 4.8 },
        { title: 'On Demo', rating: '3.8', ratingPerc: 20 * 3.8 },
        { title: 'Reliability', rating: '4.0', ratingPerc: 20 * 4.0 },
    ]

    public filterTypes: string[] = [
        'item_type',
        'department',
        'category',
        'sub_category',
        'trend_type',
    ]

    public chips: TopAttribute[] = []

    get moveItemToProject$(): Project | undefined {
        return this._activeProject$
    }
    set moveItemToProject$(value: Project | undefined) {
        if (value !== undefined) {
            this.moveItemToProject(value)
        }
        this._activeProject$ = value
    }
    public notEnoughSpace: boolean = false

    public activeFilters: Filters = {}
    public userFullName = userFullName

    @Input() item: Item | null = null
    @Output() onLikeItem: EventEmitter<Item> = new EventEmitter()
    @Output() filterAction: EventEmitter<any> = new EventEmitter()
    @ViewChild('tooltipContainer') tooltipContainer!: ElementRef
    @ViewChild('tooltip') tooltip!: ElementRef

    constructor(
        private store: Store<AppState>,
        private dialog: MatDialog,
        private router: Router,
        private renderer: Renderer2
    ) {}

    ngAfterViewInit() {
        if (this.tooltipContainer) {
            this.tooltipContainer.nativeElement.addEventListener(
                'mouseenter',
                () => {
                    const tooltipWidth = this.tooltip.nativeElement.offsetWidth
                    const tooltipContainerWidth =
                        this.tooltipContainer.nativeElement.offsetWidth
                    this.ratingBarsDiameter = (tooltipContainerWidth * 0.55) / 4
                    const containerLeft =
                        this.tooltipContainer.nativeElement.getBoundingClientRect()
                            .left
                    const isEnoughSpace = containerLeft - tooltipWidth > 0

                    if (!isEnoughSpace) {
                        this.helpTooltipPosition = `${
                            tooltipContainerWidth - 20
                        }px`
                        this.notEnoughSpace = true
                    }
                }
            )

            this.tooltipContainer.nativeElement.addEventListener(
                'mouseleave',
                () => {
                    this.helpTooltipPosition = '0px'
                    this.notEnoughSpace = false
                }
            )
        }
    }

    public openItemDialog(itemId?: string) {
        if (itemId) {
            this.store.dispatch(getOneItem({ payload: itemId }))
        }
    }

    public openItemPage(itemId?: string) {
        if (itemId) {
            this.router.navigate(['item', itemId])
        }
    }

    public moveItemToProject(project?: OmniSelectListI) {
        if (this.item?.id && project?.id) {
            this.store.dispatch(
                moveItemToProject({
                    payload: {
                        itemId: this.item.id,
                        projectId: project.id,
                    },
                })
            )
        }
    }

    public likeItem(e: Event) {
        e.stopPropagation()
        if (this.item) {
            this.onLikeItem.emit(this.item)
        }
    }

    public preventDefault(e: Event) {
        e.preventDefault()
    }

    public stopPropagation(e: Event) {
        e.stopPropagation()
    }

    ngOnInit() {
        this.store.dispatch(getAllProjects())
        if (this.item?.top_attributes) {
            this.chips = this.item?.top_attributes
        }

        this.subscription.add(
            this.store
                .select(selectLoggedInUser)
                .subscribe((loggedInUser) => (this.loggedInUser = loggedInUser))
        )

        this.subscription.add(
            this.store.select(selectAllProjects).subscribe((allProjects) => {
                this.allProjects$ = allProjects
                this.selectList = this.allProjects$.map((item) => {
                    return { name: item.name, id: item.id }
                })
            })
        )

        this.subscription.add(
            this.store
                .select(selectActiveProject)
                .subscribe((activeProject) => {
                    if (activeProject) {
                        this.activeProject$ = activeProject
                    }
                })
        )

        if (this.item?.images)
            this.thumbnailURL = `${environment.CDN_URL}/${this.item.images[0]?.img_hash}-0.png`
        if (this.item?.ml_explain)
            this.itemTooltip = formatMLExplainText(this.item.ml_explain)
    }

    selectChip(selectedChip: TopAttribute): void {
        this.activeFilters = {
            item_type: [selectedChip.item_type],
            department: [selectedChip.department],
            category: [selectedChip.category],
            sub_category: [selectedChip.sub_category],
            trend_type: [selectedChip.trend_type],
        }

        this.filterAction.emit(this.activeFilters)
    }

    clearHoverElements() {
        this.showElements = false
        this.showMoreInfo = false
        this.showRating = false
    }

    deleteItem() {
        const dialogRef = this.dialog.open(ConfirmationDialog, {
            data: 'Are you sure you want to delete this item?',
        })
        dialogRef.afterClosed().subscribe((result) => {
            if (result && this.item?.id) {
                this.store.dispatch(
                    deleteItem({ payload: { id: this.item.id } })
                )
            }
        })
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe()
    }
}
