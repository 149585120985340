<div class="login-page">
    <div class="login-container">
        <div class="login-logo">
            <img src="assets/images/logo.png" alt="logo" />
        </div>
        <form [formGroup]="forgotPasswordForm">
            <div class="login-inputs">
                <mat-form-field class="mat-form-field" appearance="fill">
                    <mat-label class="label-color">Enter Email</mat-label>
                    <input matInput formControlName="email" type="email" />
                    <mat-error
                        *ngIf="
                            forgotPasswordForm
                                .get('email')
                                ?.hasError('required')
                        "
                    >
                        Email is required
                    </mat-error>
                    <mat-error
                        *ngIf="
                            forgotPasswordForm.get('email')?.hasError('email')
                        "
                    >
                        Please enter a valid email address
                    </mat-error>
                </mat-form-field>
                <button
                    mat-raised-button
                    class="login-button"
                    color="primary"
                    [disabled]="isLoading"
                    (click)="resetPasswordRequest()"
                >
                    <span>Submit</span>
                    <mat-spinner
                        *ngIf="isLoading"
                        class="ml-2"
                        [diameter]="20"
                    ></mat-spinner>
                </button>
                <div class="d-flex justify-content-end mt-4">
                    Already registered?
                    <span
                        class="ml-1 already-login pointer"
                        (click)="goToLogin()"
                        >Login</span
                    >
                </div>
            </div>
        </form>
    </div>
</div>
