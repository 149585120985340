import { COMMA, ENTER } from '@angular/cdk/keycodes'
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild,
} from '@angular/core'
import { FormControl } from '@angular/forms'
import { Observable, map, startWith } from 'rxjs'
import {
    FilterEnum,
    Filters,
    getFilterIcon,
} from 'src/app/state/models/filters.models'

@Component({
    selector: 'filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss'],
})
export class FilterComponent {
    @Input() filterType: string = ''
    @Input() allFilters: string[] | null = []
    @Input() activeFilters: Filters = {}
    @Output() filterAction: EventEmitter<any> = new EventEmitter()
    @ViewChild('filterInput') filterInput!: ElementRef<HTMLInputElement>

    separatorKeysCodes: number[] = [ENTER, COMMA]

    private get selectedValue(): any {
        return this.activeFilters[this.filterType as keyof Filters] || []
    }

    filterControl = new FormControl('')
    public getFilterIcon: Function = getFilterIcon

    get filterOptions(): string[] {
        const searchQuery: string | null = this.filterControl.value
        const allOptions: string[] = this.allFilters || []

        const filteredOptions: string[] = searchQuery
            ? this._filter(searchQuery, allOptions)
            : allOptions

        const result = filteredOptions.filter(
            (option: string) => !this.selectedValue.includes(option)
        )

        return result
    }

    remove(): void {
        this.filterInput.nativeElement.value = ''
        this.filterControl.setValue(null)

        const newFilterValue = {
            [this.filterType]: null,
        }

        this.filterAction.emit(newFilterValue)
    }

    onFilterOptionSelected(selectedOpt: string): void {
        this.filterInput.nativeElement.value = ''
        this.filterControl.setValue(null)

        const newFilterValue = {
            [this.filterType]: [...this.selectedValue, selectedOpt],
        }
        this.filterInput.nativeElement.blur()

        this.filterAction.emit(newFilterValue)
    }

    private _filter(value: string, allOptions: string[]): string[] {
        const filterValue = value.toLowerCase()
        return allOptions.filter((option) =>
            option.toLowerCase().includes(filterValue)
        )
    }

    filterPlaceholder(filterType: string): string {
        return FilterEnum[filterType as keyof typeof FilterEnum]
    }
}
