import { Action, createReducer, on } from '@ngrx/store'
import * as CommentsActions from './comments.action'
import { Comment } from '../../models/comments.model'

export const commentsFeatureKey = 'commentsFeatureKey'

export interface CommentsState {
    comments: Array<Comment>
}

export const initialState: CommentsState = {
    comments: [],
}

const commentsReducer = createReducer(
    initialState,
    on(CommentsActions.createComment, (state, action) => ({
        ...state,
        comments: [action.payload, ...state.comments],
    })),
    on(CommentsActions.createCommentSuccess, (state, action) => ({
        ...state,
        comments: [
            action.payload,
            ...state.comments.filter(
                (comment) => !comment.id.includes('dummy')
            ),
        ],
    })),
    on(CommentsActions.createCommentFailure, (state, action) => {
        const updatedComment = { ...action.payload, status: 'error' }
        return {
            ...state,
            comments: [...state.comments.slice(0, -1), updatedComment],
        }
    }),

    on(CommentsActions.getCommentsSuccess, (state, action) => ({
        ...state,
        comments: action.payload,
    })),

    on(CommentsActions.retryComment, (state, action) => {
        const updatedComment = { ...action.payload, status: 'pending' }
        return {
            ...state,
            comments: [...state.comments.slice(0, -1), updatedComment],
        }
    }),

    on(CommentsActions.deleteComment, (state, action) => ({
        ...state,
        comments: [
            ...state.comments.filter((item) => item.id !== action.payload.id),
        ],
    }))
)

export function reducer(
    state: CommentsState | undefined,
    action: Action
): CommentsState {
    return commentsReducer(state, action)
}
