import { Component, Inject, Input } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

@Component({
    selector: 'confirmation-dialog',
    templateUrl: './confirmation.dialog.html',
    styleUrls: ['./confirmation.dialog.scss'],
})
export class ConfirmationDialog {
    constructor(
        public dialogRef: MatDialogRef<ConfirmationDialog>,
        @Inject(MAT_DIALOG_DATA) public data: string
    ) {}

    onNoClick(): void {
        this.dialogRef.close()
    }
}
