import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ActivatedRoute, Router } from '@angular/router'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { EMPTY, from, of } from 'rxjs'
import { catchError, exhaustMap, map, mergeMap } from 'rxjs/operators'
import { AppState } from '..'
import { Project } from '../../models/projects.models'
import { ItemsWebService } from '../../services/items-web.service'
import { ProjectsWebService } from '../../services/projects-web.service'
import { UsersWebService } from '../../services/users-web.service'
import {
    ProjectsActions,
    ProjectsUnion,
    createProjectFailure,
    deleteProjectSuccess,
    generateAdvancedItemsFailure,
    generateAdvancedItemsSuccess,
    generateItemComponentsFailure,
    generateItemComponentsSuccess,
    generateProjectItemsFailure,
    getAllProjectsFailure,
    getAllProjectsSuccess,
    getOneItemAndAddToAllFailure,
    getOneItemAndAddToAllSuccess,
    getOneItemFailure,
    getOneItemSuccess,
    likeItemFailure,
    likeItemSuccess,
    moveItemToProjectFailure,
    moveItemToProjectSuccess,
    setActiveProject,
    getAllProjects,
    deleteItemSuccess,
    deleteItemFailure,
    removeSkeletonLoaderForItem,
} from './projects.actions'
import { LocalStorage } from '../../local-storage'

@Injectable()
export class ProjectsEffects {
    public getAllProjects$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProjectsActions.GET_ALL_PROJECTS),
            exhaustMap(() => {
                return this.projectsWebService.getAllProjects().pipe(
                    map((projects: Array<Project>) => {
                        return getAllProjectsSuccess({
                            payload: projects,
                        })
                    }),
                    catchError((error) => {
                        this.usersWebService.catchError(error)
                        return of(getAllProjectsFailure({ error: error }))
                    })
                )
            })
        )
    })

    public createProject$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProjectsActions.CREATE_PROJECT),
            exhaustMap(({ payload: projectCreationParams }) => {
                return this.projectsWebService
                    .createProject(projectCreationParams.name)
                    .pipe(
                        map((project) => {
                            this.router.navigate([], {
                                relativeTo: this.route,
                                queryParams: {
                                    projectId: project.id,
                                },
                                queryParamsHandling: 'merge',
                            })
                            return setActiveProject({
                                payload: project,
                                filters: {},
                            })
                        }),
                        catchError((error) => {
                            this.usersWebService.catchError(error)
                            return of(createProjectFailure({ error: error }))
                        })
                    )
            })
        )
    })

    public deleteProject$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProjectsActions.DELETE_PROJECT),
            exhaustMap(({ payload: id }) => {
                return this.projectsWebService.deleteProject(id).pipe(
                    map(() => {
                        this.router.navigate(['project-selection'])
                        this.store.dispatch(setActiveProject({ payload: null }))
                        LocalStorage.removeProjectsState()
                        this.store.dispatch(getAllProjects())
                        return deleteProjectSuccess()
                    }),
                    catchError((error) => {
                        this.usersWebService.catchError(error)
                        return of(createProjectFailure({ error: error }))
                    })
                )
            })
        )
    })

    public generateProjectItems$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProjectsActions.GENERATE_MULTIPLE_PROJECT_ITEMS),
            mergeMap(({ payload: { project_id, apiCalls } }) => {
                this.snackbar.open('Concepts are generating', '', {
                    duration: 1200,
                })

                // Use from and mergeMap to launch all API calls concurrently
                return from(apiCalls).pipe(
                    mergeMap((params: any) =>
                        this.itemsWebService.generateProjectItems(params).pipe(
                            map((item: any) => {
                                this.store.dispatch(
                                    removeSkeletonLoaderForItem({
                                        payload: project_id,
                                    })
                                )
                                return getOneItemAndAddToAllSuccess({
                                    payload: item,
                                })
                            }),
                            catchError((error) => {
                                this.store.dispatch(
                                    removeSkeletonLoaderForItem({
                                        payload: project_id,
                                    })
                                )
                                this.snackbar.open(
                                    'Failed to generate a concept',
                                    'Ok',
                                    {
                                        duration: 1200,
                                    }
                                )
                                return of(
                                    generateProjectItemsFailure({ error })
                                )
                            })
                        )
                    ),
                    catchError((error: any) => {
                        console.log('error', error)
                        return EMPTY
                    })
                )
            })
        )
    })

    public getOneProject$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProjectsActions.GET_ONE_PROJECT),
            exhaustMap(({ payload: { id, filters } }) => {
                return this.projectsWebService.getOneProject(id, filters).pipe(
                    map((project) => {
                        this.router.navigate([], {
                            relativeTo: this.route,
                            queryParams: {
                                projectId: project.id,
                            },
                            queryParamsHandling: 'merge',
                        })
                        return setActiveProject({
                            payload: project,
                            filters: filters,
                        })
                    }),
                    catchError((errorMessage) => {
                        this.usersWebService.catchError(errorMessage)
                        return of(
                            getAllProjectsFailure({
                                error: errorMessage,
                            })
                        )
                    })
                )
            })
        )
    })

    public getOneItem$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProjectsActions.GET_ONE_ITEM),
            exhaustMap(({ payload }) => {
                return this.itemsWebService.getOneItem(payload).pipe(
                    map((item) => {
                        this.router.navigate([], {
                            relativeTo: this.route,
                            queryParamsHandling: 'merge',
                        })
                        return getOneItemSuccess({ payload: item })
                    }),
                    catchError((errorMessage) => {
                        this.usersWebService.catchError(errorMessage)
                        return of(getOneItemFailure({ error: errorMessage }))
                    })
                )
            })
        )
    })

    public getOneItemAndAddToAll$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProjectsActions.GET_ONE_ITEM_AND_ADD_TO_ALL),
            exhaustMap(({ payload }) => {
                return this.itemsWebService.getOneItem(payload).pipe(
                    map((item) => {
                        return getOneItemAndAddToAllSuccess({
                            payload: item,
                        })
                    }),
                    catchError((errorMessage) => {
                        this.usersWebService.catchError(errorMessage)
                        return of(
                            getOneItemAndAddToAllFailure({
                                error: errorMessage,
                            })
                        )
                    })
                )
            })
        )
    })

    public likeItem$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProjectsActions.LIKE_ITEM),
            exhaustMap(({ payload: params }) => {
                return this.itemsWebService.likeItem(params).pipe(
                    map((res) => {
                        return likeItemSuccess({
                            itemId: params.itemId,
                        })
                    }),
                    catchError((err) => {
                        this.usersWebService.catchError(err)
                        return of(likeItemFailure())
                    })
                )
            })
        )
    })

    public generateItemComponents$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProjectsActions.GENERATE_ITEM_COMPONENTS),
            exhaustMap(({ payload: { id } }) => {
                return this.itemsWebService.generateItemComponents(id).res.pipe(
                    map((res) => {
                        this.snackbar.open(res.msg, '', {
                            duration: 1200,
                        })
                        return generateItemComponentsSuccess()
                    }),
                    catchError((error) => {
                        this.snackbar.open(
                            `Failed to generate new components`,
                            'Ok',
                            {
                                duration: 1200,
                            }
                        )
                        this.usersWebService.catchError(error)
                        return of(
                            generateItemComponentsFailure({
                                error: error,
                            })
                        )
                    })
                )
            })
        )
    })

    public deleteItem$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProjectsActions.DELETE_ITEM),
            exhaustMap(({ payload: data }: any) => {
                return this.itemsWebService.deleteItem(data.id).pipe(
                    map(() => {
                        this.router.navigate(['gallery'])
                        return deleteItemSuccess()
                    }),
                    catchError((error) => {
                        this.usersWebService.catchError(error)
                        return of(deleteItemFailure({ error: error }))
                    })
                )
            })
        )
    })

    public moveItemToProject$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProjectsActions.MOVE_ITEM_TO_PROJECT),
            exhaustMap(({ payload: params }) => {
                return this.itemsWebService.moveItemToProject(params).pipe(
                    map(() => {
                        return moveItemToProjectSuccess({
                            payload: params.itemId,
                        })
                    }),
                    catchError((err) => {
                        this.usersWebService.catchError(err)
                        return of(moveItemToProjectFailure())
                    })
                )
            })
        )
    })

    public generateAdvancedItems$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProjectsActions.GENERATE_ADVANCED_ITEMS),
            exhaustMap(({ payload: { project_id } }) => {
                return this.itemsWebService
                    .generateAdvancedItems(project_id)
                    .res.pipe(
                        map((res) => {
                            this.snackbar.open(res.msg, '', {
                                duration: 1200,
                            })
                            return generateAdvancedItemsSuccess()
                        }),
                        catchError((error) => {
                            this.snackbar.open(
                                `Failed to generate new components`,
                                'Ok',
                                {
                                    duration: 1200,
                                }
                            )
                            return of(
                                generateAdvancedItemsFailure({
                                    error: error,
                                })
                            )
                        })
                    )
            })
        )
    })

    constructor(
        private actions$: Actions<ProjectsUnion>,
        private projectsWebService: ProjectsWebService,
        private itemsWebService: ItemsWebService,
        private store: Store<AppState>,
        private snackbar: MatSnackBar,
        private usersWebService: UsersWebService,
        private router: Router,
        private route: ActivatedRoute
    ) {}
}
