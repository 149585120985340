import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouterModule } from '@angular/router'
import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { GalleryComponent } from './gallery/gallery.component'
import { ItemComponent } from './item/item.component'
import { LoginComponent } from './login/login.component'
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component'
import { SharedModule } from './shared/shared.module'
import { AuthInterceptor } from './state/auth/auth.interceptor'
import { ItemsWebService } from './state/services/items-web.service'
import { ProjectsWebService } from './state/services/projects-web.service'
import { TopAttributesWebService } from './state/services/top-attributes-web.service'
import { UsersWebService } from './state/services/users-web.service'
import { CommentsWebService } from './state/services/comments-web.service'
import { WebSocketService } from './state/services/websocket.service'
import { ROOT_EFFECTS, ROOT_REDUCERS } from './state/store'
import { TopAttributesComponent } from './top-attributes/top-attributes.component'
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader'
import { ManageUsers } from './manage-users/manage-users.component'
import { ResetPasswordComponent } from './reset-password/reset-password.component'

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        TopAttributesComponent,
        ItemComponent,
        GalleryComponent,
        ManageUsers,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        RouterModule,
        BrowserAnimationsModule,
        SharedModule,
        HttpClientModule,
        StoreModule.forRoot(ROOT_REDUCERS),
        EffectsModule.forRoot(ROOT_EFFECTS),
        NgxSkeletonLoaderModule,
    ],
    providers: [
        ItemsWebService,
        ProjectsWebService,
        UsersWebService,
        TopAttributesWebService,
        CommentsWebService,
        WebSocketService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
