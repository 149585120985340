import { AppState } from './store'

export class LocalStorage {
    public static setUsersState(store: AppState['usersState']): void {
        localStorage.setItem('usersState', JSON.stringify(store))
    }

    public static getUsersState(): AppState['usersState'] | null {
        const store = localStorage.getItem('usersState')
        if (store) {
            return JSON.parse(store)
        }
        return null
    }

    public static clearUsersState(): void {
        localStorage.removeItem('usersState')
    }

    public static setProjectsState(store: AppState['projectsState']): void {
        localStorage.setItem('projectsState', JSON.stringify(store))
    }

    public static removeProjectsState(): void {
        localStorage.removeItem('projectsState')
    }

    public static getProjectsState(): AppState['projectsState'] | null {
        const store = localStorage.getItem('projectsState')
        if (store) {
            return JSON.parse(store)
        }
        return null
    }

    public static clearProjectsState(): void {
        localStorage.removeItem('projectsState')
    }

    public static clearAllState(): void {
        localStorage.clear()
    }

    public static setRedirectPath(path: string): void {
        sessionStorage.setItem('redirectPath', path)
    }

    public static getRedirectPath(): string {
        const redirectPath = sessionStorage.getItem('redirectPath')
        return redirectPath ?? ''
    }

    public static clearRedirectPath(): void {
        sessionStorage.removeItem('redirectPath')
    }
}
