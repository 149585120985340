import { Action, createReducer, on } from '@ngrx/store'
import { FilterOptions, Filters } from '../../models/filters.models'
import { TopAttribute } from '../../models/top-attributes.models'
import * as TopAttributesActions from './top-attributes.actions'

export const topAttributesFeatureKey = 'topAttributesState'

export interface TopAttributesState {
    allFilters: FilterOptions
    activeFilters: Filters
    allTopAttributes: Array<TopAttribute>
    activeTopAttributes: Array<TopAttribute>
}

export const initialState: TopAttributesState = {
    allFilters: {
        item_type: [],
        department: [],
        category: [],
        sub_category: [],
        trend_type: [],
    },
    activeFilters: { limit: 50 },
    allTopAttributes: [],
    activeTopAttributes: [],
}

const topAttributesReducer = createReducer(
    initialState,
    on(TopAttributesActions.getTopAttributes, (state, action) => ({
        ...state,
        activeFilters: action.payload,
    })),
    on(TopAttributesActions.getTopAttributesSuccess, (state, action) => ({
        ...state,
        allFilters: action.payload.filters,
        allTopAttributes: action.payload.top_attributes,
        activeTopAttributes: [],
    })),
    on(TopAttributesActions.getTopAttributesFailure, (state) => ({ ...state })),
    on(TopAttributesActions.activateTopAttribute, (state, action) => ({
        ...state,
        activeTopAttributes: [...state.activeTopAttributes, action.payload],
    })),
    on(TopAttributesActions.deactivateTopAttribute, (state, action) => ({
        ...state,
        activeTopAttributes: state.activeTopAttributes.filter(
            (topAttribute) => topAttribute.id !== action.payload.id
        ),
    }))
)

export function reducer(
    state: TopAttributesState | undefined,
    action: Action
): TopAttributesState {
    return topAttributesReducer(state, action)
}
