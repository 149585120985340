import { Action, createReducer, on } from '@ngrx/store'
import { LocalStorage } from '../../local-storage'
import * as LoginActions from './users.actions'
import { LoggedInUser, User } from '../../models/users.models'

export const usersFeatureKey = 'usersState'

export interface UsersState {
    access_token: string
    is_logged_in: boolean
    is_sidebar_open: ConstrainBoolean
    loggedInUser: LoggedInUser | null
    allUsers: Array<User>
    user: User | null
    is_loading: boolean
}

export const initialState: UsersState = LocalStorage.getUsersState() || {
    access_token: '',
    is_logged_in: false,
    is_sidebar_open: false,
    loggedInUser: null,
    allUsers: [] as Array<User>,
    user: null,
    is_loading: false,
}

const usersReducer = createReducer(
    initialState,
    on(LoginActions.login, (state) => ({
        ...state,
        is_loading: true,
    })),
    on(LoginActions.loginSuccess, (state, { payload }) => {
        const newState: UsersState = {
            ...state,
            access_token: payload.access_token,
            is_loading: false,
        }

        LocalStorage.setUsersState(newState)

        return newState
    }),
    on(LoginActions.loginFailure, (state) => ({
        ...state,
        login: '',
        is_loading: false,
    })),

    on(LoginActions.getLoggedInUser, (state) => ({
        ...state,
    })),
    on(LoginActions.getLoggedInUserSuccess, (state, user: LoggedInUser) => {
        const newState: UsersState = {
            ...state,
            loggedInUser: user,
        }

        LocalStorage.setUsersState(newState)

        return newState
    }),
    on(LoginActions.getLoggedInUserFailure, (state) => ({ ...state })),
    on(LoginActions.logout, (state) => ({ ...state, access_token: '' })),
    on(LoginActions.getAllUsersSuccess, (state, action) => ({
        ...state,
        allUsers: action.payload,
    })),

    on(LoginActions.createUser, (state) => ({
        ...state,
    })),
    on(LoginActions.createUserSuccess, (state, action) => ({
        ...state,
        allUsers: [action.payload, ...state.allUsers],
    })),

    on(LoginActions.updateUser, (state) => ({
        ...state,
    })),
    on(LoginActions.updateUserSuccess, (state, action) => {
        const index = state.allUsers.findIndex(
            (user: any) => user.id === action.userId
        )

        let updatedUsers = []

        if (index === -1) updatedUsers = [action.payload, ...state.allUsers]
        else
            updatedUsers = [
                ...state.allUsers.slice(0, index),
                action.payload, // Replace the user object with the new payload
                ...state.allUsers.slice(index + 1),
            ]

        return {
            ...state,
            allUsers: updatedUsers,
        }
    }),
    on(LoginActions.resetPassword, (state) => ({
        ...state,
        is_loading: true,
    })),
    on(LoginActions.resetPasswordSuccess, (state) => ({
        ...state,
        is_loading: false,
    })),
    on(LoginActions.resetPasswordFailure, (state) => ({
        ...state,
        is_loading: false,
    })),
    on(LoginActions.resetPasswordRequest, (state) => ({
        ...state,
        is_loading: true,
    })),
    on(LoginActions.resetPasswordRequestSuccess, (state) => ({
        ...state,
        is_loading: false,
    })),
    on(LoginActions.resetPasswordRequestFailure, (state) => ({
        ...state,
        is_loading: false,
    }))
)

export function reducer(
    state: UsersState | undefined,
    action: Action
): UsersState {
    return usersReducer(state, action)
}
