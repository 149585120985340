import { Component, Inject } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/state/store'
import { createUser, updateUser } from 'src/app/state/store/users/users.actions'
import { isEqual } from 'lodash'
import {
    CreateUserPayload,
    UpdateUserPayload,
} from 'src/app/state/models/users.models'

@Component({
    selector: 'user-dialog',
    templateUrl: './user.dialog.html',
    styleUrls: ['./user.dialog.scss'],
})
export class UserDialog {
    public myForm: FormGroup
    public isLoading: boolean = false
    public initialFormValues: any
    public updateValidation: boolean = true

    get isFormInvalid(): boolean {
        return !this.myForm.value.firstName ||
            !this.myForm.value.lastName ||
            !this.myForm.value.role ||
            this.data.type === 'Create'
            ? !this.myForm.value.password ||
                  this.myForm.controls['password'].invalid ||
                  !this.myForm.value.email ||
                  this.myForm.controls['email'].invalid
            : false
    }
    constructor(
        private store: Store<AppState>,
        public dialogRef: MatDialogRef<UserDialog>,
        private fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        const isUpdate = this.data?.type === 'Update'
        const userRole = this.data?.user?.role.toLowerCase()
        this.myForm = this.fb.group({
            firstName: [this.data?.user?.first_name || '', Validators.required],
            lastName: [this.data?.user?.last_name || '', Validators.required],
            email: [
                { value: this.data?.user?.username || '', disabled: isUpdate },
                [Validators.required, Validators.email],
            ],
            role: [
                userRole === 'superadmin' || userRole === 'admin'
                    ? 'admin'
                    : 'member',
                Validators.required,
            ],
            password: [
                this.data?.user?.hashed_password || '',
                isUpdate ? [] : [Validators.required, Validators.minLength(3)],
            ],
        })

        // Store the initial values
        this.initialFormValues = this.myForm.getRawValue()

        // Monitor form changes
        this.myForm.valueChanges.subscribe(() => {
            if (isEqual(this.initialFormValues, this.myForm.getRawValue()))
                this.updateValidation = true
            else this.updateValidation = false
        })
    }

    onNoClick(): void {
        this.dialogRef.close()
    }

    public getErrorMessages(): string {
        const errorMessages = []

        if (this.myForm.get('firstName')?.invalid)
            errorMessages.push('• First name is required')

        if (this.myForm.get('lastName')?.invalid)
            errorMessages.push('• Last name is required')

        if (!this.myForm.get('email')?.value)
            errorMessages.push('• Email is required')
        else if (this.myForm.get('email')?.invalid)
            errorMessages.push('• Email is invalid')

        if (this.data?.type !== 'Update') {
            if (!this.myForm.get('password')?.value)
                errorMessages.push('• Password is required')
            else if (this.myForm.get('password')?.invalid)
                errorMessages.push('• Password should be at least 3 characters')
        }

        return errorMessages.join('\n')
    }

    public addUser() {
        this.isLoading = true
        const body: CreateUserPayload = {
            username: this.myForm.value.email,
            password: this.myForm.value.password,
            first_name: this.myForm.value.firstName,
            last_name: this.myForm.value.lastName,
            role: this.myForm.value.role,
        }
        this.store.dispatch(createUser({ payload: body }))
        this.isLoading = false
        this.dialogRef.close()
    }
    public editUser() {
        this.isLoading = true

        let body: UpdateUserPayload = {
            username: this.myForm.value.email,
            first_name: this.myForm.value.firstName,
            last_name: this.myForm.value.lastName,
            role: this.myForm.value.role,
        }
        if (this.myForm.value.password)
            body.password = this.myForm.value.password

        this.store.dispatch(
            updateUser({
                userId: this.data?.user?.id,
                payload: body,
            })
        )

        this.isLoading = false
        this.dialogRef.close()
    }
}
