import { InjectionToken } from '@angular/core'
import { ActionReducerMap } from '@ngrx/store'
import { ProjectsEffects } from './projects/projects.effects'
import * as fromProjects from './projects/projects.reducer'
import { TopAttributesEffects } from './top-attributes/top-attributes.effects'
import * as fromTopAttributes from './top-attributes/top-attributes.reducer'
import { UsersEffects } from './users/users.effects'
import * as fromUsers from './users/users.reducer'
import { CommentsEffects } from './comments/comments.effects'
import * as fromComments from './comments/comments.reducer'

export interface AppState {
    [fromUsers.usersFeatureKey]: fromUsers.UsersState
    [fromProjects.projectsFeatureKey]: fromProjects.ProjectsState
    [fromTopAttributes.topAttributesFeatureKey]: fromTopAttributes.TopAttributesState
    [fromComments.commentsFeatureKey]: fromComments.CommentsState
}

export const ROOT_REDUCERS = new InjectionToken<ActionReducerMap<AppState>>(
    'Root Reducers',
    {
        factory: () => ({
            [fromUsers.usersFeatureKey]: fromUsers.reducer,
            [fromProjects.projectsFeatureKey]: fromProjects.reducer,
            [fromTopAttributes.topAttributesFeatureKey]:
                fromTopAttributes.reducer,
            [fromComments.commentsFeatureKey]: fromComments.reducer,
        }),
    }
)

export const ROOT_EFFECTS = [
    UsersEffects,
    ProjectsEffects,
    TopAttributesEffects,
    CommentsEffects,
]
