import { Creator } from './creator.models'

export interface FilterOptions {
    item_type?: string[]
    department?: string[]
    category?: string[]
    sub_category?: string[]
    trend_type?: string[]
    creator?: any[]
}

export interface Filters extends FilterOptions {
    trend_source?: string
    search?: string
    only_saved?: boolean
    limit?: number
    offset?: number
    start_date?: Date
    end_date?: Date
    is_liked?: Boolean
}

export enum FilterEnum {
    item_type = 'Item Type',
    department = 'Department',
    category = 'Category',
    sub_category = 'Sub-Category',
    trend_type = 'Trend Type',
}

export enum FilterIconEnum {
    item_type = '../../../assets/images/icons/item.svg',
    department = '../../../assets/images/icons/department.svg',
    category = '../../../assets/images/icons/category.svg',
    sub_category = '../../../assets/images/icons/sub-category.svg',
    trend_type = '../../../assets/images/icons/trend.svg',
}

export function getFilterIcon(filterType: string | null): string {
    return FilterIconEnum[filterType as keyof typeof FilterIconEnum]
}
