import { createAction, props, union } from '@ngrx/store'
import { Filters } from '../../models/filters.models'
import {
    Item,
    LikeItemParams,
    MoveItemToProjectParams,
    ProjectItemsGenerationParams,
} from '../../models/items.models'
import { Project, ProjectCreationParams } from '../../models/projects.models'

export enum ProjectsActions {
    GET_ALL_PROJECTS = '[Projects] Get All Projects',
    GET_ALL_PROJECTS_SUCCESS = '[Projects] Get All Projects Success',
    GET_ALL_PROJECTS_FAILURE = '[Projects] Get All Projects Failure',

    GET_ONE_PROJECT = '[Projects] Get One Project',
    GET_ONE_PROJECT_SUCCESS = '[Projects] Get One Project Success',
    GET_ONE_PROJECT_FAILURE = '[Projects] Get One Project Failure',

    CREATE_PROJECT = '[Projects] Create Project',
    CREATE_PROJECT_SUCCESS = '[Projects] Create Project Success',
    CREATE_PROJECT_FAILURE = '[Projects] Create Project Failure',

    DELETE_PROJECT = '[Projects] Delete Project',
    DELETE_PROJECT_SUCCESS = '[Projects] Delete Project Success',
    DELETE_PROJECT_FAILURE = '[Projects] Delete Project Failure',

    GENERATE_PROJECT_ITEMS = '[Projects] Generate Project Items',
    GENERATE_PROJECT_ITEMS_SUCCESS = '[Projects] Generate Project Items Success',
    GENERATE_PROJECT_ITEMS_FAILURE = '[Projects] Generate Project Items Failure',
    GENERATE_MULTIPLE_PROJECT_ITEMS = '[Projects] Generate Multiple Project Items',
    GENERATE_SINGLE_PROJECT_ITEM_SUCCESS = '[Projects] Generate Single Project Item Success',

    GET_ONE_ITEM = '[Projects] Get One Item',
    GET_ONE_ITEM_SUCCESS = '[Projects] Get One Item Success',
    GET_ONE_ITEM_FAILURE = '[Projects] Get One Item Failure',

    GET_ONE_ITEM_AND_ADD_TO_ALL = '[Projects] Get One Item And Add To All',
    GET_ONE_ITEM_AND_ADD_TO_ALL_SUCCESS = '[Projects] Get One Item And Add To All Success',
    GET_ONE_ITEM_AND_ADD_TO_ALL_FAILURE = '[Projects] Get One Item And Add To All Failure',

    LIKE_ITEM = '[Items] Like Item',
    LIKE_ITEM_SUCCESS = '[Items] Like Item Success',
    LIKE_ITEM_FAILURE = '[Items] Like Item Failure',

    GENERATE_ITEM_COMPONENTS = '[Projects] Generate Item COmponents',
    GENERATE_ITEM_COMPONENTS_SUCCESS = '[Projects] Generate Item COmponents Success',
    GENERATE_ITEM_COMPONENTS_FAILURE = '[Projects] Generate Item COmponents Failure',

    DELETE_ITEM = '[Projects] Delete Item',
    DELETE_ITEM_SUCCESS = '[Projects] Delete Item Success',
    DELETE_ITEM_FAILURE = '[Projects] Delete Item Failure',

    MOVE_ITEM_TO_PROJECT = '[Items] Move Item To Project',
    MOVE_ITEM_TO_PROJECT_SUCCESS = '[Items] Move Item To Project Success',
    MOVE_ITEM_TO_PROJECT_FAILURE = '[Items] Move Item To Project Failure',

    GENERATE_ADVANCED_ITEMS = '[Projects] Generate Advanced Items',
    GENERATE_ADVANCED_ITEMS_SUCCESS = '[Projects] Generate Advanced Items Success',
    GENERATE_ADVANCED_ITEMS_FAILURE = '[Projects] Generate Advanced Items Failure',

    RECEIVED_ITEM = '[Projects] Received Item',
    ADD_SKELETON_LOADER = '[Projects] Add skeleton Loader',
}

export enum ProjectsLocalActions {
    SET_ACTIVE_PROJECT = '[Projects] Set Active Project',
    CLEAR_ITEM = '[Item] Clear Item',
}

export const getAllProjects = createAction(ProjectsActions.GET_ALL_PROJECTS)
export const getAllProjectsSuccess = createAction(
    ProjectsActions.GET_ALL_PROJECTS_SUCCESS,
    props<{ payload: Array<Project> }>()
)
export const getAllProjectsFailure = createAction(
    ProjectsActions.GET_ALL_PROJECTS_FAILURE,
    props<{ error: string }>()
)

export const getOneProject = createAction(
    ProjectsActions.GET_ONE_PROJECT,
    props<{ payload: { id: string; filters: Filters } }>()
)
export const getOneProjectSuccess = createAction(
    ProjectsActions.GET_ONE_PROJECT_SUCCESS,
    props<{ payload: Project; filters: Filters }>()
)
export const getOneProjectFailure = createAction(
    ProjectsActions.GET_ONE_PROJECT_FAILURE,
    props<{ error: string }>()
)

export const createProject = createAction(
    ProjectsActions.CREATE_PROJECT,
    props<{ payload: ProjectCreationParams }>()
)
export const createProjectSuccess = createAction(
    ProjectsActions.CREATE_PROJECT_SUCCESS,
    props<{ payload: Project }>()
)
export const createProjectFailure = createAction(
    ProjectsActions.CREATE_PROJECT_FAILURE,
    props<{ error: string }>()
)

export const deleteProject = createAction(
    ProjectsActions.DELETE_PROJECT,
    props<{ payload: string }>()
)
export const deleteProjectSuccess = createAction(
    ProjectsActions.DELETE_PROJECT_SUCCESS
)
export const deleteProjectFailure = createAction(
    ProjectsActions.DELETE_PROJECT_FAILURE
)

export const generateProjectItems = createAction(
    ProjectsActions.GENERATE_MULTIPLE_PROJECT_ITEMS,
    props<{
        payload: {
            project_id: string
            apiCalls: ProjectItemsGenerationParams[]
        }
    }>()
)
export const generateProjectItemsSuccess = createAction(
    ProjectsActions.GENERATE_PROJECT_ITEMS_SUCCESS
)
export const generateProjectItemsFailure = createAction(
    ProjectsActions.GENERATE_PROJECT_ITEMS_FAILURE,
    props<{ error: string }>()
)

export const setActiveProject = createAction(
    ProjectsLocalActions.SET_ACTIVE_PROJECT,
    props<{ payload: Project | null; filters?: Filters }>()
)

export const getOneItem = createAction(
    ProjectsActions.GET_ONE_ITEM,
    props<{ payload: string }>()
)
export const getOneItemSuccess = createAction(
    ProjectsActions.GET_ONE_ITEM_SUCCESS,
    props<{ payload: Item }>()
)
export const getOneItemFailure = createAction(
    ProjectsActions.GET_ONE_ITEM_FAILURE,
    props<{ error: string }>()
)

export const getOneItemAndAddToAll = createAction(
    ProjectsActions.GET_ONE_ITEM_AND_ADD_TO_ALL,
    props<{ payload: string }>()
)
export const getOneItemAndAddToAllSuccess = createAction(
    ProjectsActions.GET_ONE_ITEM_AND_ADD_TO_ALL_SUCCESS,
    props<{ payload: Item }>()
)
export const getOneItemAndAddToAllFailure = createAction(
    ProjectsActions.GET_ONE_ITEM_AND_ADD_TO_ALL_FAILURE,
    props<{ error: string }>()
)

export const clearItem = createAction(ProjectsLocalActions.CLEAR_ITEM)

export const likeItem = createAction(
    ProjectsActions.LIKE_ITEM,
    props<{ payload: LikeItemParams }>()
)
export const likeItemSuccess = createAction(
    ProjectsActions.LIKE_ITEM_SUCCESS,
    props<{ itemId: string }>()
)
export const likeItemFailure = createAction(ProjectsActions.LIKE_ITEM_FAILURE)

export const generateItemComponents = createAction(
    ProjectsActions.GENERATE_ITEM_COMPONENTS,
    props<{ payload: { id: string } }>()
)
export const generateItemComponentsSuccess = createAction(
    ProjectsActions.GENERATE_ITEM_COMPONENTS_SUCCESS
)
export const generateItemComponentsFailure = createAction(
    ProjectsActions.GENERATE_ITEM_COMPONENTS_FAILURE,
    props<{ error: string }>()
)

export const deleteItem = createAction(
    ProjectsActions.DELETE_ITEM,
    props<{ payload: { id: string } }>()
)
export const deleteItemSuccess = createAction(
    ProjectsActions.DELETE_ITEM_SUCCESS
)
export const deleteItemFailure = createAction(
    ProjectsActions.DELETE_ITEM_FAILURE,
    props<{ error: string }>()
)

export const moveItemToProject = createAction(
    ProjectsActions.MOVE_ITEM_TO_PROJECT,
    props<{ payload: MoveItemToProjectParams }>()
)

export const moveItemToProjectSuccess = createAction(
    ProjectsActions.MOVE_ITEM_TO_PROJECT_SUCCESS,
    props<{ payload: string }>()
)
export const moveItemToProjectFailure = createAction(
    ProjectsActions.MOVE_ITEM_TO_PROJECT_FAILURE
)

export const generateAdvancedItems = createAction(
    ProjectsActions.GENERATE_ADVANCED_ITEMS,
    props<{
        payload: { project_id: string }
    }>()
)

export const generateAdvancedItemsSuccess = createAction(
    ProjectsActions.GENERATE_ADVANCED_ITEMS_SUCCESS
)

export const generateAdvancedItemsFailure = createAction(
    ProjectsActions.GENERATE_ADVANCED_ITEMS_FAILURE,
    props<{ error: string }>()
)

export const removeSkeletonLoaderForItem = createAction(
    ProjectsActions.RECEIVED_ITEM,
    props<{
        payload: any
    }>()
)

export const addskeletonLoaders = createAction(
    ProjectsActions.ADD_SKELETON_LOADER,
    props<{
        project_id: string
        n_of_items: number
    }>()
)

const all = union({
    getAllProjects,
    getAllProjectsSuccess,
    getAllProjectsFailure,

    getOneProject,
    getOneProjectSuccess,
    getOneProjectFailure,

    createProject,
    createProjectSuccess,
    createProjectFailure,

    setActiveProject,

    generateProjectItemsSuccess,
    generateProjectItemsFailure,
    generateProjectItems,

    getOneItem,
    getOneItemSuccess,
    getOneItemFailure,

    getOneItemAndAddToAll,
    getOneItemAndAddToAllSuccess,
    getOneItemAndAddToAllFailure,

    generateItemComponents,
    generateItemComponentsSuccess,
    generateItemComponentsFailure,

    moveItemToProject,
    moveItemToProjectSuccess,
    moveItemToProjectFailure,

    likeItem,
    likeItemSuccess,
    likeItemFailure,

    clearItem,
})

export type ProjectsUnion = typeof all
