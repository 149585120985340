<navbar></navbar>
<div class="page-container h-full">
    <sidebar></sidebar>
    <div class="mt-55 w-100 overflow-hidden">
        <div class="d-flex justify-content-center w-100 mt-25">
            <div class="w-70">
                <div class="d-flex justify-content-end">
                    <button
                        mat-fab
                        extended
                        color="primary"
                        class="generate-item-button"
                        (click)="addUser()"
                    >
                        <mat-icon>account_circle</mat-icon>
                        Add User
                    </button>
                </div>
            </div>
        </div>
        <div
            class="mt-25 w-100 h-100 d-flex justify-content-center overflow-hidden"
        >
            <div class="overflow-auto mxh-110 w-70">
                <table
                    mat-table
                    [dataSource]="dataSource"
                    id="top-attributes-mat-table"
                >
                    <!-- Define each column -->
                    <ng-container
                        *ngFor="let column of displayedColumns"
                        [matColumnDef]="column"
                    >
                        <th mat-header-cell *matHeaderCellDef class="bg-header">
                            {{ columnPlaceholder(column) }}
                        </th>
                        <td *matCellDef="let element" mat-cell>
                            <ng-container
                                *ngIf="column === 'action'; else showElement"
                            >
                                <div class="d-flex align-items-center">
                                    <mat-icon
                                        class="fs-16 pointer"
                                        matTooltip="Edit"
                                        aria-label="Edit user"
                                        (click)="editUser(element)"
                                        >edit</mat-icon
                                    >

                                    <mat-icon
                                        class="fs-16 pointer"
                                        [matTooltip]="
                                            element.is_decommissioned
                                                ? 'Restore'
                                                : 'Archive'
                                        "
                                        (click)="toggleUserStatus(element)"
                                    >
                                        <ng-container
                                            *ngIf="
                                                element.is_decommissioned;
                                                else archiveIcon
                                            "
                                        >
                                            restore
                                        </ng-container>
                                        <ng-template #archiveIcon
                                            >archive</ng-template
                                        >
                                    </mat-icon>
                                </div>
                            </ng-container>

                            <ng-template #showElement>
                                <ng-container
                                    *ngIf="
                                        column === 'status';
                                        else defaultElement
                                    "
                                >
                                    {{
                                        element.is_decommissioned
                                            ? 'Archived'
                                            : 'Active'
                                    }}
                                </ng-container>
                                <ng-template #defaultElement>
                                    {{ element[column] || 'N/A' }}
                                </ng-template>
                            </ng-template>
                        </td>
                    </ng-container>

                    <!-- Header and row definitions -->
                    <tr
                        mat-header-row
                        sticky
                        *matHeaderRowDef="displayedColumns"
                    ></tr>
                    <tr
                        mat-row
                        *matRowDef="let row; columns: displayedColumns"
                        class="custom-mat-row"
                    ></tr>
                </table>
            </div>
        </div>
    </div>
</div>
