import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core'
import { FilterOptions, Filters } from 'src/app/state/models/filters.models'

@Component({
    selector: 'filter-menu',
    templateUrl: './filter-menu.component.html',
    styleUrls: ['./filter-menu.component.scss'],
})
export class FilterMenu implements AfterViewInit {
    @Input() public activeFilters: Filters = {}
    @Input() public filterOptions: FilterOptions | null = null
    @Input() public getSelectedFilterCount: number = 0
    @Output() public filterAction: EventEmitter<any> = new EventEmitter()

    public isOpen: boolean = false
    public filterTypes: string[] = [
        'item_type',
        'department',
        'category',
        'sub_category',
        'trend_type',
    ]

    public getFiltersOptions(filter: string): string[] | null {
        return this.filterOptions?.[filter as keyof FilterOptions] ?? null
    }

    public mergeAndApplyFilters(filters: Filters): void {
        this.filterAction.emit(filters)
    }

    ngAfterViewInit() {
        // Added this to customize the click handling of filter menu
        // Need to close filter menu if clicked outside of the filter menu and options
        document.addEventListener('click', (e) => {
            const filterMenuCard = document.getElementById('filter-menu')
            const filterMenuBtn = document.getElementById('filter-menu-btn')
            const isFilterOption = Array.from(
                (e.target as HTMLElement).classList || []
            ).some((item) => item.includes('mdc-list-item'))
            if (!filterMenuCard || !filterMenuBtn) return

            if (
                !filterMenuCard.contains(e.target as Node) &&
                !filterMenuBtn.contains(e.target as Node) &&
                !isFilterOption
            )
                this.isOpen = false
        })
    }
}
