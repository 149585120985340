<div
    class="omni-field"
    (click)="openDropDown()"
    (mouseleave)="openDropDown(true)"
>
    <div class="omni-label">
        {{ selectedPlaceholder }}
        <img
            [class.rotate-180]="isDropDownOpen"
            class="arrow-icon"
            src="../../../../assets/images/icons/arrow.svg"
            alt=""
        />
    </div>
    <ng-container *ngIf="isDropDownOpen">
        <div class="omni-select-container">
            <div
                *ngFor="let item of list"
                class="omni-select-item pointer"
                (click)="onSelect(item)"
            >
                {{ item.name }}
            </div>
            <ng-container *ngIf="!list.length"> No items </ng-container>
        </div>
    </ng-container>
</div>
