import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
    selector: 'omni-select',
    templateUrl: './omni-select.component.html',
    styleUrls: ['./omni-select.component.scss'],
})
export class OmniSelectComponent {
    @Input() list: Array<{ name: string; id: string }> = []
    @Input() placeholder: string = ''

    @Output() onSelectE: EventEmitter<{ name: string; id: string }> =
        new EventEmitter()

    public selectedPlaceholder = ''
    public isDropDownOpen = false

    constructor() {}

    ngOnInit() {
        this.selectedPlaceholder = this.placeholder
    }

    public onSelect(item: { name: string; id: string }) {
        this.selectedPlaceholder = item.name
        this.onSelectE.emit(item)
    }

    public openDropDown(close?: boolean) {
        if (close) {
            this.isDropDownOpen = false
            return
        } else {
            this.isDropDownOpen = !this.isDropDownOpen
        }
    }
}
