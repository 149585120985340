import { Filters } from 'src/app/state/models/filters.models'

export function mergeFilters(newFilters: Filters, oldFilters: Filters) {
    const uniqueCategory = (newFilters.category || []).filter(
        (value) => !oldFilters.category || !oldFilters.category.includes(value)
    )
    const uniqueSubCategory = (newFilters.sub_category || []).filter(
        (value) =>
            !oldFilters.sub_category || !oldFilters.sub_category.includes(value)
    )
    const uniqueTrendType = (newFilters.trend_type || []).filter(
        (value) =>
            !oldFilters.trend_type || !oldFilters.trend_type.includes(value)
    )
    const uniqueItemType = (newFilters.item_type || []).filter(
        (value) =>
            !oldFilters.item_type || !oldFilters.item_type.includes(value)
    )
    const uniqueDepartment = (newFilters.department || []).filter(
        (value) =>
            !oldFilters.department || !oldFilters.department.includes(value)
    )
    const uniqueCreator = (newFilters.creator || []).filter(
        (value) => !oldFilters.creator || !oldFilters.creator.includes(value)
    )

    return {
        item_type: [...(oldFilters.item_type || []), ...uniqueItemType],
        department: [...(oldFilters.department || []), ...uniqueDepartment],
        category: [...(oldFilters.category || []), ...uniqueCategory],
        sub_category: [
            ...(oldFilters.sub_category || []),
            ...uniqueSubCategory,
        ],
        trend_type: [...(oldFilters.trend_type || []), ...uniqueTrendType],
        creator: [...(oldFilters.creator || []), ...uniqueCreator],
        search: newFilters.search || oldFilters.search,
    }
}

export function removeEmptyFilters(filters: Filters) {
    const newFilters: Filters = { ...filters }
    Object.keys(newFilters).forEach((key: string) => {
        if (!newFilters[key as keyof Filters]) {
            delete newFilters[key as keyof Filters]
        }
    })
    return newFilters
}
