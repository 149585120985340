import { NgModule } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { ActivatedRoute, RouterModule, Routes } from '@angular/router'
import { Store } from '@ngrx/store'
import { Subscription } from 'rxjs'
import { GalleryComponent } from './gallery/gallery.component'
import { ItemComponent } from './item/item.component'
import { LoginComponent } from './login/login.component'
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component'
import { ProjectSelectionComponent } from './shared/components/project-selection/project-selection.component'
import { AuthGuard } from './state/auth/auth.guard'
import { Filters } from './state/models/filters.models'
import { Item } from './state/models/items.models'
import { Project } from './state/models/projects.models'
import { AppState } from './state/store'
import { getOneProject } from './state/store/projects/projects.actions'
import {
    selectActiveItem,
    selectActiveProject,
} from './state/store/projects/projects.selectors'
import { TopAttributesComponent } from './top-attributes/top-attributes.component'
import { ManageUsers } from './manage-users/manage-users.component'
import { ResetPasswordComponent } from './reset-password/reset-password.component'

const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
    },
    {
        path: 'login',
        component: LoginComponent,
        canActivate: [],
        title: 'Login',
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        canActivate: [],
        title: 'Forgot Password',
    },
    {
        path: 'reset-password',
        component: ResetPasswordComponent,
        canActivate: [],
        title: 'Reset Password',
    },
    {
        path: 'project-selection',
        component: ProjectSelectionComponent,
        canActivate: [AuthGuard],
        title: 'Select project',
    },
    {
        path: 'top-attributes',
        component: TopAttributesComponent,
        canActivate: [AuthGuard],
        title: 'Top Trending Attributes',
    },
    {
        path: 'gallery',
        component: GalleryComponent,
        canActivate: [AuthGuard],
        title: 'Gallery',
    },
    {
        path: 'item/:itemId',
        component: ItemComponent,
        canActivate: [AuthGuard],
        title: 'Concept',
    },
    {
        path: 'manage-users',
        component: ManageUsers,
        canActivate: [AuthGuard],
        title: 'Manage Users',
        data: {
            hideComponent: true,
        },
    },
]

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {
    private queryProjectId: string = ''
    private queryProjectActiveFilters: Filters = {}
    private subscription = new Subscription()
    public activeProject$: Project | null = null
    public activeItem$: Item | null = null

    constructor(
        private route: ActivatedRoute,
        private store: Store<AppState>,
        private dialog: MatDialog
    ) {
        this.subscription.add(
            this.store
                .select(selectActiveProject)
                .subscribe((activeProject) => {
                    this.activeProject$ = activeProject
                })
        )
        this.subscription.add(
            this.store.select(selectActiveItem).subscribe((activeItem) => {
                this.activeItem$ = activeItem
            })
        )
        this.route.queryParams.subscribe((queryParams) => {
            this.queryProjectId = queryParams['projectId']
            this.queryProjectActiveFilters = queryParams['filters']
            if (
                this.queryProjectId &&
                this.queryProjectId != this.activeProject$?.id
            ) {
                this.store.dispatch(
                    getOneProject({
                        payload: {
                            id: this.queryProjectId,
                            filters: {},
                        },
                    })
                )
            } else if (!this.queryProjectId && this.activeProject$?.id) {
                this.store.dispatch(
                    getOneProject({
                        payload: {
                            id: this.queryProjectId || this.activeProject$?.id,
                            filters: {},
                        },
                    })
                )
            }
        })
    }
}
