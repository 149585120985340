import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, exhaustMap, map } from 'rxjs/operators'
import { CommentsWebService } from '../../services/comments-web.service'
import {
    CommentsActions,
    CommentsUnion,
    createCommentFailure,
    createCommentSuccess,
    getCommentsFailure,
    getCommentsSuccess,
} from './comments.action'
import { UsersWebService } from '../../services/users-web.service'

@Injectable()
export class CommentsEffects {
    public createComment$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CommentsActions.CREATE_COMMENT),
            exhaustMap(({ payload }) => {
                const comment = { item_id: payload.item_id, text: payload.text }
                return this.commentsWebService.createComment(comment).pipe(
                    map((res) => {
                        return createCommentSuccess({
                            payload: res,
                        })
                    }),
                    catchError((err) => {
                        this.usersWebService.catchError(err)
                        return of(createCommentFailure({ payload: payload }))
                    })
                )
            })
        )
    })

    public getComments$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CommentsActions.GET_COMMENTS),
            exhaustMap(({ payload }) => {
                return this.commentsWebService.getComments(payload).pipe(
                    map((res) => {
                        return getCommentsSuccess({
                            payload: res,
                        })
                    }),
                    catchError((err) => {
                        this.usersWebService.catchError(err)
                        return of(getCommentsFailure())
                    })
                )
            })
        )
    })

    public retryComment$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(CommentsActions.RETRY_COMMENTS),
            exhaustMap(({ payload }: any) => {
                console.log('payload here', payload)
                const comment: any = {
                    item_id: payload.item_id,
                    text: payload.text,
                }
                return this.commentsWebService.createComment(comment).pipe(
                    map((res) => {
                        return createCommentSuccess({
                            payload: res,
                        })
                    }),
                    catchError((err) => {
                        this.usersWebService.catchError(err)
                        return of(createCommentFailure({ payload: payload }))
                    })
                )
            })
        )
    })

    constructor(
        private actions$: Actions<CommentsUnion>,
        private commentsWebService: CommentsWebService,
        private usersWebService: UsersWebService
    ) {}
}
