<navbar></navbar>
<div class="page-container container-height">
    <sidebar></sidebar>
    <div class="mt-80 ml-80 w-100 h-100">
        <div class="d-flex flex-wrap align-items-center">
            <mat-form-field
                class="filter-table mr-3 search-filter d-flex no-hint"
            >
                <mat-label>Search</mat-label>
                <mat-icon matPrefix>search</mat-icon>
                <input matInput placeholder="Ex. Mia" #input />
            </mat-form-field>
            <filter-menu
                [activeFilters]="activeFilters"
                [filterOptions]="filterOptions"
                [getSelectedFilterCount]="getSelectedFilterCount"
                (filterAction)="mergeAndApplyFilters($event)"
            >
            </filter-menu>
            <div class="ml-3">
                <date-picker
                    [isDateRangeSelected]="isDateRangeSelected"
                    (selectedDateRange)="onDateRangeSelected($event)"
                >
                </date-picker>
            </div>
            <creator-filter
                class="ml-3"
                (filterAction)="mergeAndApplyFilters($event)"
            />
            <div
                class="d-flex align-items-center pointer ml-3"
                (click)="toggleLiked()"
            >
                <button
                    mat-button
                    class="liked-btn"
                    [ngClass]="{ liked: isLiked, unliked: !isLiked }"
                >
                    <mat-icon
                        matPrefix
                        [ngClass]="{ liked: isLiked, unliked: !isLiked }"
                        >favorite</mat-icon
                    >
                    Show Liked
                </button>
            </div>
            <div
                *ngIf="getSelectedFilterCount > 0 || isDateRangeSelected"
                class="mx-3"
            >
                <reset-filters (resetFilters)="resetFilters()"></reset-filters>
            </div>
        </div>
        <filter-chips-row
            [activeFilters]="activeFilters"
            (filterAction)="updateActiveProject($event)"
        ></filter-chips-row>
        <div class="w-100 mt-2">
            <div class="d-flex flex-wrap">
                <div
                    class="col py-3 mx-2 item-content"
                    *ngFor="let item of getAllItems"
                >
                    <ng-container *ngIf="item?.type === 'loader'">
                        <ng-container
                            *ngTemplateOutlet="loaderTemplate"
                        ></ng-container>
                    </ng-container>
                    <item-card
                        *ngIf="item?.type !== 'loader'"
                        [item]="item"
                        (onLikeItem)="likeItem($event)"
                        (filterAction)="mergeAndApplyFilters($event)"
                    ></item-card>
                </div>
            </div>
            <mat-paginator
                *ngIf="!!activeProject?.total_items || getAllItems.length"
                class="mr-4"
                [pageSize]="pageSize"
                [pageIndex]="currentPage"
                [pageSizeOptions]="pageSizeOptions"
                [length]="activeProject?.total_items || getAllItems.length"
                (page)="handlePageEvent($event)"
            ></mat-paginator>
        </div>

        <div
            *ngIf="!activeProject?.total_items && !getAllItems.length"
            class="d-flex flex-column justify-content-center align-items-center w-100 h-100"
        >
            <img
                src="../../assets/images/not-found.svg"
                class="not-found-img"
            />
            <div class="fs-18">You don’t have any item generated.</div>
            <div
                class="pointer navigate-item fs-18"
                (click)="handleRedirection()"
            >
                Navigate to generate items.
            </div>
        </div>
    </div>
</div>

<ng-template #loaderTemplate>
    <div class="skeleton-container">
        <ngx-skeleton-loader
            count="1"
            animation="pulse"
            [theme]="{
                'border-radius': '10px',
                width: '100%',
                height: '100%',
                'background-color': '#EFF1F6'
            }"
        ></ngx-skeleton-loader>
        <img
            class="grayed-logo"
            src="../../assets/images/grayed_logo.svg"
            onerror="this.src='../../assets/images/grayed_logo.svg';"
        />
    </div>
    <div class="skeleton-name-container">
        <ngx-skeleton-loader
            count="1"
            animation="pulse"
            [theme]="{
                'border-radius': '10px',
                width: '100%',
                height: '100%',
                'background-color': '#EFF1F6'
            }"
        ></ngx-skeleton-loader>
    </div>
</ng-template>
