<div
    [ngClass]="{
        'without-chips': selectedFilters.length === 0
    }"
    class="outer-container"
>
    <span class="mt-6">Select Item Domain:</span>
    <div class="d-flex flex-wrap mt-2">
        <img
            *ngIf="selectedFilters.length"
            id="reset-filters-btn"
            class="mr-2"
            src="assets/images/icons/reset-icon.svg"
            alt="logo"
            (click)="onReset()"
        />
        <mat-chip *ngFor="let filter of selectedFilters" class="mat-chip m-1">
            {{ filter }}
            <mat-icon
                matSuffix
                class="pointer ml-1 mat-cross-icon"
                (click)="onRemove(filter)"
                >close
            </mat-icon>
        </mat-chip>
    </div>
    <div class="mt-2 w-100">
        <mat-form-field class="w-100 mat-field-width">
            <mat-label>Search</mat-label>
            <mat-icon matPrefix>search</mat-icon>
            <input
                #filterInput
                matInput
                type="text"
                [formControl]="filterControl"
                [matAutocomplete]="auto"
                (keydown.enter)="onEnterPressed()"
            />
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option
                    *ngFor="let option of getFilterOptions"
                    [value]="option"
                    (click)="onFilterOptionSelected(option)"
                >
                    {{ option }}
                </mat-option>
            </mat-autocomplete>
        </mat-form-field>
    </div>
</div>
