import { animate, state, style, transition, trigger } from '@angular/animations'
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core'
import { FormControl, FormGroup } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Store } from '@ngrx/store'
import { union } from 'lodash'
import { Subscription } from 'rxjs'
import { ProjectItemsGenerationParams } from 'src/app/state/models/items.models'
import { Project } from 'src/app/state/models/projects.models'
import { TopAttribute } from 'src/app/state/models/top-attributes.models'
import { AppState } from 'src/app/state/store'
import {
    addskeletonLoaders,
    generateAdvancedItems,
    generateProjectItems,
} from 'src/app/state/store/projects/projects.actions'
import { selectActiveProject } from 'src/app/state/store/projects/projects.selectors'
import {
    selectActiveFilters,
    selectActiveTopAttributes,
} from 'src/app/state/store/top-attributes/top-attributes.selectors'
import { Router } from '@angular/router'

@Component({
    selector: 'item-generation',
    templateUrl: './item-generation.component.html',
    styleUrls: ['./item-generation.component.scss'],
    animations: [
        trigger('expandState', [
            state('false', style({ height: '36px' })),
            transition('false <=> true', animate('300ms ease-in-out')),
        ]),
    ],
})
export class ItemGenerationComponent implements OnInit, OnDestroy {
    private subscription = new Subscription()
    private activeProject: Project | null = null
    private activeTopAttributes$: TopAttribute[] = []
    private activeFilters: string[] = []
    private expanded = false
    public item_options: string[] = []
    public user_attribute_weight_1: number = 0
    public user_attribute_weight_2: number = 0
    public user_attribute_weight_3: number = 0
    public selectedAttributes: TopAttribute[] = []

    public temperatureTypes: string[] = [
        'Slightly',
        'Moderately',
        'Very',
        'Extremely',
    ]

    public countOptions: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

    @Input() title = 'Generate Items'
    @Output() generateProjectItemsAction: EventEmitter<any> = new EventEmitter()
    @ViewChild('custom_prompt') custom_prompt!: ElementRef<HTMLInputElement>

    public form: FormGroup
    public weight1: FormControl = new FormControl({
        value: 0.7,
        disabled: false,
    })
    public weight2: FormControl = new FormControl({
        value: 0,
        disabled: true,
    })
    public weight3: FormControl = new FormControl({
        value: 0,
        disabled: true,
    })
    public n_of_items = new FormControl(6)

    constructor(
        private store: Store<AppState>,
        private snackbar: MatSnackBar,
        private router: Router
    ) {
        this.form = new FormGroup({
            user_attribute_1: new FormControl(''),
            user_attribute_weight_1: this.weight1,
            user_attribute_2: new FormControl({ value: '', disabled: true }),
            user_attribute_weight_2: this.weight2,
            user_attribute_3: new FormControl({ value: '', disabled: true }),
            user_attribute_weight_3: this.weight3,
            custom_prompt: new FormControl(''),
            temperature_slider: new FormControl(0),
        })

        this.form.get('user_attribute_1')?.valueChanges.subscribe((val) => {
            const controls = ['user_attribute_2', 'user_attribute_weight_2']
            if (val) {
                this.form.get(controls[0])!.enable()
                this.form.get(controls[1])!.enable()
            } else {
                this.form.get(controls[0])!.disable()
                this.form.get(controls[1])!.disable()
                this.form.get(controls[0])?.setValue('')
                this.form.get(controls[1])?.setValue(0)
            }
        })

        this.form.get('user_attribute_2')?.valueChanges.subscribe((val) => {
            const controls = ['user_attribute_3', 'user_attribute_weight_3']
            if (val) {
                this.form.get(controls[0])!.enable()
                this.form.get(controls[1])!.enable()
            } else {
                this.form.get(controls[0])!.disable()
                this.form.get(controls[1])!.disable()
                this.form.get(controls[0])?.setValue('')
                this.form.get(controls[1])?.setValue(0)
            }
        })
    }

    get formattedAttributes(): string {
        const attributes = this.selectedAttributes.map((obj) => obj.name)

        if (attributes.length === 1) return attributes[0]
        else if (attributes.length === 2) return attributes.join(' and ')
        else if (attributes.length === 3)
            return `${attributes[0]}, ${attributes[1]} and ${attributes[2]}.`
        else if (attributes.length > 3) {
            return `${attributes.slice(0, 3).join(', ')}, and ${
                attributes.length - 3
            } more.`
        }

        return ''
    }

    get moreAttributes() {
        if (this.selectedAttributes.length > 4) {
            return this.selectedAttributes
                .slice(4)
                .map((attr) => attr.name)
                .join(', ')
        } else {
            return ''
        }
    }

    public expand(): void {
        this.expanded = !this.expanded
    }

    public onFilterValueSelected(selectedFilters: string[]) {
        this.item_options = selectedFilters
    }

    public resetForm(): void {
        this.form.value.custom_prompt = ''
        this.form.get('user_attribute_1')?.setValue('')
        this.form.get('user_attribute_2')?.setValue('')
        this.form.get('user_attribute_3')?.setValue('')
        this.weight1.setValue(0)
        this.weight2.setValue(0)
        this.weight3.setValue(0)
        this.custom_prompt.nativeElement.value = ''
        this.item_options = [...this.activeFilters]
        this.form.get('temperature_slider')?.setValue(0)
        this.n_of_items.setValue(6)
    }

    public generateProjectItems(): void {
        const weightsString: string = [
            (this.weight1.value ? `${this.weight1.value} ` : '') +
                (this.form.value.user_attribute_1 || ''),
            (this.weight2.value ? `${this.weight2.value} ` : '') +
                (this.form.value.user_attribute_2 || ''),
            (this.weight3.value ? `${this.weight3.value} ` : '') +
                (this.form.value.user_attribute_3 || ''),
        ]
            .filter((item) => !!item)
            .join(', ')
        let customPrompt: string = this.form.value.custom_prompt || ''
        customPrompt = `${
            weightsString ? weightsString + '. ' : ''
        }${customPrompt}`
        const projectItemsGenerationParams: ProjectItemsGenerationParams = {
            project_id: this.activeProject?.id as string,
            top_attributes: this.activeTopAttributes$,
            custom_prompt: customPrompt,
            item_options: this.item_options ?? [],
            temperature_slider: this.form.value.temperature_slider as number,
        }
        // Create an array of API calls based on n_of_items
        const apiCalls = Array(this.n_of_items.value as any).fill(
            projectItemsGenerationParams
        )
        this.router.navigate(['gallery'])
        // Dispatch the new action for generating multiple items
        this.store.dispatch(
            generateProjectItems({
                payload: {
                    project_id: this.activeProject?.id as string,
                    apiCalls: apiCalls,
                },
            })
        )
        this.store.dispatch(
            addskeletonLoaders({
                project_id: this.activeProject?.id as string,
                n_of_items: apiCalls.length,
            })
        )
    }

    public generateAdvancedItems(): void {
        this.store.dispatch(
            generateAdvancedItems({
                payload: {
                    project_id: (this.activeProject?.id || '') as string,
                },
            })
        )
    }

    ngOnInit(): void {
        this.subscription.add(
            this.store
                .select(selectActiveProject)
                .subscribe((project: Project | null) => {
                    this.activeProject = project
                })
        )

        this.subscription.add(
            this.store
                .select(selectActiveTopAttributes)
                .subscribe((topAttributes) => {
                    this.activeTopAttributes$ = topAttributes
                })
        )

        this.subscription.add(
            this.store.select(selectActiveFilters).subscribe((res) => {
                this.activeFilters = Object.values(res)
                    .filter((item) => Array.isArray(item))
                    .flat()
                this.item_options = union(this.item_options, this.activeFilters)
            })
        )

        this.subscription.add(
            this.store.select(selectActiveTopAttributes).subscribe((res) => {
                const attributes = res
                this.selectedAttributes = Array.from(new Set(attributes))
            })
        )
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe()
    }
}
