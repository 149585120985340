import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core'
import { DateRange } from '@angular/material/datepicker'

@Component({
    selector: 'date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements AfterViewInit {
    public isOpen: boolean | null = false
    public selectedRangeValue: DateRange<Date> | null = null

    @Input() public isDateRangeSelected: boolean | null = false
    @Output() public selectedDateRange: EventEmitter<any> = new EventEmitter()

    public selectedChange(date: Date): void {
        const start = this.selectedRangeValue?.start
        const end = date

        if (start) {
            if (end < start)
                this.selectedRangeValue = new DateRange<Date>(end, start)
            else this.selectedRangeValue = new DateRange<Date>(start, end)
        } else this.selectedRangeValue = new DateRange<Date>(date, null)

        if (this.selectedRangeValue.start && this.selectedRangeValue.end) {
            this.selectedDateRange.emit({
                start_date: new Date(
                    this.selectedRangeValue.start
                ).toISOString(),
                end_date: new Date(this.selectedRangeValue.end).toISOString(),
            })
            this.isOpen = false
        }
    }

    public calendarOpened() {
        this.isOpen = !this.isOpen
        if (!this.isDateRangeSelected)
            this.selectedRangeValue = new DateRange<Date>(null, null)
    }

    public dateFormatter(date: Date | any) {
        return new Date(date).toLocaleDateString('en-GB').slice(0, 10)
    }

    public clearDateRange() {
        this.selectedRangeValue = new DateRange<Date>(null, null)
        this.selectedDateRange.emit({
            start_date: null,
            end_date: null,
        })
    }

    ngAfterViewInit() {
        // Added this to customize the click handling of filter menu
        // Need to close filter menu if clicked outside of the filter menu and options
        document.addEventListener('click', (e) => {
            const datePickerBtn = document.getElementById('datepicker-btn')
            const calendar = document.getElementById('calendar')
            const calendarMenu = document.getElementById('calendar-menu')

            // Check if the target element or any of its parents have the specified class
            const isInCalendarCell = (node: Node | null): boolean => {
                while (node) {
                    if (
                        (node as Element).classList?.contains(
                            'mat-calendar-body-cell-content'
                        )
                    ) {
                        return true
                    }
                    node = node.parentNode
                }
                return false
            }

            if (
                !datePickerBtn?.contains(e.target as Node) &&
                !calendar?.contains(e.target as Node) &&
                !calendarMenu?.contains(e.target as Node) &&
                !isInCalendarCell(e.target as Node)
            )
                this.isOpen = false
        })
    }
}
