<div class="d-flex flex-wrap my-3">
    <mat-chip *ngFor="let filter of selectedFilters" class="mat-chip m-1">
        <img matChipAvatar [src]="filter.icon" />
        <span class="caption">
            {{ filter.value }}
        </span>
        <mat-icon
            matSuffix
            class="pointer ml-1 mat-cross-icon"
            (click)="removeSelectedOption(filter)"
            >close</mat-icon
        >
    </mat-chip>
</div>
