import { createAction, props, union } from '@ngrx/store'
import { Filters } from '../../models/filters.models'
import {
    GetTopAttributesResponse,
    TopAttribute,
} from '../../models/top-attributes.models'

export enum TopAttributesActions {
    GET_TOP_ATTRIBUTES = '[TopAttributes] Get TopAttributes',
    GET_TOP_ATTRIBUTES_SUCCESS = '[TopAttributes] Get TopAttributes Success',
    GET_TOP_ATTRIBUTES_FAILURE = '[TopAttributes] Get TopAttributes Failure',
}

export enum TopAttributesLocalActions {
    ACTIVATE_TOP_ATTRIBUTE = '[TopAttributes] Activate TopAttribute',
    DEACTIVATE_TOP_ATTRIBUTE = '[TopAttributes] Deactivate TopAttribute',
}

export const getTopAttributes = createAction(
    TopAttributesActions.GET_TOP_ATTRIBUTES,
    props<{ payload: Filters }>()
)
export const getTopAttributesSuccess = createAction(
    TopAttributesActions.GET_TOP_ATTRIBUTES_SUCCESS,
    props<{ payload: GetTopAttributesResponse }>()
)
export const getTopAttributesFailure = createAction(
    TopAttributesActions.GET_TOP_ATTRIBUTES_FAILURE
)

export const activateTopAttribute = createAction(
    TopAttributesLocalActions.ACTIVATE_TOP_ATTRIBUTE,
    props<{ payload: TopAttribute }>()
)

export const deactivateTopAttribute = createAction(
    TopAttributesLocalActions.DEACTIVATE_TOP_ATTRIBUTE,
    props<{ payload: TopAttribute }>()
)

const all = union({
    getTopAttributes,
    getTopAttributesSuccess,
    getTopAttributesFailure,
    activateTopAttribute,
    deactivateTopAttribute,
})

export type TopAttributesUnion = typeof all
