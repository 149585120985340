import { Component, EventEmitter, Output } from '@angular/core'

@Component({
    selector: 'reset-filters',
    templateUrl: './reset-filters.component.html',
    styleUrls: ['./reset-filters.component.scss'],
})
export class ResetFilters {
    @Output() resetFilters: EventEmitter<any> = new EventEmitter()

    resetActiveFilters() {
        this.resetFilters.emit()
    }
}
