<div class="navbar-container">
    <div class="left-items">
        <div class="logo pointer" (click)="goTo('top-attributes')">
            <img src="assets/images/logo.png" alt="Logo" />
        </div>
        <div class="h5 mb-0">{{ routeTitle }}</div>
    </div>
    <div class="right-items">
        <project-selection-dropdown />

        <avatar
            class="pointer"
            [creator]="loggedInUser"
            [avatarWidth]="34"
            [avatarHeight]="34"
            [fontSize]="12"
            [matMenuTriggerFor]="userMenu"
        />
        <mat-menu #userMenu="matMenu">
            <button mat-menu-item (click)="resetPassword()">
                <mat-icon>vpn_key</mat-icon>
                <span>Reset Password</span>
            </button>
            <button mat-menu-item (click)="logout()">
                <mat-icon>logout</mat-icon>
                <span>Logout</span>
            </button>
        </mat-menu>

        <ng-container *ngIf="0">
            <button
                mat-raised-button
                color="primary"
                class="brand-guidelines-button"
            >
                <img
                    matChipAvatar
                    class="ml-2 mr-1 brand-guideline-img"
                    src="../../../../assets/images/icons/brand-guideline.svg"
                    alt=""
                />
                Brand Guidelines
            </button>
        </ng-container>
    </div>
</div>
