<div
    class="avatar"
    #tooltip="matTooltip"
    [matTooltip]="userFullName"
    [ngStyle]="{
        'width.px': avatarWidth,
        'height.px': avatarHeight,
        'font-size.px': fontSize
    }"
>
    <div>{{ getInitials(creator) }}</div>
</div>
