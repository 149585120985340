import { Component, OnInit, OnDestroy } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/state/store'
import { LocalStorage } from 'src/app/state/local-storage'
import {
    logout,
    resetPasswordRequest,
} from 'src/app/state/store/users/users.actions'
import { LoggedInUser } from 'src/app/state/models/users.models'
import { selectLoggedInUser } from 'src/app/state/store/users/users.selectors'
import { Subscription } from 'rxjs'

@Component({
    selector: 'navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, OnDestroy {
    public routeTitle: string = ''
    public loggedInUser: any = null
    private subscription: Subscription = new Subscription()

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private store: Store<AppState>
    ) {}

    ngOnInit(): void {
        this.subscription.add(
            this.route.title.subscribe((title: string | undefined): void => {
                this.routeTitle = title || ''
            })
        )

        this.subscription.add(
            this.store.select(selectLoggedInUser).subscribe((user) => {
                this.loggedInUser = user
            })
        )
    }

    public goTo(nav: string) {
        this.router.navigate([nav])
    }

    public logout(): void {
        this.store.dispatch(logout())
        LocalStorage.clearAllState()
        window.location.href = '/login'
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe()
    }

    public resetPassword(): void {
        if (this.loggedInUser && this.loggedInUser.username) {
            this.store.dispatch(
                resetPasswordRequest({ username: this.loggedInUser.username })
            )
        }
    }
}
