import {
    AfterViewInit,
    Component,
    EventEmitter,
    OnDestroy,
    OnInit,
    Output,
} from '@angular/core'
import { Store } from '@ngrx/store'
import { Subscription } from 'rxjs'
import { Creator } from 'src/app/state/models/creator.models'
import { Project } from 'src/app/state/models/projects.models'
import { AppState } from 'src/app/state/store'
import { selectActiveProject } from 'src/app/state/store/projects/projects.selectors'
import { userFullName } from '../../helpers/utils'

@Component({
    selector: 'creator-filter',
    templateUrl: './creator-filter.component.html',
    styleUrls: ['./creator-filter.component.scss'],
})
export class CreatorFilter implements OnInit, AfterViewInit, OnDestroy {
    private subscription = new Subscription()
    public activeProject: Project | null = null
    public isOpened: boolean = false
    public searchTerm: string = ''
    public creatorOptions: Creator[] = []
    public selectedUsers: Creator[] = []
    public userFullName = userFullName
    @Output() filterAction: EventEmitter<any> = new EventEmitter()

    get moreUsers() {
        if (this.selectedUsers.length > 1)
            return this.selectedUsers
                .slice(1)
                .map((user: any) =>
                    user.first_name && user.last_name
                        ? user.first_name + ' ' + user.last_name
                        : user.username
                )
                .join(', ')
        else return ''
    }

    get filteredCreators(): Creator[] {
        return this.creatorOptions.filter((user: any) => {
            // Convert searchTerm to lowercase
            const searchTermLower = this.searchTerm?.toLowerCase()

            // Create a full name string if both first and last name are available
            const fullName =
                user.first_name && user.last_name
                    ? (user.first_name + ' ' + user.last_name).toLowerCase()
                    : ''

            // Check if either the full name or username includes the searchTerm
            return (
                fullName.includes(searchTermLower) ||
                user.username?.toLowerCase().includes(searchTermLower)
            )
        })
    }

    constructor(private store: Store<AppState>) {}

    public getSelectedUsers(user: any): any {
        return !!this.selectedUsers.find((item: any) => item.id === user.id)
    }

    public openProjectSelect(): void {
        this.isOpened = !this.isOpened
    }

    public selectCreator(user: any): void {
        const isFound = this.selectedUsers.find(
            (item: any) => item.id === user.id
        )
        if (isFound)
            this.selectedUsers = this.selectedUsers.filter(
                (item: any) => item.id !== user.id
            )
        else this.selectedUsers.push(user)

        const newFilters = {
            creator: this.selectedUsers,
        }
        this.filterAction.emit(newFilters)
    }

    ngOnInit(): void {
        this.subscription.add(
            this.store.select(selectActiveProject).subscribe((res) => {
                this.creatorOptions = res?.filters?.creator || []
            })
        )
    }

    ngAfterViewInit() {
        // Added this to customize the click handling of filter menu
        // Need to close filter menu if clicked outside of the filter menu and options
        document.addEventListener('click', (e) => {
            const creatorListCard = document.getElementById('creator-menu')
            const creatorSelectionButton = document.getElementById(
                'creator-selection-btn'
            )
            if (!creatorListCard || !creatorSelectionButton) return

            if (
                !creatorListCard.contains(e.target as Node) &&
                !creatorSelectionButton.contains(e.target as Node)
            )
                this.isOpened = false
        })
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe()
    }
}
