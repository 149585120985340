export function formatString(inputString: string): string {
    // Replace symbols and non-letter characters with spaces
    const stringWithSpaces = inputString.replace(/[^a-zA-Z ]/g, ' ')

    // Capitalize the first letter and add spaces between words
    const formattedString = stringWithSpaces
        .split(' ')
        .filter((word) => word.trim() !== '')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')

    return formattedString
}
