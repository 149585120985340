<div class="container">
    <!-- Heading -->
    <div class="header">
        <h5>Comments</h5>
    </div>

    <!-- Scrollable Messages Container -->
    <div #messageContainer class="messages-container">
        <div
            *ngFor="let comment of getComments"
            class="comment d-flex"
            [ngClass]="{
                'right-aligned': comment.creator.id === loggedInUser?.id,
                'left-aligned': comment.creator.id !== loggedInUser?.id
            }"
            (mouseover)="showClipboard = comment.id"
            (mouseleave)="showClipboard = null"
        >
            <avatar
                *ngIf="comment.creator.id !== loggedInUser?.id"
                class="mt-2 mr-2"
                [creator]="comment.creator"
            />
            <div
                class="d-flex align-items-center bubble-container"
                [class.justify-content-end]="
                    comment.creator.id === loggedInUser?.id
                "
            >
                <copy-to-clipboard
                    *ngIf="
                        showClipboard === comment.id &&
                        comment.creator.id === loggedInUser?.id
                    "
                    [size]="'15px'"
                    [content]="comment.text"
                />
                <mat-spinner
                    *ngIf="comment.status === 'pending'"
                    class="mr-2"
                    [diameter]="20"
                ></mat-spinner>
                <mat-icon
                    *ngIf="comment.status === 'error'"
                    class="alert-icon mr-2"
                    [matMenuTriggerFor]="menu"
                >
                    info
                </mat-icon>
                <mat-menu #menu="matMenu">
                    <div mat-menu-item (click)="deleteComment(comment)">
                        Delete
                    </div>

                    <div mat-menu-item (click)="retryComment(comment)">
                        Retry
                    </div>
                </mat-menu>
                <div
                    class="comment-bubble"
                    [ngClass]="{
                        'send-comment': comment.creator.id === loggedInUser?.id,
                        'received-comment':
                            comment.creator.id !== loggedInUser?.id
                    }"
                >
                    <div [innerHTML]="sanitizeMessage(comment.text)"></div>
                    <div class="caption d-flex justify-content-end">
                        {{ getCurrentFormattedTime(comment.time_created) }}
                    </div>
                </div>
                <copy-to-clipboard
                    *ngIf="
                        showClipboard === comment.id &&
                        comment.creator.id !== loggedInUser?.id
                    "
                    class="ml-2"
                    [size]="'15px'"
                    [content]="comment.text"
                />
            </div>
            <avatar
                *ngIf="comment.creator.id === loggedInUser?.id"
                class="mt-2 ml-2"
                [creator]="comment.creator"
            />
        </div>
        <div
            *ngIf="!getComments.length"
            class="d-flex flex-column align-items-center h-100 w-100 justify-content-center"
        >
            <img
                src="../../../../assets/images/not-found.svg"
                class="no-comments-img"
            />
            <div class="fs-18 no-comments-text">No comments yet.</div>
            <div class="fs-18 no-comments-text">Start a conversation!</div>
        </div>
    </div>

    <!-- Form Field at the Bottom -->
    <div class="footer">
        <mat-form-field appearance="fill" class="w-100">
            <mat-label>Enter your comment</mat-label>
            <div class="d-flex align-items-start no-resize">
                <textarea
                    matInput
                    rows="5"
                    [(ngModel)]="commentInput"
                    (keydown)="onKeydown($event)"
                ></textarea>
                <button
                    mat-mini-fab
                    class="d-flex justify-content-center align-items-center send-btn"
                    [disabled]="!commentInput.trim()"
                    (click)="sendComment()"
                >
                    <mat-icon matSuffix class="send-icon">send</mat-icon>
                </button>
            </div>
        </mat-form-field>
    </div>
</div>
