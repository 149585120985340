import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import {
    Item,
    LikeItemParams,
    MoveItemToProjectParams,
    ProjectItemsGenerationParams,
} from '../models/items.models'
import { Project } from '../models/projects.models'

@Injectable()
export class ItemsWebService {
    private webServiceUri: string = environment.API_URL + '/items'

    constructor(private http: HttpClient) {}

    public getAllItems(): Observable<Array<Item>> {
        const url = this.webServiceUri
        return this.http.get<Array<Item>>(url)
    }

    public getOneItem(id: string): Observable<Item> {
        const url = this.webServiceUri + `/${id}`
        return this.http.get<Item>(url)
    }

    public likeItem(params: LikeItemParams): Observable<any> {
        const url = this.webServiceUri + `/${params.itemId}/like`
        return this.http.post<any>(url, null, {
            params: { is_liked: params.isLiked },
        })
    }

    public moveItemToProject(params: MoveItemToProjectParams): Observable<any> {
        const url =
            this.webServiceUri + `/${params.itemId}/save/${params.projectId}`
        return this.http.post<any>(url, null)
    }

    public generateProjectItems(
        body: ProjectItemsGenerationParams
    ): Observable<Item> {
        const url = this.webServiceUri

        const { temperature_slider, ...payload } = body

        const params: any = {
            project_id: body.project_id,
            temperature: temperature_slider,
        }

        return this.http.post<Item>(url, payload, {
            params,
        })
    }

    public generateItemComponents(id: string): {
        res: Observable<{ msg: string }>
    } {
        const url = this.webServiceUri + `/${id}/components`
        const response = this.http.post<{ msg: string }>(url, null)
        return { res: response }
    }

    public deleteItem(id: string): Observable<boolean> {
        const url = this.webServiceUri + `/${id}`
        return this.http.delete<any>(url)
    }

    public generateAdvancedItems(project_id: string): {
        res: Observable<{ msg: string }>
    } {
        const url = this.webServiceUri + '/random'
        const response = this.http.post<any>(url, null, {
            params: { project_id },
        })
        return { res: response }
    }
}
