import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Router, ActivatedRoute } from '@angular/router'
import { Store, select } from '@ngrx/store'
import { AppState } from '../state/store'
import { MatSnackBar } from '@angular/material/snack-bar'
import { resetPassword } from '../state/store/users/users.actions'
import { selectIsLoading } from '../state/store/users/users.selectors'
import { Subscription } from 'rxjs'

@Component({
    selector: 'reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
    private subscription = new Subscription()
    private token: string | null = null
    public resetPasswordForm: FormGroup
    public newPassword: FormControl
    public confirmPassword: FormControl
    public isLoading: boolean = false

    constructor(
        private store: Store<AppState>,
        private router: Router,
        private snackbar: MatSnackBar,
        private route: ActivatedRoute
    ) {
        this.newPassword = new FormControl('', [Validators.required])
        this.confirmPassword = new FormControl('', [Validators.required])

        this.resetPasswordForm = new FormGroup({
            newPassword: this.newPassword,
            confirmPassword: this.confirmPassword,
        })

        // Subscribe to value changes for real-time password match validation
        this.newPassword.valueChanges.subscribe(() => this.checkPasswordMatch())
        this.confirmPassword.valueChanges.subscribe(() =>
            this.checkPasswordMatch()
        )
    }

    private checkPasswordMatch(): void {
        if (this.newPassword.value !== this.confirmPassword.value) {
            this.confirmPassword.setErrors({ mismatch: true })
        } else {
            this.confirmPassword.setErrors(null)
        }
    }

    public resetPassword(): void {
        if (
            this.resetPasswordForm.invalid ||
            this.confirmPassword.hasError('mismatch') ||
            !this.token
        ) {
            this.snackbar.open(
                'Please fill in all fields correctly and ensure passwords match',
                'Ok',
                {
                    duration: 3000,
                }
            )
            return
        }
        const { newPassword } = this.resetPasswordForm.value
        this.store.dispatch(
            resetPassword({
                payload: {
                    token: this.token,
                    new_password: newPassword,
                },
            })
        )
    }

    public goToLogin(): void {
        this.router.navigate(['/login'])
    }

    ngOnInit(): void {
        // Extract token from route params
        this.route.queryParams.subscribe((params) => {
            this.token = params['token']
        })

        this.subscription.add(
            this.store
                .pipe(select(selectIsLoading))
                .subscribe((isLoading) => (this.isLoading = isLoading))
        )
    }
}
