<navbar></navbar>
<mat-drawer-container class="drawer-container">
    <!-- Comments (Mobile view) -->
    <mat-drawer #commentsNav mode="over" position="end" [opened]="false">
        <div class="white-container no-radius comments-nav-container">
            <comment-bubbles></comment-bubbles>
        </div>
    </mat-drawer>
    <mat-drawer-content>
        <div class="page-container w-auto">
            <sidebar></sidebar>

            <div id="main_container" class="ml-80 w-100">
                <div class="info-container">
                    <div class="top-item-bar w-100">
                        <div class="d-flex pointer">
                            <div class="back-button" (click)="backToGallery()">
                                <img
                                    src="../../assets/images/icons/arrow.svg"
                                    alt=""
                                />
                                <span class="caption">Back</span>
                            </div>
                            <div
                                *ngIf="item?.creator?.id === loggedInUser?.id"
                                class="delete-item-button ml-2"
                                (click)="deleteItem()"
                            >
                                <span class="caption">Delete Item</span>
                            </div>
                        </div>

                        <div class="d-flex align-center">
                            <h4>{{ item?.name }}</h4>
                            <button
                                mat-mini-fab
                                color="primary"
                                class="ml-4 comments-btn"
                                matTooltip="View comments"
                                (click)="toggleCommentsDrawer()"
                            >
                                <mat-icon style="color: white">
                                    comment
                                </mat-icon>
                            </button>
                        </div>
                    </div>

                    <section id="main_info">
                        <div class="image-section-container">
                            <ng-template
                                [ngTemplateOutlet]="item_image"
                            ></ng-template>
                            <div id="expandable_bars">
                                <ng-template
                                    *ngTemplateOutlet="
                                        expandable_bar;
                                        context: {
                                            $implicit: {
                                                isOpened: true,
                                                title: 'Description',
                                                content: item?.description
                                            }
                                        }
                                    "
                                ></ng-template>
                                <ng-template
                                    *ngTemplateOutlet="
                                        expandable_bar;
                                        context: {
                                            $implicit: {
                                                isOpened: false,
                                                title: 'Why are we suggesting this design?',
                                                content: itemMLExplain,
                                                last: true
                                            }
                                        }
                                    "
                                ></ng-template>
                            </div>
                        </div>
                        <div id="table_section">
                            <ng-template
                                [ngTemplateOutlet]="omni_table"
                            ></ng-template>
                        </div>
                    </section>
                </div>

                <!-- Comments (Desktop view) -->
                <div class="comments-container white-container no-radius">
                    <comment-bubbles></comment-bubbles>
                </div>
            </div>
        </div>
    </mat-drawer-content>
</mat-drawer-container>

<ng-template #expandable_bar let-data>
    <div
        class="expandable-element-container pointer omni-scroll white-container"
        (click)="data.isOpened = !data.isOpened"
        [class.active]="
            data.isOpened && (data.content || data.contentTemplateId)
        "
        [class.last]="data.last"
        [class.fullHeight]="data.contentTemplateId"
    >
        <span class="ee-title-container">
            <ng-container
                *ngIf="data.title || data.placeHolder; else titleElseBlock"
                class="w-100"
            >
                <div
                    class="d-flex justify-content-center align-items-center w-100"
                >
                    <img
                        class="arrow-icon"
                        src="/assets/images/icons/arrow.svg"
                        alt=""
                    />
                    <div
                        class="d-flex align-items-center justify-content-between w-100"
                    >
                        <h6 class="ee-title">
                            {{ data.title ? data.title : data.placeHolder }}
                        </h6>

                        <copy-to-clipboard
                            [content]="data.content"
                        ></copy-to-clipboard>
                    </div>
                </div>
            </ng-container>
            <ng-template
                #titleElseBlock
                [ngTemplateOutlet]="data.titleTemplateId"
            ></ng-template>
        </span>
        <span class="ee-explain">
            <ng-container *ngIf="data.content; else contentElseBlock">
                <div [innerHTML]="data.content"></div>
            </ng-container>
            <ng-template
                #contentElseBlock
                [ngTemplateOutlet]="data.contentTemplateId"
            ></ng-template>
        </span>
    </div>
</ng-template>

<ng-template #msi_score>
    <div class="msi-container white-container">
        <span class="section-titles">MSI Score: 30 - 40</span>
        <img src="../../assets/images/icons/msi-score-icon.png" alt="" />
    </div>
</ng-template>

<ng-template #price_calculator>
    <div class="calculator-container white-container">
        <h6 class="section-titles">Price Calculator</h6>
        <article
            [formGroup]="calculatorForm"
            class="row justify-content-between"
            (ngSubmit)="calculatePrice()"
        >
            <div class="col-auto col-lg-12 calculator-item">
                <span class="calculator-item-title">Price per Unit</span>
                <input
                    formControlName="ppuControl"
                    type="number"
                    (click)="stopPropagation($event)"
                />
            </div>
            <div class="col-auto col-lg-12 calculator-item">
                <span class="calculator-item-title"> Gross Margin: </span>
                <div class="d-flex d-lg-block">
                    <div>
                        <input
                            type="string"
                            value="$"
                            class="sign-input"
                            [disabled]="true"
                            (click)="stopPropagation($event)"
                        />
                        <input
                            formControlName="gmPlusControl"
                            [value]="10"
                            type="number"
                            (click)="stopPropagation($event)"
                        />
                    </div>
                    <div>
                        <input
                            formControlName="gmPercentControl"
                            type="number"
                            (click)="stopPropagation($event)"
                        />
                        <input
                            type="string"
                            value="%"
                            class="sign-input"
                            [disabled]="true"
                            (click)="stopPropagation($event)"
                        />
                    </div>
                </div>
            </div>
            <div class="col-auto col-lg-12 calculator-item">
                <span class="calculator-item-title">
                    Total Cost <br />
                    Per Unit:
                </span>
                <input
                    [formControl]="tcControl"
                    [disabled]="true"
                    #ppu_total_cost_input
                    type="number"
                    (click)="stopPropagation($event)"
                />
                <img
                    class="pointer"
                    src="../../assets/images/icons/copy-icon.svg"
                    alt=""
                    [cdkCopyToClipboard]="ppu_total_cost_input.value"
                    (click)="stopPropagation($event)"
                />
            </div>
        </article>
        <button
            type="submit"
            [disabled]="!calculatorForm.valid"
            class="calculator-total-cost-refresh pointer"
        >
            <img src="../../assets/images/icons/refresh-icon.svg" alt="" />
            Update Components
        </button>
    </div>
</ng-template>

<ng-template #omni_score>
    <div class="row white-container omni-score-container m-0">
        <h6 class="section-titles omni-score-title col-12 col-lg-6">
            Item Omniscoring System
        </h6>
        <ng-container *ngFor="let scoreData of mocScoreList">
            <div class="col-auto col-lg-6 d-flex justify-content-center">
                <ng-template
                    *ngTemplateOutlet="
                        scor_circle;
                        context: { $implicit: scoreData }
                    "
                ></ng-template>
            </div>
        </ng-container>
    </div>
</ng-template>

<ng-template #scor_circle let-scoreData>
    <div class="score-container">
        <div class="score-name">
            {{ scoreData.title }}
        </div>
        <div class="score-spiner">
            <mat-progress-spinner
                [diameter]="84"
                [strokeWidth]="10"
                [color]="scoreBarColor"
                [mode]="scoreBarMode"
                [value]="scoreData.scorePerc"
            >
            </mat-progress-spinner>
            <div class="score-number">
                <span>
                    {{ scoreData.score }}
                </span>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #item_image>
    <div class="img-container">
        <img
            #item_image_img
            id="item_image"
            alt="Item img"
            [src]="thumbnailURL"
            onerror="this.src='./assets/images/image_placeholder.png';"
        />
        <div class="items-position">
            <omni-select
                class="omni-select pointer"
                [placeholder]="'Move'"
                [list]="selectList"
                (onSelectE)="moveItemToProject($event)"
                (click)="stopPropagation($event)"
            />
            <div
                (click)="likeItem()"
                class="like-icon-container d-flex justify-content-center align-items-center pointer"
            >
                <mat-icon
                    class="icon"
                    aria-hidden="false"
                    [fontIcon]="item?.is_liked ? 'favorite' : 'favorite_border'"
                />
            </div>
        </div>
    </div>
</ng-template>

<ng-template #omni_table>
    <article class="table-container">
        <table class="table">
            <tr class="table-header dark-green-row">
                <ng-container *ngFor="let column of displayedColumns">
                    <th>{{ formatString(column) }}</th>
                </ng-container>
            </tr>
            <ng-container *ngFor="let itemType of itemTypes">
                <tr class="darker-row">
                    <td>{{ formatString(itemType) }}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
                <tr
                    *ngFor="
                        let row of getComponentsByType(itemType);
                        index as i
                    "
                    [class.brighter-row]="i % 2 == 0"
                    [class.darker-row]="i % 2 == 1"
                >
                    <td>{{ row.name }}</td>
                    <td>{{ row.quantity }}</td>
                    <td>{{ row.unit_cost | currency }}</td>
                    <td>
                        {{ +row.quantity * row.unit_cost | currency }}
                    </td>
                </tr>

                <tr class="dark-green-row">
                    <th>Total {{ formatString(itemType) }}</th>
                    <th></th>
                    <th></th>
                    <th>
                        {{
                            getTotalCost(getComponentsByType(itemType))
                                | currency
                        }}
                    </th>
                </tr>
            </ng-container>
            <tr class="table-footer">
                <th>Total Cost per Unit</th>
                <th></th>
                <th></th>
                <th>{{ getTotalCostPerUnit() | currency }}</th>
            </tr>
        </table>
    </article>
</ng-template>

<ng-template #scores_and_calculator>
    <article class="main-info-container">
        <div class="main-info-desktop">
            <ng-template [ngTemplateOutlet]="msi_score"></ng-template>
            <ng-template [ngTemplateOutlet]="price_calculator"></ng-template>
            <ng-template [ngTemplateOutlet]="omni_score"></ng-template>
        </div>
        <div class="main-info-mobile">
            <ng-template
                *ngTemplateOutlet="
                    expandable_bar;
                    context: {
                        $implicit: {
                            isOpened: false,
                            titleTemplateId: msi_score,
                            contentTemplateId: omni_score
                        }
                    }
                "
            ></ng-template>
            <ng-template
                *ngTemplateOutlet="
                    expandable_bar;
                    context: {
                        $implicit: {
                            isOpened: false,
                            title: 'Price Calculator',
                            contentTemplateId: price_calculator
                        }
                    }
                "
            ></ng-template>
        </div>
    </article>
</ng-template>
