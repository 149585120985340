<div>
    <div
        id="datepicker-btn"
        class="d-flex align-items-center justify-content-center pointer custom-date-range-field"
        (click)="calendarOpened()"
        [ngClass]="{
            'opened-calendar': isOpen,
            'closed-calendar': !isOpen
        }"
    >
        <mat-datepicker-toggle
            matIconSuffix
            class="calendar-btn"
            [ngClass]="{
                'opened-icon': isOpen,
                'closed-icon': !isOpen
            }"
        ></mat-datepicker-toggle>
        <div
            *ngIf="isDateRangeSelected"
            class="d-flex align-items-center justify-content-center"
            [ngClass]="{
                'menu-opened-badge': isOpen,
                'menu-closed-badge': !isOpen,
                'mr-3': isDateRangeSelected
            }"
        >
            <div
                class="px-2 d-flex"
                [ngClass]="{
                    'badge-text-opened': isOpen,
                    'badge-text-closed': !isOpen
                }"
            >
                <div>
                    {{ dateFormatter(selectedRangeValue?.start) }} -
                    {{ dateFormatter(selectedRangeValue?.end) }}
                </div>
                <mat-icon
                    matSuffix
                    class="pointer ml-1 mat-cross-icon"
                    (click)="clearDateRange()"
                >
                    close
                </mat-icon>
            </div>
        </div>
    </div>
    <div id="calendar-menu">
        <mat-card *ngIf="isOpen" class="calendar-card">
            <div
                class="d-flex justify-content-center align-items-center calendar-header"
            >
                <div>Date Created</div>
            </div>
            <mat-calendar
                id="calendar"
                class="w-calendar"
                [selected]="selectedRangeValue"
                (selectedChange)="selectedChange($event)"
            >
            </mat-calendar>
        </mat-card>
    </div>
</div>
