import { Component, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/state/store'
import { updateUser } from 'src/app/state/store/users/users.actions'

@Component({
    selector: 'user-confirmation-dialog',
    templateUrl: './user.confirmation.dialog.html',
    styleUrls: ['./user.confirmation.dialog.scss'],
})
export class UserConfirmationDialog {
    public isLoading: boolean = false

    constructor(
        private store: Store<AppState>,
        public dialogRef: MatDialogRef<UserConfirmationDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    onNoClick(): void {
        this.dialogRef.close()
    }

    public toggleUserStatus() {
        const payload = {
            is_decommissioned: !this.data?.user.is_decommissioned,
        }
        this.store.dispatch(
            updateUser({
                userId: this.data?.user?.id,
                payload: payload,
            })
        )

        this.isLoading = false
        this.dialogRef.close()
    }
}
