import { createSelector } from '@ngrx/store'
import { usersFeatureKey, UsersState } from './users.reducer'
import { User } from '../../models/users.models'

interface AppState {
    [usersFeatureKey]: UsersState
}

const selectUsersState = (state: AppState) => state[usersFeatureKey]

export const selectAccessToken = createSelector(
    selectUsersState,
    (state: UsersState) => {
        return state.access_token
    }
)

export const selectIsLoading = createSelector(
    selectUsersState,
    (state: UsersState) => {
        return state.is_loading
    }
)

export const selectLoggedInUser = createSelector(
    selectUsersState,
    (state: UsersState) => {
        return state.loggedInUser
    }
)

export const selectIsLoggedIn = createSelector(
    selectUsersState,
    (state: UsersState): boolean => state.is_logged_in
)

export const selectIsAdmin = createSelector(
    selectUsersState,
    (state: UsersState): boolean =>
        state.loggedInUser?.role?.includes('admin') || false
)

export const selectAllUsers = createSelector(
    selectUsersState,
    (state: UsersState): User[] => {
        return state.allUsers
    }
)
