import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core'
import { FormControl } from '@angular/forms'
import { Store } from '@ngrx/store'
import { Subscription } from 'rxjs'
import { FilterOptions } from 'src/app/state/models/filters.models'
import { selectFilterOptions } from 'src/app/state/store/top-attributes/top-attributes.selectors'
import { AppState } from '../../../state/store'

@Component({
    selector: 'item-filter',
    templateUrl: './item-filter.component.html',
    styleUrls: ['./item-filter.component.scss'],
})
export class ItemFilterComponent implements OnInit {
    private subscription = new Subscription()

    public filterOptions: string[] = []
    public filterControl = new FormControl('')

    @ViewChild('filterInput') filterInput!: ElementRef<HTMLInputElement>

    @Output() public onFilterValueSelected: EventEmitter<string[]> =
        new EventEmitter()
    @Input() public selectedFilters: string[] = []

    public get getFilterOptions(): string[] {
        return this.filterOptions.filter((option: string) => {
            const isSelected = this.selectedFilters.includes(option)
            return (
                !isSelected &&
                option
                    .toLowerCase()
                    .trim()
                    .includes(
                        (this.filterControl.value || '').toLowerCase().trim()
                    )
            )
        })
    }

    constructor(private store: Store<AppState>) {}

    public ngOnInit(): void {
        this.subscription.add(
            this.store.select(selectFilterOptions).subscribe(
                (res: FilterOptions) =>
                    (this.filterOptions = Object.values(res)
                        .filter((option) => !!option)
                        .flat())
            )
        )
    }

    public onFilterOptionSelected(selectedOpt: string): void {
        const newFilters: string[] = [...this.selectedFilters, selectedOpt]
        this.onFilterValueSelected.emit(newFilters)
        this.filterInput.nativeElement.value = ''
        this.filterControl.setValue(null)
    }

    public onRemove(selectedOpt: string): void {
        const newFilters: string[] = this.selectedFilters.filter(
            (option: string) => option !== selectedOpt
        )
        this.onFilterValueSelected.emit(newFilters)
    }

    public onReset(): void {
        const newFilters: string[] = []
        this.onFilterValueSelected.emit(newFilters)
    }

    public onEnterPressed(): void {
        const searchQuery: string = (this.filterControl.value || '').trim()
        if (searchQuery) {
            const unSelectedMatched = this.getFilterOptions.find(
                (option) =>
                    option.toLowerCase().trim() ===
                    searchQuery.toLowerCase().trim()
            )
            const selectedMatched = this.selectedFilters.find(
                (option) =>
                    option.toLowerCase() === searchQuery.toLowerCase().trim()
            )
            if (unSelectedMatched)
                this.onFilterOptionSelected(unSelectedMatched)
            else if (!selectedMatched) this.onFilterOptionSelected(searchQuery)
        }
    }
}
