import {
    AfterViewInit,
    Component,
    ElementRef,
    OnDestroy,
    ViewChild,
} from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ThemePalette } from '@angular/material/core'
import { ProgressSpinnerMode } from '@angular/material/progress-spinner'
import { ActivatedRoute, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs'
import { environment } from 'src/environments/environment'
import { formatMLExplainText } from '../shared/helpers/string-formatters'
import { formatString } from '../shared/helpers/table-title-formatter'
import { convertStringToNumber } from '../shared/helpers/type-convertes'
import { ItemComponentI } from '../state/models/components.models'
import { OmniSelectListI } from '../state/models/custom-components.models'
import { Item } from '../state/models/items.models'
import { Project } from '../state/models/projects.models'
import { AppState } from '../state/store'
import {
    clearItem,
    getAllProjects,
    getOneItem,
    likeItem,
    moveItemToProject,
    deleteItem,
} from '../state/store/projects/projects.actions'
import {
    selectActiveItem,
    selectActiveProject,
    selectAllProjects,
} from '../state/store/projects/projects.selectors'
import { MatSidenav } from '@angular/material/sidenav'
import { MatDialog } from '@angular/material/dialog'
import { ConfirmationDialog } from '../shared/dialogs/confirmation.dialog/confirmation.dialog'
import { selectLoggedInUser } from '../state/store/users/users.selectors'
import { SafeHtml } from '@angular/platform-browser'

import { selectGetComments } from '../state/store/comments/comments.selectors'
@Component({
    selector: 'app-item',
    templateUrl: './item.component.html',
    styleUrls: ['./item.component.scss'],
})
export class ItemComponent implements OnDestroy, AfterViewInit {
    @ViewChild('item_image_img') item_image_img!: ElementRef
    @ViewChild('commentsNav') commentsNav!: MatSidenav

    content = ''
    postedContent: any = []

    public drawerOpened = true
    public dataSource: any[] = []
    public totalCost: number = 0
    private subscription = new Subscription()
    public itemId: string = ''
    public item: Item | null = null
    public itemMLExplain: string = ''
    public thumbnailURL: string = ''
    public loggedInUser: any = null
    public sanitizedString: SafeHtml = ''
    public comment: string = ''
    public comments: any[] = []
    public displayedColumns: string[] = [
        'name',
        'quantity',
        'unit_cost',
        'total',
    ]
    public columnsToDisplay: string[] = this.displayedColumns.slice()

    public formatString = formatString

    public mocScoreList: Array<ScoreDataI> = [
        { title: 'Our Score', score: '4.6', scorePerc: 20 * 4.6 },
        { title: 'On Brand', score: '4.4', scorePerc: 20 * 4.4 },
        { title: 'On Trend', score: '4.8', scorePerc: 20 * 4.8 },
        { title: 'On Demo', score: '3.8', scorePerc: 20 * 3.8 },
        { title: 'Reliability', score: '4.0', scorePerc: 20 * 4.0 },
    ]
    public scoreBarColor: ThemePalette = 'primary'
    public scoreBarMode: ProgressSpinnerMode = 'determinate'

    public selectList: Array<OmniSelectListI> = []
    public allProjects$: Array<Project> = []
    public activeProject$: Project | null = null

    public itemTypes: string[] = []

    calculatorForm = new FormGroup({
        ppuControl: new FormControl(0, [
            Validators.required,
            Validators.pattern(/^\d+(\.\d{1,2})?$/),
        ]),
        gmPlusControl: new FormControl(0, [
            Validators.pattern(/^\d+(\.\d{1,2})?$/),
        ]),
        gmPercentControl: new FormControl(0, [
            Validators.pattern(/^\d+(\.\d{1,2})?$/),
        ]),
    })
    tcControl = new FormControl(0, [
        Validators.required,
        Validators.pattern(/^\d+(\.\d{1,2})?$/),
    ])

    constructor(
        private store: Store<AppState>,
        private router: Router,
        private route: ActivatedRoute,
        private dialog: MatDialog
    ) {
        this.store.dispatch(getAllProjects())

        this.subscription.add(
            this.store.select(selectGetComments).subscribe((comments) => {
                this.comments = comments
            })
        )
    }

    public ngOnInit() {
        this.calculatorForm
            .get('gmPlusControl')
            ?.valueChanges.pipe(debounceTime(1000), distinctUntilChanged())
            .subscribe(() => {
                this.calculatorForm
                    .get('gmPercentControl')
                    ?.setValue(0, { emitEvent: false })
                this.calculatePrice()
            })
        this.calculatorForm
            .get('gmPercentControl')
            ?.valueChanges.pipe(debounceTime(1000), distinctUntilChanged())
            .subscribe(() => {
                this.calculatorForm
                    .get('gmPlusControl')
                    ?.setValue(0, { emitEvent: false })
                this.calculatePrice()
            })

        this.subscription.add(
            this.store.select(selectLoggedInUser).subscribe((loggedInUser) => {
                this.loggedInUser = loggedInUser
            })
        )

        this.subscription.add(
            this.store
                .select(selectActiveProject)
                .subscribe((activeProject) => {
                    this.activeProject$ = activeProject
                })
        )
        this.route.params.subscribe((params) => {
            if (params['itemId']) {
                this.itemId = params['itemId']
                this.store.dispatch(getOneItem({ payload: this.itemId }))
            }
        })
        this.subscription.add(
            this.store.select(selectActiveItem).subscribe((activeItem) => {
                this.item = activeItem
                if (this.item?.ml_explain) {
                    this.itemMLExplain = formatMLExplainText(
                        this.item.ml_explain
                    )
                }
                if (this.item?.images) {
                    this.thumbnailURL = `${environment.CDN_URL}/${this.item.images[0].img_hash}-0.png`
                }
                if (this.item?.components) {
                    this.itemTypes = this.getUniqueItemTypes(
                        this.item.components
                    )
                }
            })
        )

        this.subscription.add(
            this.store.select(selectAllProjects).subscribe((allProjects) => {
                this.allProjects$ = allProjects
                this.selectList = this.allProjects$.map((item) => {
                    return { name: item.name, id: item.id }
                })
            })
        )
        this.calculatorForm.setValue(
            {
                ppuControl: 10.01,
                gmPercentControl: 10.01,
                gmPlusControl: 10.01,
            },
            { onlySelf: false, emitEvent: false }
        )
        this.calculatePrice()
    }

    ngAfterViewInit(): void {
        new ResizeObserver(this.resizeExpandableArea).observe(
            this.item_image_img?.nativeElement
        )
    }

    public toggleCommentsDrawer(): void {
        this.commentsNav.toggle()
    }

    public resizeExpandableArea() {
        const imageWidth = document.getElementById('item_image')?.offsetWidth
        let expandableBars = document.getElementById('expandable_bars')
        let table = document.getElementById('table_section')

        if (expandableBars) {
            expandableBars.style.maxWidth = `${imageWidth}px`
        }
        if (table) {
            table.style.maxWidth = `${imageWidth}px`
        }
    }

    public moveItemToProject(project?: OmniSelectListI) {
        if (this.item?.id && project?.id) {
            this.store.dispatch(
                moveItemToProject({
                    payload: {
                        itemId: this.item.id,
                        projectId: project.id,
                    },
                })
            )
        }
    }

    public likeItem() {
        if (this.item?.id) {
            this.store.dispatch(
                likeItem({
                    payload: {
                        itemId: this.item.id,
                        isLiked: !this.item.is_liked,
                    },
                })
            )
        }
    }

    public backToGallery() {
        this.router.navigate(['gallery'])
    }

    getUniqueItemTypes(itemComponents: ItemComponentI[]): string[] {
        let uniqueTypes: string[] = []

        itemComponents.forEach((component) => {
            if (!uniqueTypes.includes(component.type)) {
                uniqueTypes.push(component.type)
            }
        })

        return uniqueTypes
    }

    public stopPropagation(e: Event) {
        e.stopPropagation()
    }

    public getTotalCost(components: ItemComponentI[]) {
        let total = 0
        for (let row of components) {
            const quantity = convertStringToNumber(row.quantity)
            const unitCost = row.unit_cost
            total += quantity * unitCost
        }
        return total
    }

    public convertQuantityToNumber(quantity: string) {
        return convertStringToNumber(quantity)
    }

    public getTotalCostPerUnit() {
        if (this.item?.components) {
            return this.getTotalCost(this.item.components)
        }
        return 0
    }

    public getComponentsByType(type: string): ItemComponentI[] {
        const components = this.item?.components?.filter(
            (component) => component.type === type
        )
        return components || []
    }

    public calculatePrice() {
        let value = this.calculatorForm.value
        if (
            value?.ppuControl &&
            value?.gmPlusControl != undefined &&
            value.gmPercentControl != undefined
        ) {
            this.calculatorForm.setValue(
                {
                    gmPercentControl: this.round(value.gmPercentControl),
                    gmPlusControl: this.round(value.gmPlusControl),
                    ppuControl: this.round(value.ppuControl),
                },
                { emitEvent: false }
            )
            const total =
                (value?.ppuControl + value?.gmPlusControl) *
                (value.gmPercentControl / 100 + 1)
            this.tcControl.setValue(this.round(total))
        }
    }

    public round(value: number): number {
        return +(Math.round(value * 100) / 100).toFixed(2)
    }

    deleteItem() {
        const dialogRef = this.dialog.open(ConfirmationDialog, {
            data: 'Are you sure you want to delete this item?',
        })
        dialogRef.afterClosed().subscribe((result) => {
            if (result && this.item?.id) {
                this.store.dispatch(
                    deleteItem({ payload: { id: this.item.id } })
                )
            }
        })
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe()
        this.store.dispatch(clearItem())
    }
}

interface ScoreDataI {
    title: string
    score: string
    scorePerc: number
}
