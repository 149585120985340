import { Component, OnInit } from '@angular/core'
import { Store, select } from '@ngrx/store'
import { AppState } from './state/store'
import { selectAccessToken } from './state/store/users/users.selectors'
import { getLoggedInUser } from './state/store/users/users.actions'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    constructor(private store: Store<AppState>) {}

    title = 'omniclient'

    ngOnInit() {
        this.store.pipe(select(selectAccessToken)).subscribe((token) => {
            if (token) this.store.dispatch(getLoggedInUser())
        })
    }
}
