import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { Store, select } from '@ngrx/store'
import { AppState } from '../state/store'
import { MatSnackBar } from '@angular/material/snack-bar'
import { resetPasswordRequest } from '../state/store/users/users.actions'
import { Subscription } from 'rxjs'
import { selectIsLoading } from '../state/store/users/users.selectors'

@Component({
    selector: 'forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
    private subscription = new Subscription()
    public isLoading: boolean = false
    constructor(
        private store: Store<AppState>,
        private router: Router,
        private snackbar: MatSnackBar
    ) {}

    public forgotPasswordForm: FormGroup = new FormGroup({
        email: new FormControl('', [Validators.required, Validators.email]),
    })

    public resetPasswordRequest(): void {
        if (!this.forgotPasswordForm.valid) {
            return
        }
        const formParams = this.forgotPasswordForm.getRawValue()
        if (formParams.email) {
            this.store.dispatch(
                resetPasswordRequest({
                    username: formParams.email,
                })
            )
        } else {
            this.snackbar.open(`Please enter email`, 'Ok', {
                duration: 1200,
            })
        }
    }

    public goToLogin(): void {
        this.router.navigate(['/login'])
    }

    ngOnInit(): void {
        this.subscription.add(
            this.store
                .pipe(select(selectIsLoading))
                .subscribe((isLoading) => (this.isLoading = isLoading))
        )
    }
}
