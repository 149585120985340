import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import {
    Comment,
    CommentPayload,
    GetCommentsPayload,
} from '../models/comments.model'

@Injectable()
export class CommentsWebService {
    private webServiceUri: string = `${environment.API_URL}/comments`

    constructor(protected http: HttpClient) {}

    public createComment(payload: CommentPayload): Observable<Comment> {
        const url = this.webServiceUri
        return this.http.post<Comment>(`${url}/create`, payload)
    }

    public getComments(
        payload: GetCommentsPayload
    ): Observable<Array<Comment>> {
        const url = this.webServiceUri
        return this.http.post<Array<Comment>>(url, payload)
    }
}
