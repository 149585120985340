import {
    Component,
    EventEmitter,
    Input,
    Output,
    OnInit,
    ViewChild,
    ElementRef,
} from '@angular/core'
import { Creator } from 'src/app/state/models/creator.models'
import { Subscription } from 'rxjs'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/state/store'
import { selectLoggedInUser } from 'src/app/state/store/users/users.selectors'
import { LoggedInUser } from 'src/app/state/models/users.models'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
import { selectGetComments } from 'src/app/state/store/comments/comments.selectors'
import {
    createComment,
    deleteComment,
    getComments,
    retryComment,
} from 'src/app/state/store/comments/comments.action'
import { ActivatedRoute } from '@angular/router'
import { Comment } from 'src/app/state/models/comments.model'
import { cloneDeep } from 'lodash'

export interface SelectedFilter {
    icon: string
    type: string
    value: string
}

@Component({
    selector: 'comment-bubbles',
    templateUrl: './comment-bubbles.component.html',
    styleUrls: ['./comment-bubbles.component.scss'],
    queries: {
        messageContainer: new ViewChild('messageContainer'),
    },
})
export class CommentBubbles implements OnInit {
    private subscription = new Subscription()
    public loggedInUser: LoggedInUser | null = null

    public showClipboard: any = null
    public sanitizedString: SafeHtml = ''
    public commentInput: string = ''
    public comments: Comment[] = []
    public itemId: string = ''

    messageContainer!: ElementRef

    @Output() filterAction: EventEmitter<any> = new EventEmitter()
    @Input() creator: Creator = {}

    get getComments() {
        const comments = this.comments
        return comments.reverse()
    }

    constructor(
        private store: Store<AppState>,
        private sanitizer: DomSanitizer,
        private route: ActivatedRoute
    ) {
        this.subscription.add(
            this.store.select(selectGetComments).subscribe((comments) => {
                setTimeout(() => this.scrollToBottom(), 200)
                this.comments = cloneDeep(comments)
            })
        )
    }

    public ngOnInit(): void {
        this.route.params.subscribe((params) => {
            if (params['itemId']) {
                this.itemId = params['itemId']
            }
        })
        this.subscription.add(
            this.store.select(selectLoggedInUser).subscribe((loggedInUser) => {
                this.loggedInUser = loggedInUser
            })
        )

        this.store.dispatch(
            getComments({
                payload: {
                    item_id: this.itemId,
                },
            })
        )
    }

    sanitizeMessage(comment: string): SafeHtml {
        const messageWithLineBreaks = comment.replace(/\n/g, '<br/>')
        return this.sanitizer.bypassSecurityTrustHtml(messageWithLineBreaks)
    }

    public deleteComment(comment: Comment) {
        this.store.dispatch(deleteComment({ payload: comment }))
        this.comments = this.comments.filter((item) => item.id !== comment.id)
    }

    public retryComment(comment: Comment) {
        const updatedComment = { ...comment, status: 'pending' }
        this.store.dispatch(retryComment({ payload: updatedComment }))
    }

    public sendComment(): void {
        if (!this.commentInput.trim()) return
        const comment = {
            status: 'pending',
            id: `dummy-${Math.random().toString(36)}`,
            item_id: this.itemId,
            text: this.commentInput,
            time_created: new Date(),
            creator: {
                id: this.loggedInUser?.id,
                username: this.loggedInUser?.username,
                first_name: this.loggedInUser?.first_name,
                last_name: this.loggedInUser?.last_name,
            },
        }

        this.store.dispatch(createComment({ payload: comment }))

        this.commentInput = ''

        setTimeout(() => {
            this.scrollToBottom()
        }, 100)
    }

    public onKeydown(event: KeyboardEvent) {
        const isCmdOrCtrlPressed = event.ctrlKey || event.metaKey
        const isEnterPressed = event.key === 'Enter'

        if (isCmdOrCtrlPressed && isEnterPressed) this.sendComment()
    }

    public getCurrentFormattedTime(date?: any) {
        // Get the current time using Date.now()
        const currentTime = new Date(date || new Date().toISOString())

        // Extract the hours and minutes
        let hours = currentTime.getHours()
        const minutes = currentTime.getMinutes()

        // Determine AM or PM
        const ampm = hours >= 12 ? 'PM' : 'AM'

        // Convert hours to 12-hour format
        hours = hours % 12 || 12 // Converts 0 to 12 for midnight

        // Format the minutes with a leading zero if needed
        const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes

        // Create the final formatted time string
        return `${hours}:${formattedMinutes} ${ampm}`
    }

    private scrollToBottom(): void {
        const container = this.messageContainer.nativeElement
        container.scrollTop = container.scrollHeight
    }
}
