import { createAction, props, union } from '@ngrx/store'

export enum CommentsActions {
    CREATE_COMMENT = '[Comments] Create Comment',
    CREATE_COMMENT_SUCCESS = '[Comments] Create Comment Success',
    CREATE_COMMENT_FAILURE = '[Comments] Create Comment Failure',

    GET_COMMENTS = '[Comments] Get Comments',
    GET_COMMENTS_SUCCESS = '[Comments] Get Comments Success',
    GET_COMMENTS_FAILURE = '[Comments] Get Comments Failure',

    RETRY_COMMENTS = '[Comments] Retry Comments',
    RETRY_COMMENTS_SUCCESS = '[Comments] Retry Comments Success',
    RETRY_COMMENTS_FAILURE = '[Comments] Retry Comments Failure',

    DELETE_COMMENT = '[Comments] Delete Comment',
}

export const createComment = createAction(
    CommentsActions.CREATE_COMMENT,
    props<{ payload: any }>()
)
export const createCommentSuccess = createAction(
    CommentsActions.CREATE_COMMENT_SUCCESS,
    props<{ payload: any }>()
)
export const createCommentFailure = createAction(
    CommentsActions.CREATE_COMMENT_FAILURE,
    props<{ payload: any }>()
)
export const getComments = createAction(
    CommentsActions.GET_COMMENTS,
    props<{ payload: any }>()
)
export const getCommentsSuccess = createAction(
    CommentsActions.GET_COMMENTS_SUCCESS,
    props<{ payload: any }>()
)
export const getCommentsFailure = createAction(
    CommentsActions.GET_COMMENTS_FAILURE
)
export const retryComment = createAction(
    CommentsActions.RETRY_COMMENTS,
    props<{ payload: any }>()
)
export const deleteComment = createAction(
    CommentsActions.DELETE_COMMENT,
    props<{ payload: any }>()
)

const all = union({
    createComment,
    createCommentSuccess,
    createCommentFailure,
    getComments,
    getCommentsSuccess,
    getCommentsFailure,
    retryComment,
})

export type CommentsUnion = typeof all
