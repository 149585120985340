export function formatMLExplainText(input: string) {
    // Convert text between ** ** into bold text
    let boldFormattedText = input.replace(
        /\*\*(.*?)\*\*/g,
        '<strong>$1</strong>'
    )

    // Replace newlines with <br> to respect line breaks
    let htmlFormattedText = boldFormattedText.replace(/\n/g, '<br>')

    return htmlFormattedText
}
