import { FilterOptions, Filters } from './filters.models'

export interface TopAttribute {
    id: string
    name: string
    item_type: string
    department: string
    category: string
    sub_category: string
    trend_type: string
    trend_source: string
}

export interface GetTopAttributesResponse {
    active_filters: Filters
    filters: FilterOptions
    top_attributes: TopAttribute[]
}

export enum TopAttributeEnum {
    name = 'Name',
    item_type = 'Item Type',
    department = 'Department',
    category = 'Category',
    sub_category = 'Sub-Category',
    trend_type = 'Trend Type',
    trend_source = 'Trend Reason',
}
