<button
    *ngIf="!hideBtn"
    id="project-selection-btn"
    mat-raised-button
    color="primary"
    class="projects-button"
    (click)="openProjectSelect()"
>
    <div class="button-text">
        {{ this.activeProject?.name ?? 'Select Project' }}
    </div>
    <mat-icon class="ml-2">keyboard_arrow_down</mat-icon>
</button>
<div *ngIf="isOpened" id="projects-menu" class="projects-menu">
    <input
        type="text"
        placeholder="Search projects..."
        [(ngModel)]="searchTerm"
        class="w-100 p-10"
    />
    <div class="projects-container">
        <div
            *ngFor="let project of filteredProjects"
            class="projects-list"
            [class.project-selected]="activeProject?.id === project.id"
            (click)="selectProject(project)"
        >
            <div class="d-flex justify-content-center align-items-center">
                <mat-icon class="mt-2 public-private-icon">
                    {{ project?.description === 'public' ? 'public' : 'lock' }}
                </mat-icon>

                <div class="ml-2">{{ project.name }}</div>
            </div>
            <mat-icon
                *ngIf="activeProject?.id === project?.id"
                class="mt-2 check-icon"
                >check</mat-icon
            >
        </div>
        <div
            *ngIf="filteredProjects.length === 0"
            class="d-flex justify-content-center align-items-center w-100 h-100 no-result-found"
        >
            <div>No results found.</div>
        </div>
    </div>
</div>
