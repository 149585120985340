import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { Store, select } from '@ngrx/store'
import { Subscription } from 'rxjs'
import { Project } from 'src/app/state/models/projects.models'
import { AppState } from 'src/app/state/store'
import {
    getAllProjects,
    getOneProject,
    setActiveProject,
} from 'src/app/state/store/projects/projects.actions'
import {
    selectActiveProject,
    selectAllProjects,
} from 'src/app/state/store/projects/projects.selectors'

@Component({
    selector: 'project-selection-dropdown',
    templateUrl: './project-selection-dropdown.component.html',
    styleUrls: ['./project-selection-dropdown.component.scss'],
})
export class ProjectSelectionDropdown
    implements OnInit, AfterViewInit, OnDestroy
{
    private subscription = new Subscription()
    public activeProject: Project | null = null
    public projectsList: any = []
    public isOpened: boolean = false
    public searchTerm: string = ''
    public hideBtn: boolean = false

    get filteredProjects(): any[] {
        return this.projectsList.filter((project: any) =>
            project.name.toLowerCase().includes(this.searchTerm.toLowerCase())
        )
    }

    constructor(
        private store: Store<AppState>,
        private route: ActivatedRoute
    ) {
        this.store.dispatch(getAllProjects())
    }

    public openProjectSelect(): void {
        this.isOpened = !this.isOpened
    }

    public selectProject(project: any): void {
        this.store.dispatch(
            getOneProject({
                payload: { id: project.id, filters: { limit: 10 } },
            })
        )
        this.store.dispatch(setActiveProject({ payload: project }))
        this.isOpened = false
        this.searchTerm = ''
    }

    ngOnInit(): void {
        this.subscription.add(
            this.store
                .pipe(select(selectAllProjects))
                .subscribe((allProjects) => {
                    this.projectsList = allProjects
                })
        )
        this.subscription.add(
            this.store.pipe(select(selectActiveProject)).subscribe((item) => {
                if (item) {
                    this.activeProject = item
                } else {
                    this.store.dispatch(getAllProjects())
                }
            })
        )

        this.route.data.subscribe(
            (data: any) => (this.hideBtn = data?.hideComponent)
        )
    }

    ngAfterViewInit() {
        // Added this to customize the click handling of filter menu
        // Need to close filter menu if clicked outside of the filter menu and options
        document.addEventListener('click', (e) => {
            const projectsListCard = document.getElementById('projects-menu')
            const projectSelectionButton = document.getElementById(
                'project-selection-btn'
            )
            if (!projectsListCard || !projectSelectionButton) return

            if (
                !projectsListCard.contains(e.target as Node) &&
                !projectSelectionButton.contains(e.target as Node)
            )
                this.isOpened = false
        })
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe()
    }
}
