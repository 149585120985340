import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http'
import { Injectable, OnDestroy } from '@angular/core'
import { Store, select } from '@ngrx/store'
import { Observable, Subscription, filter } from 'rxjs'
import { AppState } from '../store'
import { selectAccessToken } from '../store/users/users.selectors'
import { LocalStorage } from '../local-storage'

@Injectable()
export class AuthInterceptor implements HttpInterceptor, OnDestroy {
    private subscription = new Subscription()
    private access_token: string | undefined =
        LocalStorage.getUsersState()?.access_token
    constructor(private store: Store<AppState>) {
        this.subscription.add(
            this.store
                .pipe(
                    select(selectAccessToken),
                    filter((res) => !!res)
                )
                .subscribe((res) => {
                    this.access_token = res
                })
        )
    }
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const url = new URL(req.url)
        const isTokenEndpoint = url.pathname === '/api/v1/token'

        req = req.clone({
            setHeaders: {
                'Content-Type': !isTokenEndpoint
                    ? 'application/json; charset=utf-8'
                    : 'application/x-www-form-urlencoded',
                Accept: 'application/json',
                Authorization: `Bearer ${this.access_token}`,
            },
        })

        return next.handle(req)
    }
    ngOnDestroy(): void {
        this.subscription.unsubscribe()
    }
}
