import { createAction, props, union } from '@ngrx/store'
import {
    CreateUserPayload,
    LoggedInUser,
    LoginRequestParams,
    LoginResponseI,
    UpdateUserPayload,
    User,
} from '../../models/users.models'

export enum UsersActions {
    LOGIN = '[Login] Login',
    LOGIN_SUCCESS = '[Login API] Login Success',
    LOGIN_FAILURE = '[Login API] Login Failure',
    LOGOUT = '[Login API] Logout',
    GET_LOGGED_IN_USER = '[USER api] Get Logged-in User',
    GET_LOGGED_IN_USER_SUCCESS = '[USER api] Get Logged-in User Success',
    GET_LOGGED_IN_USER_FAILURE = '[USER api] Get Logged-in User Failure',
    GET_ALL_USERS = '[USERS api] Get All Users',
    GET_ALL_USERS_SUCCESS = '[USERS api] Get All Users Success',
    GET_ALL_USERS_FAILURE = '[USERS api] Get All Users Failure',
    CREATE_USER = '[USERS api] Create User',
    CREATE_USER_SUCCESS = '[USERS api] Create User Success',
    CREATE_USER_FAILURE = '[USERS api] Create User Failure',
    UPDATE_USER = '[USERS api] Update User',
    UPDATE_USER_SUCCESS = '[USERS api] Update User Success',
    UPDATE_USER_FAILURE = '[USERS api] Update User Failure',
    RESET_PASSWORD = '[USERS api] Reset Password',
    RESET_PASSWORD_SUCCESS = '[USERS api] Reset Password Success',
    RESET_PASSWORD_FAILURE = '[USERS api] Reset Password Failure',
    RESET_PASSWORD_REQUEST = '[USERS api] Reset Password Request',
    RESET_PASSWORD_REQUEST_SUCCESS = '[USERS api] Reset Password Request Success',
    RESET_PASSWORD_REQUEST_FAILURE = '[USERS api] Reset Password Request Failure',
}

export const login = createAction(
    UsersActions.LOGIN,
    props<{ payload: LoginRequestParams }>()
)

export const loginSuccess = createAction(
    UsersActions.LOGIN_SUCCESS,
    props<{ payload: LoginResponseI }>()
)

export const loginFailure = createAction(UsersActions.LOGIN_FAILURE)

export const getLoggedInUser = createAction(UsersActions.GET_LOGGED_IN_USER)

export const getLoggedInUserSuccess = createAction(
    UsersActions.GET_LOGGED_IN_USER_SUCCESS,
    props<LoggedInUser>()
)

export const getLoggedInUserFailure = createAction(
    UsersActions.GET_LOGGED_IN_USER_FAILURE
)

export const logout = createAction(UsersActions.LOGOUT)

export const getAllUsers = createAction(UsersActions.GET_ALL_USERS)

export const getAllUsersSuccess = createAction(
    UsersActions.GET_ALL_USERS_SUCCESS,
    props<{ payload: Array<User> }>()
)

export const getAllUsersFailure = createAction(
    UsersActions.GET_ALL_USERS_FAILURE
)

export const createUser = createAction(
    UsersActions.CREATE_USER,
    props<{ payload: CreateUserPayload }>()
)

export const createUserSuccess = createAction(
    UsersActions.CREATE_USER_SUCCESS,
    props<{ payload: User }>()
)

export const createUserFailure = createAction(UsersActions.CREATE_USER_FAILURE)

export const updateUser = createAction(
    UsersActions.UPDATE_USER,
    props<{ userId: any; payload: UpdateUserPayload }>()
)

export const updateUserSuccess = createAction(
    UsersActions.UPDATE_USER_SUCCESS,
    props<{ userId: any; payload: User }>()
)

export const updateUserFailure = createAction(UsersActions.UPDATE_USER_FAILURE)

export const resetPassword = createAction(
    UsersActions.RESET_PASSWORD,
    props<{ payload: { token: string; new_password: string } }>()
)

export const resetPasswordSuccess = createAction(
    UsersActions.RESET_PASSWORD_SUCCESS,
    props<{ payload: any }>()
)

export const resetPasswordFailure = createAction(
    UsersActions.RESET_PASSWORD_FAILURE,
    props<{ error: any }>()
)

export const resetPasswordRequest = createAction(
    UsersActions.RESET_PASSWORD_REQUEST,
    props<{ username: string }>()
)

export const resetPasswordRequestSuccess = createAction(
    UsersActions.RESET_PASSWORD_REQUEST_SUCCESS
)

export const resetPasswordRequestFailure = createAction(
    UsersActions.RESET_PASSWORD_REQUEST_FAILURE,
    props<{ error: any }>()
)

const all = union({
    login,
    loginSuccess,
    loginFailure,
    getAllUsers,
    getAllUsersFailure,
    createUser,
    createUserSuccess,
    createUserFailure,
    updateUser,
    updateUserSuccess,
    updateUserFailure,
    resetPassword,
    resetPasswordSuccess,
    resetPasswordFailure,
    resetPasswordRequest,
    resetPasswordRequestSuccess,
    resetPasswordRequestFailure,
})

export type UsersUnion = typeof all
