import {
    Component,
    EventEmitter,
    Input,
    Output,
    ChangeDetectorRef,
} from '@angular/core'
import {
    FilterEnum,
    FilterOptions,
    getFilterIcon,
} from '../../../state/models/filters.models'

export interface SelectedFilter {
    icon: string
    type: string
    value: string
}

@Component({
    selector: 'filter-chips-row',
    templateUrl: './filter-chips-row.component.html',
    styleUrls: ['./filter-chips-row.component.scss'],
})
export class FilterChipsRow {
    @Output() filterAction: EventEmitter<any> = new EventEmitter()
    @Input() activeFilters: FilterOptions = {}

    public get selectedFilters(): Array<SelectedFilter> {
        let rearrangedData: Array<SelectedFilter> = []
        Object.keys(FilterEnum).forEach((key) => {
            const currentTypeFilters: string[] =
                this.activeFilters[key as keyof FilterOptions] || []
            if (currentTypeFilters.length) {
                const transformedFilter: Array<SelectedFilter> =
                    currentTypeFilters.map((item: string) => ({
                        type: key,
                        value: item,
                        icon: getFilterIcon(key),
                    }))
                rearrangedData = rearrangedData.concat(transformedFilter)
            }
        })
        return rearrangedData
    }

    constructor(private cdr: ChangeDetectorRef) {}

    removeSelectedOption({ type, value }: any) {
        this.cdr.detectChanges()
        const updatedFilters = {
            ...this.activeFilters,
            [type]: (
                this.activeFilters[type as keyof FilterOptions] || []
            ).filter((item: any) => item !== value),
        }

        this.filterAction.emit(updatedFilters)
    }
}
