<div
    id="filter-menu-btn"
    class="d-flex align-items-center justify-content-center br-3 pointer"
    [ngClass]="{
        'w-n-filters': getSelectedFilterCount === 0,
        'w-filters': getSelectedFilterCount > 0,
        'filter-icon-opened': isOpen,
        'filter-icon-closed': '!isOpen'
    }"
    (click)="isOpen = !isOpen"
>
    <img
        [src]="
            isOpen
                ? '../../assets/images/icons/filter-icon-white.svg'
                : '../../assets/images/icons/filter-icon.svg'
        "
        alt=""
    />
    <div
        *ngIf="getSelectedFilterCount > 0"
        class="ml-2 d-flex align-items-center justify-content-center"
        [ngClass]="{
            'menu-opened-badge': isOpen,
            'menu-closed-badge': !isOpen
        }"
    >
        <div
            [ngClass]="{
                'badge-text-opened': isOpen,
                'badge-text-closed': !isOpen
            }"
        >
            {{ getSelectedFilterCount }}
        </div>
    </div>
</div>
<div *ngIf="isOpen" id="filter-menu" class="filter-menu">
    <div style="font-weight: 500; font-size: 20px" class="px-2 pb-3">
        Filters
    </div>
    <div class="d-flex align-items-center flex-wrap">
        <filter
            *ngFor="let filterType of filterTypes"
            [filterType]="filterType"
            [activeFilters]="activeFilters"
            [allFilters]="getFiltersOptions(filterType)"
            (filterAction)="mergeAndApplyFilters($event)"
        ></filter>
    </div>
</div>
