import { Component, OnInit } from '@angular/core'
import { Store } from '@ngrx/store'
import { ActivatedRoute, Router } from '@angular/router'
import { Subscription } from 'rxjs'

import { AppState } from 'src/app/state/store'
import { selectIsAdmin } from 'src/app/state/store/users/users.selectors'

@Component({
    selector: 'sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
    private subscription = new Subscription()
    public isSidebarOpen: boolean = false
    public isAdmin: boolean = false
    public currentPage: string = ''

    constructor(
        private store: Store<AppState>,
        private router: Router,
        private route: ActivatedRoute
    ) {
        if (this.route.snapshot.routeConfig?.path) {
            this.currentPage = this.route.snapshot.routeConfig?.path
        }
    }

    ngOnInit(): void {
        this.subscription.add(
            this.store.select(selectIsAdmin).subscribe((isAdmin) => {
                this.isAdmin = isAdmin
            })
        )
    }

    public goTo(nav: string) {
        this.router.navigate([nav])
    }
}
