<div class="mt-2">
    <mat-icon
        *ngIf="!isCopied"
        matTooltip="Copy to clipboard"
        #tooltip="matTooltip"
        [style.font-size]="size"
        (click)="copyContent($event)"
        >content_copy</mat-icon
    >

    <mat-icon
        *ngIf="isCopied"
        matTooltip="Copied!"
        #tooltip="matTooltip"
        [style.font-size]="size"
        >check</mat-icon
    >
</div>
