<div
    id="creator-selection-btn"
    class="user-menu pointer"
    (click)="openProjectSelect()"
>
    <div class="fs-13 ml-2">
        {{
            selectedUsers.length > 0
                ? selectedUsers[0].first_name && selectedUsers[0].last_name
                    ? selectedUsers[0].first_name +
                      ' ' +
                      selectedUsers[0].last_name
                    : selectedUsers[0].username
                : 'Filter by creator'
        }}
    </div>

    <div
        *ngIf="selectedUsers.length > 1"
        class="ml-2 d-flex align-items-center justify-content-center mr-2 pointer menu-closed-badge"
        #tooltip="matTooltip"
        [matTooltip]="moreUsers"
    >
        <div class="badge-text-closed">+{{ selectedUsers.length - 1 }}</div>
    </div>

    <mat-icon matSuffix class="ml-2 menu-icon"
        >{{ isOpened ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
    </mat-icon>
</div>
<div *ngIf="isOpened" id="creator-menu" class="creator-menu">
    <input
        type="text"
        placeholder="Search name..."
        [(ngModel)]="searchTerm"
        class="w-100 p-10"
    />
    <div class="users-container">
        <div
            *ngFor="let creator of filteredCreators"
            class="users-list"
            [class.user-selected]="getSelectedUsers(creator)"
            (click)="selectCreator(creator)"
        >
            {{ userFullName(creator) }}
            <mat-icon *ngIf="getSelectedUsers(creator)">check</mat-icon>
        </div>
        <div
            *ngIf="filteredCreators.length === 0"
            class="d-flex justify-content-center align-items-center w-100 h-100 no-result-found"
        >
            <div>No results found.</div>
        </div>
    </div>
</div>
