<header>
    <div class="fs-18">{{data.type}} User</div>
    <mat-icon
        fontIcon="close"
        class="pointer"
        [mat-dialog-close]="false"
    ></mat-icon>
</header>
<section>
    <h6>Are you sure you want to {{data?.type?.toLowerCase()}} this user?</h6>
    <div class="d-flex justify-content-end">
        <button mat-raised-button class="cancel" [mat-dialog-close]="false">
            Cancel
        </button>

        <button
            mat-raised-button
            color="primary"
            class="submit ml-3"
            type="submit"
            (click)="toggleUserStatus()"
        >
            <div class="button-content">
                Submit
                <mat-spinner
                    *ngIf="isLoading"
                    diameter="18"
                    class="spinner ml-1"
                ></mat-spinner>
            </div>
        </button>
    </div>
</section>
