import { animate, animation, style } from '@angular/animations'

export const fadeIn = animation([
    style({ opacity: 0, display: 'none' }),
    animate('{{time}}', style({ opacity: 1 })),
])

export const fadeOut = animation([
    style({ opacity: 1 }),
    animate('{{time}}', style({ opacity: 0 })),
])
