import { Action, createReducer, on } from '@ngrx/store'
import { LocalStorage } from '../../local-storage'
import { FilterOptions, Filters } from '../../models/filters.models'
import { Item } from '../../models/items.models'
import { Project } from '../../models/projects.models'
import * as ProjectsActions from './projects.actions'

export const projectsFeatureKey = 'projectsState'

export interface ProjectsState {
    allProjects: Array<Project>
    activeProject: Project | null
    activeItem: Item | null
    allFilters: FilterOptions | undefined
    oldActiveFilters: Filters
    activeFilters: Filters
    operationSuccess: Boolean
    skeletonLoaders: any
}

export const initialState: ProjectsState = {
    allProjects: [],
    activeProject: null,
    activeItem: null,
    allFilters: {
        item_type: [],
        department: [],
        category: [],
        sub_category: [],
        trend_type: [],
    },
    oldActiveFilters: {},
    activeFilters: {},
    skeletonLoaders: {},

    // A generic state var for success of any POST call with no response
    // Use this if you don't want to set any data in state but only want to get success event on api response
    operationSuccess: false,
}

const projectsReducer = createReducer(
    initialState,
    on(ProjectsActions.getAllProjects, (state) => ({ ...state })),
    on(ProjectsActions.getAllProjectsSuccess, (state, action) => ({
        ...state,
        allProjects: action.payload,
    })),
    on(ProjectsActions.getAllProjectsFailure, (state, action) => ({
        ...state,
        error: action.error,
    })),

    on(ProjectsActions.getOneProject, (state, action) => ({
        ...state,
        activeFilters: action.payload.filters,
    })),
    on(ProjectsActions.getOneProjectSuccess, (state, action) => ({
        ...state,
        activeProject: action.payload,
        allFilters: action.payload.filters,
        oldActiveFilters: state.activeFilters,
        activeFilters: action.filters,
    })),
    on(ProjectsActions.getOneProjectFailure, (state) => ({
        ...state,
        activeFilters: state.oldActiveFilters,
    })),

    on(ProjectsActions.createProject, (state) => ({ ...state })),
    on(ProjectsActions.createProjectSuccess, (state, action) => ({
        ...state,
        activeProject: action.payload,
    })),
    on(ProjectsActions.createProjectFailure, (state, action) => ({
        ...state,
        error: action.error,
    })),

    on(ProjectsActions.generateProjectItems, (state) => ({
        ...state,
        operationSuccess: false,
    })),
    on(ProjectsActions.generateProjectItemsSuccess, (state) => ({
        ...state,
        operationSuccess: true,
    })),
    on(ProjectsActions.generateProjectItemsFailure, (state) => ({
        ...state,
        operationSuccess: false,
    })),

    on(ProjectsActions.generateAdvancedItems, (state) => ({
        ...state,
    })),
    on(ProjectsActions.generateAdvancedItemsSuccess, (state) => ({
        ...state,
    })),
    on(ProjectsActions.generateAdvancedItemsFailure, (state) => ({
        ...state,
    })),

    on(ProjectsActions.setActiveProject, (state, action) => {
        const newState = {
            ...state,
            activeProject: action.payload,
            oldActiveFilters: {},
            activeFilters: action.filters ? action.filters : {},
        }

        LocalStorage.setProjectsState(newState)

        return newState
    }),

    on(ProjectsActions.getOneItem, (state) => ({ ...state })),
    on(ProjectsActions.getOneItemSuccess, (state, action) => ({
        ...state,
        activeItem: action.payload,
    })),
    on(ProjectsActions.getOneItemFailure, (state) => ({ ...state })),
    on(ProjectsActions.clearItem, (state) => {
        return {
            ...state,
            activeItem: null,
        }
    }),

    on(ProjectsActions.getOneItemAndAddToAll, (state) => ({
        ...state,
    })),
    on(ProjectsActions.getOneItemAndAddToAllSuccess, (state, action) => {
        if (state.activeProject?.items) {
            return {
                ...state,
                activeProject: {
                    ...state.activeProject,
                    items: [action.payload, ...state.activeProject?.items],
                },
            }
        }
        return { ...state }
    }),
    on(ProjectsActions.getOneItemAndAddToAllFailure, (state) => ({
        ...state,
    })),

    on(ProjectsActions.likeItem, (state) => ({ ...state })),
    on(ProjectsActions.likeItemSuccess, (state, { itemId }) => {
        if (state?.activeProject?.items) {
            const allItems = state?.activeProject?.items.map((item_old) => {
                let item = { ...item_old }
                if (item.id === itemId) {
                    item.is_liked = !item.is_liked
                }
                return item
            })

            const newActiveProject = {
                ...state.activeProject,
                items: allItems,
            }

            return {
                ...state,
                activeProject: newActiveProject,
                activeItem: {
                    ...state.activeItem,
                    is_liked: !state.activeItem?.is_liked,
                    id: state.activeItem?.id!,
                    name: state.activeItem?.name!,
                    description: state.activeItem?.description!,
                    image_prompt: state.activeItem?.image_prompt!,
                    ml_explain: state.activeItem?.ml_explain!,
                    images: state.activeItem?.images!,
                    components: state.activeItem?.components!,
                },
            }
        }
        return { ...state }
    }),
    on(ProjectsActions.likeItemFailure, (state) => ({ ...state })),

    on(ProjectsActions.moveItemToProjectSuccess, (state, payload) => {
        if (state?.activeProject?.items) {
            const allItems = state.activeProject.items.filter(
                (item) => item.id !== payload.payload
            )
            const activeProject = {
                ...state.activeProject,
                items: allItems,
            }
            return {
                ...state,
                activeProject: activeProject,
            }
        }
        return { ...state }
    }),

    on(ProjectsActions.removeSkeletonLoaderForItem, (state, action) => {
        const skeletonObj = { ...state.skeletonLoaders }
        if (skeletonObj[action.payload])
            skeletonObj[action.payload] = skeletonObj[action.payload].slice(
                0,
                -1
            )

        return {
            ...state,
            skeletonLoaders: skeletonObj,
        }
    }),

    on(ProjectsActions.addskeletonLoaders, (state, action) => {
        const skeletonObj = { ...state.skeletonLoaders }
        const newLoaders = new Array(action.n_of_items).fill({
            id: crypto.randomUUID(),
            type: 'loader',
            timeStamp: Date.now(),
        })
        if (skeletonObj[action.project_id])
            skeletonObj[action.project_id] = [
                ...newLoaders,
                ...skeletonObj[action.project_id],
            ]
        else skeletonObj[action.project_id] = newLoaders
        return {
            ...state,
            skeletonLoaders: skeletonObj,
        }
    })
)

export function reducer(
    state: ProjectsState | undefined,
    action: Action
): ProjectsState {
    return projectsReducer(state, action)
}
