import { Injectable } from '@angular/core'
import { Store } from '@ngrx/store'
import { Subject } from 'rxjs'
import { environment } from 'src/environments/environment'
import { LocalStorage } from '../local-storage'
import { AppState } from '../store'

@Injectable({
    providedIn: 'root',
})
export class WebSocketService {
    private socket: WebSocket | undefined
    private webServiceUri: string = environment.WSS
    private accessToken: string | undefined = ''
    public isConnectionOpened: boolean = false
    private messageSubject = new Subject<any>()
    public messages$ = this.messageSubject.asObservable()

    constructor(private store: Store<AppState>) {
        this.accessToken = LocalStorage.getUsersState()?.access_token
    }

    public connect(): void {
        if (this.accessToken) {
            this.socket = new WebSocket(
                this.webServiceUri + `/${this.accessToken}`
            )

            this.socket.onopen = (event) => {
                console.log('Connection opened', event)
                this.isConnectionOpened = true
            }

            this.socket.onmessage = (event) => {
                console.log('event here', event)
                console.log('Message received from the server: ', event.data)
            }

            this.socket.onclose = (event) => {
                console.log('Connection closed', event)
                this.isConnectionOpened = false
            }

            this.socket.onerror = (event) => {
                console.error('WebSocket error: ', event)
                this.isConnectionOpened = false
            }
        }
    }
}
