<navbar></navbar>
<div class="page-container h-full">
    <sidebar></sidebar>
    <div class="mt-55 w-100 d-flex justify-content-between">
        <div class="pl-80 mt-25 w-70">
            <div class="w-100 d-flex flex-wrap align-items-center">
                <div>
                    <mat-form-field
                        class="filter-table mr-3 search-filter d-flex no-hint"
                    >
                        <mat-label class="fs-14">Search</mat-label>
                        <mat-icon matPrefix>search</mat-icon>
                        <input
                            matInput
                            (keyup)="applyFilter($event)"
                            placeholder="Ex. Mia"
                            #input
                        />
                    </mat-form-field>
                </div>
                <filter-menu
                    [activeFilters]="activeFilters"
                    [filterOptions]="filterOptions"
                    [getSelectedFilterCount]="getSelectedFilterCount"
                    (filterAction)="mergeAndApplyFilters($event)"
                >
                </filter-menu>
                <div class="ml-3">
                    <date-picker
                        [isDateRangeSelected]="isDateRangeSelected"
                        (selectedDateRange)="onDateRangeSelected($event)"
                    >
                    </date-picker>
                </div>
                <div
                    *ngIf="getSelectedFilterCount > 0 || isDateRangeSelected"
                    class="mx-3"
                >
                    <reset-filters
                        (resetFilters)="resetFilters()"
                    ></reset-filters>
                </div>
            </div>
            <filter-chips-row
                [activeFilters]="activeFilters"
                (filterAction)="updateTopAttributes($event)"
            ></filter-chips-row>
            <div class="overflow-auto mxh-550">
                <table
                    mat-table
                    [dataSource]="dataSource"
                    id="top-attributes-mat-table"
                >
                    <ng-container
                        *ngFor="let column of displayedColumns; let i = index"
                        [matColumnDef]="column"
                    >
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            class="bg-header"
                            [class.mnw-200]="column !== 'select'"
                            [class.sticky-column]="i === 0"
                        >
                            <mat-checkbox
                                *ngIf="column === 'select'"
                                (change)="toggleTopAttributes($event)"
                            ></mat-checkbox>
                            {{ columnPlaceholder(column) }}
                        </th>
                        <td
                            mat-cell
                            *matCellDef="let element"
                            [class.sticky-column]="i === 0"
                        >
                            <mat-checkbox
                                *ngIf="column === 'select'"
                                [checked]="allSelected"
                                (change)="selectTopAttribute($event, element)"
                            ></mat-checkbox>
                            {{ element[column] }}
                        </td>
                    </ng-container>
                    <tr
                        mat-header-row
                        *matHeaderRowDef="displayedColumns"
                        class="custom-mat-header-row"
                        sticky
                    ></tr>
                    <tr
                        mat-row
                        *matRowDef="let row; columns: displayedColumns"
                        class="custom-mat-row"
                    ></tr>
                </table>
            </div>
            <mat-paginator
                [pageSizeOptions]="[50, 100, 250]"
                aria-label="Select page of top-attributes"
                class="sticky-paginator"
            ></mat-paginator>
        </div>
        <item-generation class="position-relative w-30"></item-generation>
    </div>
</div>
