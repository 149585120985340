import { transition, trigger, useAnimation } from '@angular/animations'
import { DialogRef } from '@angular/cdk/dialog'
import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { Store, select } from '@ngrx/store'
import { Subscription, combineLatest, filter } from 'rxjs'
import { Project } from 'src/app/state/models/projects.models'
import { AppState } from 'src/app/state/store'
import {
    createProject,
    getAllProjects,
    getOneProject,
    setActiveProject,
    deleteProject,
} from 'src/app/state/store/projects/projects.actions'
import {
    selectActiveProject,
    selectAllProjects,
} from 'src/app/state/store/projects/projects.selectors'
import { fadeIn, fadeOut } from '../../helpers/animations'
import { projectValidator } from '../../helpers/validators'
import { ConfirmationDialog } from '../../dialogs/confirmation.dialog/confirmation.dialog'

@Component({
    selector: 'project-selection',
    templateUrl: './project-selection.component.html',
    styleUrls: ['./project-selection.component.scss'],
    animations: [
        trigger('appear', [
            transition('void => *', [
                useAnimation(fadeIn, { params: { time: '400ms' } }),
            ]),
        ]),
        trigger('leave', [
            transition('* => void', [
                useAnimation(fadeOut, { params: { time: '400ms' } }),
            ]),
        ]),
    ],
})
export class ProjectSelectionComponent implements OnInit, OnDestroy {
    private subscription = new Subscription()

    newProject = false
    projectList$: Project[] = []
    activeProject$: Project | null = null

    newProjectControl = new FormControl('', [
        projectValidator(this.projectList$),
    ])
    activeProjectControl = new FormControl('')
    private selectedProject: Project | null = null

    get getPlaceholder(): string {
        if (this.data) return this.data
        else return 'Project'
    }

    constructor(
        private store: Store<AppState>,
        private router: Router,
        private dialog: MatDialog,
        @Optional() private dialogRef: DialogRef,
        @Optional() @Inject(MAT_DIALOG_DATA) public data: string
    ) {}

    onCreateClick(): void {
        this.newProject = true
    }

    selectProject(activeProject: Project): void {
        this.selectedProject = activeProject
        this.activeProjectControl.setValue(activeProject.name)
    }

    isDialog(): boolean {
        return this.dialogRef != null
    }

    onSaveContinue(): void {
        const newProjectName = this.newProjectControl.getRawValue()

        if (newProjectName && this.newProjectControl.valid) {
            this.store.dispatch(
                createProject({
                    payload: {
                        name: newProjectName,
                    },
                })
            )

            if (this.isDialog()) {
                this.dialogRef.close()
            } else {
                this.router.navigate(['/top-attributes'])
            }
        }
    }

    openProject(): void {
        const activeProject = this.selectedProject || this.activeProject$
        if (activeProject) {
            this.store.dispatch(
                getOneProject({
                    payload: { id: activeProject.id, filters: { limit: 10 } },
                })
            )
            this.store.dispatch(setActiveProject({ payload: activeProject }))
            if (this.dialogRef != null) {
                this.dialogRef.close()
            } else {
                const queryParams = { projectId: activeProject.id }
                this.router.navigate(['/top-attributes'], {
                    queryParams: queryParams,
                })
            }
        }
    }

    deleteProject() {
        const dialogRef = this.dialog.open(ConfirmationDialog, {
            data: 'Are you sure you want to delete this project?',
        })
        dialogRef.afterClosed().subscribe((result) => {
            if (result && this.activeProject$?.id) {
                this.dialog.closeAll()
                this.store.dispatch(
                    deleteProject({ payload: this.activeProject$?.id })
                )
                this.activeProjectControl.setValue('')
            }
        })
    }

    ngOnInit(): void {
        this.store.dispatch(getAllProjects())

        this.subscription.add(
            combineLatest([
                this.store.pipe(select(selectAllProjects)),
                this.store.pipe(select(selectActiveProject)),
            ]).subscribe(([allProjects, activeProject]) => {
                this.projectList$ = allProjects

                this.activeProject$ = activeProject ?? allProjects[0] ?? null

                this.activeProjectControl.setValue(
                    this.activeProject$ ? this.activeProject$.name : ''
                )
            })
        )
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe()
    }
}
