import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { Store } from '@ngrx/store'
import { of } from 'rxjs'
import { catchError, exhaustMap, map } from 'rxjs/operators'
import { AppState } from '..'
import { GetTopAttributesResponse } from '../../models/top-attributes.models'
import { TopAttributesWebService } from '../../services/top-attributes-web.service'
import {
    TopAttributesActions,
    TopAttributesUnion,
    getTopAttributesFailure,
    getTopAttributesSuccess,
} from './top-attributes.actions'
import { UsersWebService } from '../../services/users-web.service'

@Injectable()
export class TopAttributesEffects {
    public getTopAttributes$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(TopAttributesActions.GET_TOP_ATTRIBUTES),
            exhaustMap(({ payload }) => {
                return this.topAttributesWebService
                    .getTopAttributes(payload)
                    .pipe(
                        map((res) => {
                            const getTopAttributesResponse: GetTopAttributesResponse =
                                {
                                    active_filters: payload,
                                    filters: res.filters,
                                    top_attributes: res.top_attributes,
                                }

                            return getTopAttributesSuccess({
                                payload: getTopAttributesResponse,
                            })
                        }),
                        catchError((err) => {
                            this.usersWebService.catchError(err)
                            return of(getTopAttributesFailure())
                        })
                    )
            })
        )
    })

    constructor(
        private actions$: Actions<TopAttributesUnion>,
        private topAttributesWebService: TopAttributesWebService,
        private usersWebService: UsersWebService,
        private store: Store<AppState>
    ) {}
}
