import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { LocalStorage } from '../local-storage'
import { LoginRequestParams } from '../models/users.models'

@Injectable()
export class UsersWebService {
    private webServiceUri: string = environment.API_URL

    constructor(
        protected http: HttpClient,
        private router: Router
    ) {}

    public login(param: LoginRequestParams): Observable<any> {
        const url = this.webServiceUri + '/token'
        const body = new URLSearchParams()
        body.set('username', param.username)
        body.set('password', param.password)
        return this.http.post(url, body.toString())
    }

    public getLoggedInUser(): Observable<any> {
        const url = `${this.webServiceUri}/user`
        return this.http.get(url)
    }

    public getAllUsers(): Observable<any> {
        const url = `${this.webServiceUri}/users`
        return this.http.get(url)
    }

    public createUser(user: any): Observable<any> {
        const url = `${this.webServiceUri}/users`
        return this.http.post(url, user)
    }

    public updateUser(userId: any, user: any): Observable<any> {
        const url = `${this.webServiceUri}/users/${userId}`
        return this.http.post(url, user)
    }

    public resetPasswordRequest(username: string): Observable<any> {
        const url = `${this.webServiceUri}/reset-password-request`
        const params = new HttpParams().set('username', username)
        return this.http.post(url, null, { params })
    }

    public resetPassword(payload: {
        token: string
        new_password: string
    }): Observable<any> {
        const url = `${this.webServiceUri}/reset-password`
        return this.http.post(url, payload)
    }

    public catchError(err: any): void {
        switch (err.status) {
            case 401:
                LocalStorage.clearAllState()
                this.router.navigate([''])
                break
            default:
                break
        }
    }
}
