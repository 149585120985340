<div class="outer">
    <div class="project-selection-container">
        <div class="project-selection-logo">
            <img src="../../../../assets/images/logo.png" alt="logo" />
        </div>
        <div class="project-select">
            <button
                mat-button
                class="new-project px-0"
                *ngIf="!newProject"
                [@leave]="true"
                (click)="onCreateClick()"
            >
                <img
                    matChipAvatar
                    src="../../../../assets/images/icons/add-icon.svg"
                    class="add-icon mb-1 mx-2"
                />
                <h6>Create a New {{ getPlaceholder }}</h6>
            </button>
            <form
                class="project-select-form"
                *ngIf="newProject"
                [@appear]="true"
            >
                <mat-form-field class="mat-form-field">
                    <mat-label>Name of {{ getPlaceholder }}</mat-label>
                    <input
                        class="project-field"
                        type="text"
                        matInput
                        [formControl]="newProjectControl"
                    />
                    <mat-error *ngIf="newProjectControl.hasError('required')">
                        Name of the project is <strong>required</strong>!
                    </mat-error>
                    <mat-error
                        *ngIf="newProjectControl.hasError('projectExists')"
                    >
                        Project already exists!
                    </mat-error>
                </mat-form-field>
                <button
                    color="accent"
                    class="new-project-button"
                    mat-raised-button
                    (click)="onSaveContinue()"
                >
                    Save & Continue
                </button>
            </form>
            <form class="project-select-form open">
                <mat-form-field
                    *ngIf="projectList$.length"
                    class="select-project-form-field"
                >
                    <mat-select
                        [formControl]="activeProjectControl"
                        class="mat-select-field"
                    >
                        <mat-option
                            (click)="selectProject(project)"
                            *ngFor="let project of projectList$"
                            [value]="project.name"
                            >{{ project.name }}</mat-option
                        >
                    </mat-select>
                    <img
                        matSuffix
                        matChipAvatar
                        src="../../../../assets/images/icons/arrow-down.svg"
                        class="arrow-down-icon mx-3"
                    />
                </mat-form-field>
                <button
                    *ngIf="projectList$.length"
                    mat-raised-button
                    color="primary"
                    class="open-project-button"
                    (click)="openProject()"
                >
                    Open {{ getPlaceholder }}
                </button>

                <button
                    *ngIf="projectList$.length"
                    mat-raised-button
                    class="delete-project-button"
                    (click)="deleteProject()"
                >
                    Delete {{ getPlaceholder }}
                </button>
            </form>
        </div>
    </div>
</div>
