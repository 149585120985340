import {
    AfterViewInit,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
} from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { Store } from '@ngrx/store'
import { Subscription } from 'rxjs'
import { FilterOptions, Filters } from '../state/models/filters.models'
import { AppState } from '../state/store'
import {
    activateTopAttribute,
    deactivateTopAttribute,
    getTopAttributes,
} from '../state/store/top-attributes/top-attributes.actions'

import { MatCheckboxChange } from '@angular/material/checkbox'
import { MatSort } from '@angular/material/sort'
import { ItemGenerationComponent } from '../shared/components/item-generation/item-generation.component'
import { ProjectItemsGenerationParams } from '../state/models/items.models'
import { Project } from '../state/models/projects.models'
import {
    TopAttribute,
    TopAttributeEnum,
} from '../state/models/top-attributes.models'
import { generateProjectItems } from '../state/store/projects/projects.actions'
import {
    selectActiveProject,
    selectOperationSuccess,
} from '../state/store/projects/projects.selectors'
import {
    selectActiveFilters,
    selectAllTopAttributes,
    selectFilterOptions,
} from '../state/store/top-attributes/top-attributes.selectors'
import { mergeFilters, removeEmptyFilters } from '../shared/helpers/filters'

@Component({
    selector: 'top-attributes',
    templateUrl: './top-attributes.component.html',
    styleUrls: ['./top-attributes.component.scss'],
})
export class TopAttributesComponent implements OnInit, OnDestroy {
    displayedColumns: string[] = [
        'select',
        'name',
        'trend_type',
        'trend_source',
        'item_type',
        'department',
        'category',
        'sub_category',
    ]

    dataSource: MatTableDataSource<TopAttribute> =
        new MatTableDataSource<TopAttribute>()

    @ViewChild(MatPaginator) paginator!: MatPaginator
    @ViewChild(MatSort) sort!: MatSort
    @ViewChild(ItemGenerationComponent)
    itemGenerationComponent!: ItemGenerationComponent

    private subscription = new Subscription()
    private topAttributes: TopAttribute[] = []
    private activeProject: Project | null = null
    private selectedFilterCount: number = 0
    public filterOptions: FilterOptions | null = null
    public activeFilters: Filters = {}
    public getSelectedFilters = selectActiveFilters
    public allSelected = false
    public mergeFilters = mergeFilters

    public get getSelectedFilterCount() {
        return this.selectedFilterCount
    }

    public get isDateRangeSelected() {
        return !!(
            this.activeFilters?.start_date && this.activeFilters?.end_date
        )
    }

    constructor(private store: Store<AppState>) {
        this.updateTopAttributes()

        this.subscription.add(
            this.store.select(selectActiveProject).subscribe((project) => {
                this.activeProject = project
            })
        )

        this.subscription.add(
            this.store
                .select(selectFilterOptions)
                .subscribe((res) => (this.filterOptions = res))
        )

        this.subscription.add(
            this.store.select(selectActiveFilters).subscribe((res) => {
                this.activeFilters = res
                this.selectedFilterCount = Object.values(res)
                    .filter((item) => Array.isArray(item))
                    .flat().length
            })
        )

        this.subscription.add(
            this.store.select(selectAllTopAttributes).subscribe((res) => {
                this.topAttributes = res
                this.dataSource = new MatTableDataSource<TopAttribute>(
                    this.topAttributes
                )
                this.dataSource.paginator = this.paginator
                this.dataSource.sort = this.sort
            })
        )
    }

    updateTopAttributes(filters?: Filters): void {
        const oldFilters: Filters = this.activeFilters

        const newFilters: Filters = removeEmptyFilters({
            ...oldFilters,
            ...filters,
        })

        this.store.dispatch(getTopAttributes({ payload: newFilters }))
    }

    public mergeAndApplyFilters(filters: Filters): void {
        const oldFilters = this.activeFilters
        const newFilters = mergeFilters(filters, oldFilters)
        this.updateTopAttributes(newFilters)
    }

    public resetFilters() {
        this.store.dispatch(getTopAttributes({ payload: { limit: 50 } }))
    }

    selectTopAttribute(
        event: MatCheckboxChange,
        topAttribute: TopAttribute
    ): void {
        if (event.checked) {
            this.store.dispatch(activateTopAttribute({ payload: topAttribute }))
        } else {
            this.store.dispatch(
                deactivateTopAttribute({ payload: topAttribute })
            )
        }
    }

    toggleTopAttributes(event: MatCheckboxChange): void {
        if (event.checked) {
            this.dataSource.data.forEach((topAttribute) => {
                this.store.dispatch(
                    activateTopAttribute({ payload: topAttribute })
                )
            })
        } else {
            this.dataSource.data.forEach((topAttribute) => {
                this.store.dispatch(
                    deactivateTopAttribute({ payload: topAttribute })
                )
            })
        }

        this.allSelected = event.checked
    }

    columnPlaceholder(column: string): string {
        return TopAttributeEnum[column as keyof typeof TopAttributeEnum]
    }

    applyFilter(event: Event): void {
        const filterValue = (event.target as HTMLInputElement).value
        this.dataSource.filter = filterValue.trim().toLowerCase()

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage()
        }
    }

    onDateRangeSelected(dateRange: Filters | null) {
        const newFilters = {
            ...this.activeFilters,
            ...dateRange,
        }
        this.updateTopAttributes(newFilters)
    }

    ngOnInit(): void {
        // Resetting the form after item generation api succeeds
        this.store
            .select(selectOperationSuccess)
            .subscribe((success: Boolean) => {
                if (success) this.itemGenerationComponent?.resetForm()
            })
    }

    ngOnDestroy() {
        this.subscription.unsubscribe()
    }
}
