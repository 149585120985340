<div class="generate-item-container pointer">
    <div class="outer-container" [formGroup]="form">
        <div class="w-100 mt-3">
            <div class="fs-18 gray-200">Generate Items:</div>
            <div class="fs-14 gray-200 mb-2 mt-2">
                Type In Custom Characteristics:
            </div>
            <item-filter
                [selectedFilters]="item_options"
                (onFilterValueSelected)="onFilterValueSelected($event)"
            ></item-filter>
            <div class="d-flex justify-content-start">
                <span class="fs-18 gray-200"> Selected Attributes: </span>
            </div>
            <div
                *ngIf="!selectedAttributes.length"
                class="d-flex justify-content-center pt-2"
            >
                <div class="fs-14 text-muted">
                    <i>No attribute selected.</i>
                </div>
            </div>
            <div
                *ngIf="selectedAttributes.length > 0"
                class="d-flex flex-wrap pt-2"
            >
                <div>
                    <mat-chip
                        *ngFor="let attribute of selectedAttributes.slice(0, 4)"
                        class="mat-chip m-1"
                    >
                        <span class="caption pr-3">
                            {{ attribute.name }}
                        </span>
                    </mat-chip>
                    <span
                        *ngIf="selectedAttributes.length > 4"
                        class="m-1"
                        #tooltip="matTooltip"
                        [matTooltip]="moreAttributes"
                        [matTooltipDisabled]="selectedAttributes.length <= 3"
                        [matTooltipClass]="'mat-tooltip-scrollable'"
                    >
                        and {{ selectedAttributes.length - 4 }} more
                    </span>
                </div>
            </div>
            <div class="d-flex justify-content-start w-100 mt-3">
                <span class="fs-18 gray-200"> Let your items be: </span>
                <img
                    matChipAvatar
                    matTooltip="Add custom attributes"
                    class="ml-2"
                    src="../../../../assets/images/icons/info-icon.svg"
                />
            </div>

            <div class="w-100 d-flex mt-3">
                <mat-button-toggle-group
                    class="mt-3 temperature-btn-group"
                    [formControl]="weight1"
                >
                    <mat-button-toggle
                        *ngFor="let temperature of temperatureTypes"
                        [value]="temperature"
                    >
                        {{ temperature }}
                    </mat-button-toggle>
                </mat-button-toggle-group>
            </div>
            <div class="w-100 mt-3">
                <mat-form-field appearance="fill" class="w-100 mat-form-field">
                    <mat-label>Attribute 1</mat-label>
                    <input
                        matInput
                        type="text"
                        formControlName="user_attribute_1"
                    />
                    <mat-icon matSuffix>edit</mat-icon>
                </mat-form-field>
            </div>

            <div class="d-flex justify-content-start">
                <span class="fs-18 gray-200"> And: </span>
            </div>

            <mat-button-toggle-group
                class="mt-3 temperature-btn-group"
                [formControl]="weight2"
            >
                <mat-button-toggle
                    *ngFor="let temperature of temperatureTypes"
                    [value]="temperature"
                >
                    {{ temperature }}
                </mat-button-toggle>
            </mat-button-toggle-group>
            <div class="w-100 mt-3">
                <mat-form-field appearance="fill" class="w-100 mat-form-field">
                    <mat-label>Attribute 2</mat-label>
                    <input
                        matInput
                        type="text"
                        formControlName="user_attribute_2"
                    />
                    <mat-icon
                        matSuffix
                        [class.disabled-icon]="
                            form.get('user_attribute_2')?.disabled
                        "
                    >
                        edit
                    </mat-icon>
                </mat-form-field>
            </div>

            <div class="d-flex justify-content-start">
                <span class="fs-18 gray-200"> And: </span>
            </div>

            <mat-button-toggle-group
                class="mt-3 temperature-btn-group"
                [formControl]="weight3"
            >
                <mat-button-toggle
                    *ngFor="let temperature of temperatureTypes"
                    [value]="temperature"
                >
                    {{ temperature }}
                </mat-button-toggle>
            </mat-button-toggle-group>
            <div class="w-100 mt-3">
                <mat-form-field appearance="fill" class="w-100 mat-form-field">
                    <mat-label>Attribute 3</mat-label>
                    <input
                        matInput
                        type="text"
                        formControlName="user_attribute_3"
                    />
                    <mat-icon
                        matSuffix
                        [class.disabled-icon]="
                            form.get('user_attribute_2')?.disabled
                        "
                    >
                        edit
                    </mat-icon>
                </mat-form-field>
            </div>

            <div class="d-flex justify-content-start mt-2">
                <span class="fs-18 gray-200"> Add A Custom Prompt: </span>
                <img
                    matChipAvatar
                    matTooltip="Write custom instructions to consider for generating your items"
                    class="ml-2"
                    src="../../../../assets/images/icons/info-icon.svg"
                />
            </div>

            <div class="w-100 mt-2">
                <mat-form-field appearance="fill" class="w-100 mat-form-field">
                    <mat-label>Text Here</mat-label>
                    <div class="d-flex align-items-start">
                        <textarea
                            matInput
                            #custom_prompt
                            rows="5"
                            type="text"
                            formControlName="custom_prompt"
                        >
                        </textarea>
                        <mat-icon matSuffix>edit</mat-icon>
                    </div>
                </mat-form-field>
            </div>

            <div class="d-flex justify-content-start mt-2">
                <span class="fs-18 gray-200"> Count: </span>
                <img
                    matChipAvatar
                    matTooltip="Select the number of concepts you want to generate"
                    class="ml-2"
                    src="../../../../assets/images/icons/info-icon.svg"
                />
            </div>

            <div class="w-100 mt-2">
                <mat-form-field class="w-100 mat-form-field">
                    <mat-select
                        placeholder="Select the number of concept"
                        [formControl]="n_of_items"
                    >
                        <mat-option
                            *ngFor="let option of countOptions"
                            [value]="option"
                        >
                            {{ option }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="d-flex justify-content-start">
                <span class="fs-18 gray-200"> Creativity Slider </span>
                <img
                    matChipAvatar
                    matTooltip="How creative do you want our generator to be"
                    class="ml-2 info-icon"
                    src="../../../../assets/images/icons/info-icon.svg"
                />
            </div>
            <div class="d-flex justify-content-between w-100 pb-4">
                <div class="w-100">
                    <mat-slider
                        min="0"
                        max="1"
                        step="0.1"
                        value="0"
                        showTickMarks
                        class="slider-width"
                    >
                        <input
                            matSliderThumb
                            formControlName="temperature_slider"
                    /></mat-slider>
                </div>
                <div class="w-100">
                    <button
                        mat-fab
                        extended
                        color="primary"
                        class="generate-item-button"
                        (click)="generateProjectItems()"
                    >
                        Generate Items
                    </button>
                </div>
            </div>
            <div class="w-100 ai-generation-container">
                <img
                    src="assets/images/icons/stars.svg"
                    alt="logo"
                    class="stars"
                />
                <div>
                    <div class="d-flex justify-content-center mt-4">
                        <img
                            matChipAvatar
                            src="../../../../assets/images/icons/stars-3.svg"
                        />
                        <div class="magic-heading">
                            Or let us do your MAGIC:
                        </div>
                        <img
                            matChipAvatar
                            class="ml-2 info-icon"
                            src="../../../../assets/images/icons/info-icon.svg"
                        />
                    </div>
                    <div class="d-flex">
                        <button
                            mat-raised-button
                            color="primary"
                            class="generate-item-advanced mt-2"
                            (click)="generateAdvancedItems()"
                        >
                            Generate Items Omni AI
                        </button>
                        <img
                            src="assets/images/icons/stars-3.svg"
                            alt="logo"
                            class="star-sm"
                        />
                    </div>
                </div>

                <img
                    src="assets/images/icons/stars-2.svg"
                    alt="logo"
                    class="stars align-self-end"
                />
            </div>
        </div>
    </div>
</div>
