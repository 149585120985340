import { createSelector } from '@ngrx/store'
import { commentsFeatureKey, CommentsState } from './comments.reducer'

interface AppState {
    [commentsFeatureKey]: CommentsState
}

const selectCommentsState = (state: AppState) => state[commentsFeatureKey]

export const selectGetComments = createSelector(
    selectCommentsState,
    (state: CommentsState) => state.comments
)
