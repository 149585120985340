import { Injectable, OnDestroy, inject } from '@angular/core'
import {
    ActivatedRouteSnapshot,
    CanActivateFn,
    Router,
    RouterStateSnapshot,
} from '@angular/router'
import { Store, select } from '@ngrx/store'
import { Subscription, filter } from 'rxjs'
import { LocalStorage } from '../local-storage'
import { WebSocketService } from '../services/websocket.service'
import { AppState } from '../store'
import {
    getOneProject,
    setActiveProject,
} from '../store/projects/projects.actions'
import { selectAccessToken } from '../store/users/users.selectors'

@Injectable({
    providedIn: 'root',
})
export class PermissionsService implements OnDestroy {
    private subscription = new Subscription()
    private access_token: string | undefined =
        LocalStorage.getUsersState()?.access_token
    constructor(
        private router: Router,
        private store: Store<AppState>,
        private webSocketWebService: WebSocketService
    ) {
        this.subscription.add(
            this.store
                .pipe(
                    select(selectAccessToken),
                    filter((res) => !!res)
                )
                .subscribe((res) => {
                    this.access_token = res
                })
        )
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        if (this.access_token) {
            const activeProject = LocalStorage.getProjectsState()?.activeProject

            if (activeProject) {
                if (!this.webSocketWebService.isConnectionOpened) {
                    this.webSocketWebService.connect()
                }
                this.store.dispatch(
                    getOneProject({
                        payload: {
                            id: activeProject.id,
                            filters: { limit: 25 },
                        },
                    })
                )
                this.store.dispatch(
                    setActiveProject({ payload: activeProject })
                )
            }

            const redirectPath = LocalStorage.getRedirectPath()
            if (redirectPath) {
                sessionStorage.removeItem('redirectPath')
                this.router.navigate([redirectPath])
            }

            return true
        } else {
            LocalStorage.clearAllState()
            sessionStorage.setItem('redirectPath', state.url)
            this.router.navigate(['/login'])
            return false
        }
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe()
    }
}

export const AuthGuard: CanActivateFn = (
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
): boolean => {
    return inject(PermissionsService).canActivate(next, state)
}
