import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { Filters } from '../models/filters.models'
import { Project } from '../models/projects.models'

@Injectable()
export class ProjectsWebService {
    private projectWebServiceUri: string = environment.API_URL + '/projects'

    constructor(protected http: HttpClient) {}

    public getOneProject(id: string, filters: Filters): Observable<Project> {
        const url = this.projectWebServiceUri + `/${id}`
        return this.http.post<Project>(url, filters)
    }

    public getAllProjects(): Observable<Array<Project>> {
        const url = this.projectWebServiceUri

        return this.http.get<Array<Project>>(url)
    }

    public createProject(projectName: string): Observable<Project> {
        const url = this.projectWebServiceUri
        const params = { project_name: projectName }

        return this.http.post<Project>(url, '', { params: params })
    }

    public deleteProject(projectId: string): Observable<Project> {
        const url = `${this.projectWebServiceUri}/${projectId}`
        return this.http.delete<Project>(url)
    }
}
