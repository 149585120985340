import { Project } from './projects.models'

export interface User {
    username?: string
    password?: string
    projects?: Project[]
    is_decommissioned?: boolean
}
export interface LoginRequestParams {
    username: string
    password: string
}

export interface LoginResponseI {
    access_token: string
    token_type: string
}

export interface LoggedInUser {
    id: string
    username: string
    first_name: string
    last_name: string
    email: string
    role: string
    title: string
}

export enum ManageUsersEnum {
    username = 'Username',
    first_name = 'First Name',
    last_name = 'Last Name',
    role = 'Role',
    status = 'Status',
    action = 'Action',
}

export interface CreateUserPayload {
    username: string
    first_name: string
    last_name: string
    password: string
    role: string
}

export interface UpdateUserPayload {
    username?: string
    first_name?: string
    last_name?: string
    role?: string
    password?: string
    is_decommissioned?: boolean
}
