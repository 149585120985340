import { createSelector } from '@ngrx/store'
import {
    topAttributesFeatureKey,
    TopAttributesState,
} from './top-attributes.reducer'

interface AppState {
    [topAttributesFeatureKey]: TopAttributesState
}

const selectTopAttributesState = (state: AppState) =>
    state[topAttributesFeatureKey]

export const selectAllTopAttributes = createSelector(
    selectTopAttributesState,
    (state: TopAttributesState) => state.allTopAttributes
)

export const selectActiveTopAttributes = createSelector(
    selectTopAttributesState,
    (state: TopAttributesState) => state.activeTopAttributes
)

export const selectFilterOptions = createSelector(
    selectTopAttributesState,
    (state: TopAttributesState) => state.allFilters
)

export const selectActiveFilters = createSelector(
    selectTopAttributesState,
    (state: TopAttributesState) => state.activeFilters
)
