<div
    #tooltipContainer
    class="card-image-container"
    (click)="openItemPage(item?.id)"
    (mouseover)="showElements = true"
    (mouseleave)="clearHoverElements()"
>
    <mat-icon
        *ngIf="item?.creator?.id === loggedInUser?.id"
        fontIcon="close"
        class="delete-button-container pointer"
        [hidden]="!showElements"
        (click)="stopPropagation($event); deleteItem()"
    ></mat-icon>
    <img
        class="card-image pointer"
        mat-card-image
        [src]="thumbnailURL || item?.thumbnailURL"
        onerror="this.src='../../assets/images/image_placeholder.png';"
        alt="Item img"
    />
    <span [class.hide]="!showElements">
        <omni-select
            class="move-item-position pointer"
            [placeholder]="'Move'"
            [list]="selectList"
            (onSelectE)="moveItemToProject($event)"
            (click)="stopPropagation($event)"
        ></omni-select>

        <div
            (click)="likeItem($event)"
            class="like-icon-container d-flex justify-content-center align-items-center pointer"
        >
            <mat-icon
                class="icon"
                aria-hidden="false"
                [fontIcon]="item?.is_liked ? 'favorite' : 'favorite_border'"
            ></mat-icon>
        </div>
        <div
            class="help-icon-container item-tooltip"
            (mouseover)="showMoreInfo = true"
            [class.show-item-tooltiptext]="showMoreInfo"
        >
            <div class="d-flex help-icon justify-content-center pointer">?</div>
            <span
                #tooltip
                class="item-tooltiptext p-3"
                [class.right-positioned-tooltip]="notEnoughSpace"
                [ngStyle]="
                    notEnoughSpace
                        ? { left: helpTooltipPosition }
                        : { right: 'unset' }
                "
            >
                <div class="pt-title">Why are we suggesting this design?</div>
                <div class="caption" [innerHTML]="itemTooltip"></div>
            </span>
        </div>
        <div
            #ratingSection
            class="rating-section"
            (mouseover)="showRating = true"
            [class.show-rating-bar-tooltip]="showRating"
        >
            <div class="rating-bar">
                <mat-progress-spinner
                    class="pointer"
                    [diameter]="37"
                    [strokeWidth]="5"
                    [color]="ratingBarColor"
                    [mode]="ratingBarMode"
                    [value]="20 * 4.6"
                >
                </mat-progress-spinner>
                <div class="rating-bar-number pointer">4.6</div>
            </div>
            <span class="rating-bar-tooltip p-3">
                <div
                    class="h-100 d-flex justify-content-around align-items-center"
                >
                    <ng-container *ngFor="let item of mocRatingList">
                        <div class="rating-container">
                            <div class="rating-name">{{ item.title }}</div>
                            <div class="rating-spiner">
                                <mat-progress-spinner
                                    [diameter]="ratingBarsDiameter"
                                    [strokeWidth]="ratingBarsDiameter / 10"
                                    [color]="ratingBarColor"
                                    [mode]="ratingBarMode"
                                    [value]="item.ratingPerc"
                                >
                                </mat-progress-spinner>
                                <div class="rating-number">
                                    {{ item.rating }}
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </span>
        </div>
    </span>
</div>
<h5 class="card-name">
    {{ item?.name }}
</h5>
<div class="d-flex align-items-center mt-2">
    <mat-chip class="mat-chip">
        <div class="caption pr-3 d-flex">
            <div>Owner:</div>
            <div class="ml-1">{{ userFullName(item?.creator) }}</div>
        </div>
    </mat-chip>
</div>
