import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, exhaustMap, map, switchMap } from 'rxjs/operators'
import { LoggedInUser, LoginResponseI } from '../../models/users.models'
import { UsersWebService } from '../../services/users-web.service'
import {
    UsersActions,
    UsersUnion,
    getAllUsersSuccess,
    getAllUsersFailure,
    getLoggedInUserFailure,
    getLoggedInUserSuccess,
    loginFailure,
    loginSuccess,
    createUserSuccess,
    createUserFailure,
    updateUserSuccess,
    updateUserFailure,
    resetPasswordSuccess,
    resetPasswordFailure,
    resetPasswordRequestSuccess,
    resetPasswordRequestFailure,
} from './users.actions'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Router } from '@angular/router'

@Injectable()
export class UsersEffects {
    public login$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(UsersActions.LOGIN),
            exhaustMap(({ payload: params }) => {
                return this.usersWebService.login(params).pipe(
                    map((res: LoginResponseI) => {
                        return loginSuccess({ payload: res })
                    }),
                    catchError((err) => {
                        this.snackbar.open(
                            `Email or password is incorrect`,
                            'Ok',
                            {
                                duration: 1200,
                            }
                        )

                        this.usersWebService.catchError(err)
                        return of(loginFailure())
                    })
                )
            })
        )
    })

    public getLoggedInUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(UsersActions.GET_LOGGED_IN_USER),
            switchMap(() => {
                return this.usersWebService.getLoggedInUser().pipe(
                    map((res: LoggedInUser) => getLoggedInUserSuccess(res)),
                    catchError((err) => {
                        this.snackbar.open(`Error fetching the user`, 'Ok', {
                            duration: 1200,
                        })

                        this.usersWebService.catchError(err)
                        return of(getLoggedInUserFailure())
                    })
                )
            })
        )
    })

    public getAllUsers$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(UsersActions.GET_ALL_USERS),
            switchMap(() => {
                return this.usersWebService.getAllUsers().pipe(
                    map((res: any) => getAllUsersSuccess({ payload: res })),
                    catchError((err) => {
                        this.snackbar.open(`Error fetching the user`, 'Ok', {
                            duration: 1200,
                        })

                        this.usersWebService.catchError(err)
                        return of(getAllUsersFailure())
                    })
                )
            })
        )
    })

    public createUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(UsersActions.CREATE_USER),
            exhaustMap(({ payload }) => {
                return this.usersWebService.createUser(payload).pipe(
                    exhaustMap((res: any) => {
                        const { role } = payload
                        if (role === 'member')
                            return of(
                                createUserSuccess({
                                    payload: res,
                                })
                            )
                        else
                            return this.usersWebService
                                .updateUser(res.id, payload)
                                .pipe(
                                    map((response: any) => {
                                        return updateUserSuccess({
                                            userId: res.id,
                                            payload: response,
                                        })
                                    })
                                )
                    }),
                    catchError((err) => {
                        this.snackbar.open(`Error creating the user`, 'Ok', {
                            duration: 1200,
                        })

                        this.usersWebService.catchError(err)
                        return of(createUserFailure())
                    })
                )
            })
        )
    })

    public updateUser$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(UsersActions.UPDATE_USER),
            exhaustMap(({ userId, payload }) => {
                return this.usersWebService.updateUser(userId, payload).pipe(
                    map((res: any) => {
                        return updateUserSuccess({
                            userId,
                            payload: res,
                        })
                    }),
                    catchError((err) => {
                        this.snackbar.open(`Error updating the user`, 'Ok', {
                            duration: 1200,
                        })

                        this.usersWebService.catchError(err)
                        return of(updateUserFailure())
                    })
                )
            })
        )
    })

    public resetPassword$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(UsersActions.RESET_PASSWORD),
            exhaustMap(({ payload }) => {
                return this.usersWebService.resetPassword(payload).pipe(
                    map((res: any) => {
                        this.snackbar.open(
                            'Password reset successfully',
                            'Ok',
                            {
                                duration: 3000,
                            }
                        )
                        this.router.navigate(['/login'])
                        return resetPasswordSuccess({ payload: res })
                    }),
                    catchError((err) => {
                        this.snackbar.open('Error resetting password', 'Ok', {
                            duration: 3000,
                        })
                        this.usersWebService.catchError(err)
                        return of(resetPasswordFailure({ error: err }))
                    })
                )
            })
        )
    })

    public resetPasswordRequest$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(UsersActions.RESET_PASSWORD_REQUEST),
            exhaustMap(({ username }) => {
                return this.usersWebService.resetPasswordRequest(username).pipe(
                    map(() => {
                        this.snackbar.open(
                            'Reset password link sent to your email',
                            'Ok',
                            {
                                duration: 3000,
                            }
                        )
                        return resetPasswordRequestSuccess()
                    }),
                    catchError((err) => {
                        this.snackbar.open(
                            'Error requesting password reset',
                            'Ok',
                            {
                                duration: 3000,
                            }
                        )
                        this.usersWebService.catchError(err)
                        return of(resetPasswordRequestFailure({ error: err }))
                    })
                )
            })
        )
    })

    constructor(
        private actions$: Actions<UsersUnion>,
        private usersWebService: UsersWebService,
        private snackbar: MatSnackBar,
        private router: Router
    ) {}
}
