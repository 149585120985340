import { Component, OnInit } from '@angular/core'
import { Subscription } from 'rxjs'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/state/store'
import { selectAllUsers } from 'src/app/state/store/users/users.selectors'
import { ManageUsersEnum, User } from 'src/app/state/models/users.models'
import { getAllUsers } from '../state/store/users/users.actions'
import { UserDialog } from '../shared/dialogs/user.dialog/user.dialog'
import { MatDialog } from '@angular/material/dialog'
import { UserConfirmationDialog } from '../shared/dialogs/user.confirmation.dialog/user.confirmation.dialog'

@Component({
    selector: 'manage-users',
    templateUrl: './manage-users.component.html',
    styleUrls: ['./manage-users.component.scss'],
})
export class ManageUsers implements OnInit {
    private subscription = new Subscription()

    public displayedColumns: string[] = [
        'first_name',
        'last_name',
        'username',
        'role',
        'status',
        'action',
    ]

    public dataSource: User[] = []

    constructor(
        private store: Store<AppState>,
        private dialog: MatDialog
    ) {}

    public ngOnInit(): void {
        this.subscription.add(
            this.store.select(selectAllUsers).subscribe((allUsers) => {
                this.dataSource = allUsers
            })
        )

        this.store.dispatch(getAllUsers())
    }

    public columnPlaceholder(column: string): string {
        return ManageUsersEnum[column as keyof typeof ManageUsersEnum]
    }

    public addUser() {
        this.dialog.open(UserDialog, {
            data: { type: 'Create' },
        })
    }

    public editUser(user: User) {
        this.dialog.open(UserDialog, {
            data: { user, type: 'Update' },
        })
    }

    public toggleUserStatus(user: User) {
        this.dialog.open(UserConfirmationDialog, {
            data: {
                user,
                type: user?.is_decommissioned ? 'Restore' : 'Archive',
            },
        })
    }
}
