import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms'
import { Project } from 'src/app/state/models/projects.models'

export function projectValidator(projectList: Project[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const newName = control.value as string
        const namesList: string[] = []
        projectList.forEach((project) => {
            namesList.push(project.name)
        })
        return namesList.includes(newName) ? { projectExists: true } : null
    }
}
