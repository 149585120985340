import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { Filters } from '../models/filters.models'
import { GetTopAttributesResponse } from '../models/top-attributes.models'

@Injectable()
export class TopAttributesWebService {
    private webServiceUri: string = environment.API_URL + '/top_attributes'

    constructor(protected http: HttpClient) {}

    public getTopAttributes(
        filters: Filters
    ): Observable<GetTopAttributesResponse> {
        const url = this.webServiceUri
        return this.http.post<GetTopAttributesResponse>(url, filters)
    }
}
