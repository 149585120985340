import { Component, OnDestroy, OnInit } from '@angular/core'
import { PageEvent } from '@angular/material/paginator'
import { Store, select } from '@ngrx/store'
import { Subscription } from 'rxjs'
import { FilterOptions, Filters } from '../state/models/filters.models'
import { AppState } from '../state/store'
import { mergeFilters, removeEmptyFilters } from '../shared/helpers/filters'
import { Item } from '../state/models/items.models'
import { Project } from '../state/models/projects.models'
import { Router } from '@angular/router'

import {
    getOneProject,
    likeItem,
} from '../state/store/projects/projects.actions'
import {
    selectActiveProject,
    selectGalleryActiveFilters,
    selectSkeletonLoaders,
} from '../state/store/projects/projects.selectors'

@Component({
    selector: 'gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss'],
})
export class GalleryComponent implements OnInit, OnDestroy {
    private selectedFilterCount: number = 0
    private pollingIntervalId: any = null
    public filterOptions: FilterOptions | null = null
    public subscription = new Subscription()
    public activeProject: Project | null = null
    public allItems: Array<Item> = []
    public pageSizeOptions = [25, 50, 100]
    public pageSize = 25
    public currentPage = 0
    public activeFilters: Filters = {}
    public isLiked: Boolean = false
    public getSelectedFilters = selectGalleryActiveFilters
    public mergeFilters = mergeFilters
    public skeletonLoadersArray: Array<any> = []

    public get getSelectedFilterCount() {
        return this.selectedFilterCount
    }

    public get isDateRangeSelected(): boolean {
        return !!(
            this.activeFilters?.start_date && this.activeFilters?.end_date
        )
    }

    public get getAllItems() {
        return [...this.skeletonLoadersArray, ...this.allItems]
    }

    constructor(
        private store: Store<AppState>,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.subscription.add(
            this.store
                .select(selectActiveProject)
                .subscribe((activeProject) => {
                    if (activeProject?.id !== this.activeProject?.id)
                        this.isLiked = false
                    this.activeProject = activeProject
                    this.filterOptions = activeProject?.filters || null
                    if (activeProject?.items) {
                        this.allItems = activeProject?.items
                    }

                    this.store
                        .pipe(select(selectSkeletonLoaders))
                        .subscribe((skeletonLoaders) => {
                            this.skeletonLoadersArray =
                                skeletonLoaders[this.activeProject?.id ?? ''] ||
                                []
                        })
                })
        )

        this.subscription.add(
            this.store
                .select(selectGalleryActiveFilters)
                .subscribe((filters) => {
                    const newFilters = { ...filters }
                    if (newFilters['creator']) delete newFilters['creator']
                    this.activeFilters = newFilters

                    this.selectedFilterCount = Object.values(newFilters)
                        .filter((item) => Array.isArray(item))
                        .flat().length
                    if (
                        this.activeFilters.limit &&
                        this.activeFilters.limit != this.pageSize
                    ) {
                        this.pageSize = this.activeFilters.limit
                    }
                })
        )
    }

    public updateActiveProject(filters?: Filters): void {
        if (this.activeProject?.id) {
            this.store.dispatch(
                getOneProject({
                    payload: {
                        id: this.activeProject?.id,
                        filters: filters ? filters : this.activeFilters,
                    },
                })
            )
        }
    }

    public mergeAndApplyFilters(filters: Filters): void {
        const oldFilters = this.activeFilters
        const newFilters = mergeFilters(filters, oldFilters)
        this.updateActiveProject(newFilters)
    }

    public toggleLiked() {
        this.isLiked = !this.isLiked
        this.likeFilterSelected()
    }

    public resetFilters() {
        const newFilters = {
            offset: 0,
            limit: this.pageSize,
        }
        if (this.activeProject) {
            this.updateActiveProject(newFilters)
        }
    }

    public onDateRangeSelected(dateRange: Filters) {
        const newFilters = removeEmptyFilters({
            ...this.activeFilters,
            ...dateRange,
        })
        this.updateActiveProject(newFilters)
    }

    public handlePageEvent(e: PageEvent): void {
        this.currentPage = e.pageIndex
        this.pageSize = e.pageSize

        const currentFilters = { ...this.activeFilters }
        const paginationOptions = {
            limit: this.pageSize,
            offset: this.currentPage * this.pageSize,
        }
        const newFiltersOptions: Filters = {
            ...currentFilters,
            ...paginationOptions,
        }

        this.updateActiveProject(newFiltersOptions)
    }

    public likeItem(item: Item) {
        if (item?.id) {
            this.store.dispatch(
                likeItem({
                    payload: {
                        itemId: item.id,
                        isLiked: !item.is_liked,
                    },
                })
            )
        }
        const newFilters = removeEmptyFilters({
            ...this.activeFilters,
            ...{ is_liked: this.isLiked },
        })
        this.updateActiveProject(newFilters)
    }

    public likeFilterSelected() {
        const newFilters = removeEmptyFilters({
            ...this.activeFilters,
            ...{ is_liked: this.isLiked },
        })
        this.updateActiveProject(newFilters)
    }

    public handleRedirection() {
        this.router.navigate(['/top-attributes'])
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe()
    }
}
