<form>
    <mat-form-field class="px-2 filter-list">
        <mat-label class="fs-14">{{ filterPlaceholder(filterType) }}</mat-label>
        <!-- Selected options -->
        <mat-chip-grid
            #chipGrid
            aria-label="Filter selection"
            class="d-flex flex-wrap"
        >
        </mat-chip-grid>
        <input
            #filterInput
            [formControl]="filterControl"
            [matChipInputFor]="chipGrid"
            [matAutocomplete]="auto"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (focus)="filterControl.updateValueAndValidity()"
        />
        <mat-icon matPrefix class="ml-2 p-0">
            <img class="mb-4" [src]="getFilterIcon(filterType)" alt="" />
        </mat-icon>
        <mat-icon class="mr-2 p-0" matSuffix>keyboard_arrow_down</mat-icon>
        <mat-autocomplete #auto="matAutocomplete">
            <!-- Options to select -->
            <mat-option
                *ngFor="let option of filterOptions"
                (click)="onFilterOptionSelected(option)"
            >
                {{ option }}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</form>
