<div class="dialog-container">
    <div
        class="row h-100 m-0 justify-content-between justify-content-sm-center"
    >
        <div class="col-auto p-0">
            <div
                class="pb-3"
                (mouseover)="showElements = true"
                (mouseleave)="showElements = false"
            >
                <img
                    class="dialog-image"
                    [src]="thumbnailURL"
                    onerror="this.src='../../assets/images/image_placeholder.png';"
                    alt=""
                />
                <ng-container *ngIf="showElements">
                    <div
                        (click)="likeItem($event)"
                        class="icon-container like-icon d-flex justify-content-center align-items-center pointer"
                    >
                        <mat-icon
                            class="icon"
                            aria-hidden="false"
                            [fontIcon]="this.activeItem$?.is_liked ? 'favorite' : 'favorite_border'"
                        ></mat-icon>
                    </div>
                    <div class="help-icon-container item-tooltip">
                        <div
                            class="help-icon d-flex justify-content-center"
                            style="padding-top: 6px"
                        >
                            ?
                        </div>
                        <span class="item-tooltiptext container p-3">
                            <h5><b>Why are we suggesting this design?</b></h5>
                            <span> {{this.activeItem$?.ml_explain}} </span>
                        </span>
                    </div>
                </ng-container>
                <ng-container *ngIf="this.allProjects$.length"> </ng-container>
            </div>
            <div class="move-button">
                <h3 class="item-title">{{this.activeItem$?.name}}</h3>
                <button
                    mat-button
                    color="primary"
                    class="move-to-project-btn"
                    [matMenuTriggerFor]="menu"
                >
                    Move to Project
                </button>
            </div>
            <mat-menu #menu="matMenu" xPosition="before" yPosition="above">
                <button
                    mat-menu-item
                    *ngFor="let project of allProjects$"
                    (click)="moveItemToProject(project)"
                >
                    {{ project.name }}
                </button>
            </mat-menu>
            <p class="item-description pb-md-3">
                {{this.activeItem$?.description}}
            </p>
        </div>
        <div class="details-container">
            <div class="row">
                <div class="col-auto">
                    <div
                        class="actions-container d-flex justify-content-between align-items-center"
                    >
                        <div class="budget">
                            Budget:
                            <input
                                class="text"
                                type="number"
                                placeholder="$XX.XX"
                                id="totalpaying"
                                name="totalpaying"
                            />
                            <button
                                mat-mini-fab
                                class="calculate-btn"
                                (click)="generateComponents()"
                            >
                                <mat-icon>calculate</mat-icon>
                            </button>
                        </div>

                        <div
                            class="score-container d-flex align-items-center justify-content-between"
                        >
                            <div class="score-element pb-1 pt-1 pr-2 pl-2">
                                MSI Score: 35 - 45
                                <img
                                    class="higg-index-img pl-2"
                                    src="assets/images/Higg_Index.svg"
                                    alt="HiggIndex"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <table
                mat-table
                [dataSource]="dataSource"
                class="budget-table mat-elevation-z8"
            >
                <ng-container *ngFor="let column of displayedColumns">
                    <ng-container [matColumnDef]="column">
                        <th mat-header-cell *matHeaderCellDef>
                            {{formatString(column)}}
                        </th>
                        <td mat-cell *matCellDef="let element">
                            {{element[column]}}
                        </td>
                    </ng-container>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: columnsToDisplay;"
                ></tr>
            </table>
        </div>
    </div>
</div>
