import { Component, Input } from '@angular/core'

@Component({
    selector: 'copy-to-clipboard',
    templateUrl: './copy-to-clipboard.component.html',
})
export class CopyToClipboardComponent {
    @Input() content: string = ''
    @Input() size: string = ''
    isCopied: boolean = false

    public copyContent(event: Event) {
        event.stopPropagation()

        if (this.content) {
            navigator.clipboard
                .writeText(this.content)
                .then(() => {
                    this.isCopied = true

                    // Reset the icon back to 'content_copy' after 2 seconds
                    setTimeout(() => {
                        this.isCopied = false
                    }, 2000)
                })
                .catch((err) => {
                    console.error('Could not copy text: ', err)
                })
        }
    }
}
