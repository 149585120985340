<header class="section">
    <div class="fs-18">{{data.type}} User</div>
    <mat-icon
        fontIcon="close"
        class="pointer"
        [mat-dialog-close]="false"
    ></mat-icon>
</header>
<section>
    <form class="d-flex flex-column" [formGroup]="myForm">
        <mat-form-field appearance="fill">
            <mat-label>First Name</mat-label>
            <input
                matInput
                formControlName="firstName"
                placeholder="Enter your first name"
            />
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Last Name</mat-label>
            <input
                matInput
                formControlName="lastName"
                placeholder="Enter your last name"
            />
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Email</mat-label>
            <input
                matInput
                formControlName="email"
                placeholder="Enter your email"
            />
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Password</mat-label>
            <input
                matInput
                formControlName="password"
                placeholder="Enter your password"
                [type]="'password'"
            />
        </mat-form-field>

        <label>Role</label>
        <mat-radio-group formControlName="role">
            <mat-radio-button value="admin">Admin</mat-radio-button>
            <mat-radio-button class="ml-3" value="member"
                >Member</mat-radio-button
            >
        </mat-radio-group>

        <div class="d-flex justify-content-end">
            <button mat-raised-button class="cancel" [mat-dialog-close]="false">
                Cancel
            </button>
            <div matTooltipPosition="above" [matTooltip]="getErrorMessages()">
                <button
                    mat-raised-button
                    color="primary"
                    class="submit ml-3"
                    type="submit"
                    matTooltipPosition="above"
                    [disabled]="isFormInvalid"
                    (click)="data.type === 'Create' ? addUser() : editUser()"
                >
                    <div class="button-content">
                        Submit
                        <mat-spinner
                            *ngIf="isLoading"
                            diameter="18"
                            class="spinner ml-1"
                        ></mat-spinner>
                    </div>
                </button>
            </div>
        </div>
    </form>
</section>
