<header>
    <h4>Confirm</h4>
    <mat-icon
        fontIcon="close"
        class="pointer"
        [mat-dialog-close]="false"
    ></mat-icon>
</header>
<section>
    <h5 class="info" [innerHTML]="data"></h5>

    <div class="button-row">
        <button id="confirm" [mat-dialog-close]="true">Confirm</button>
        <button id="cancel" [mat-dialog-close]="false">Cancel</button>
    </div>
</section>
