import { createSelector } from '@ngrx/store'
import { ProjectsState, projectsFeatureKey } from './projects.reducer'

interface AppState {
    [projectsFeatureKey]: ProjectsState
}

const selectProjectsState = (state: AppState) => state[projectsFeatureKey]

export const selectAllProjects = createSelector(
    selectProjectsState,
    (state: ProjectsState) => state.allProjects
)

export const selectActiveProject = createSelector(
    selectProjectsState,
    (state: ProjectsState) => {
        return state.activeProject
    }
)

export const selectActiveItem = createSelector(
    selectProjectsState,
    (state: ProjectsState) => {
        return state.activeItem
    }
)

export const selectGalleryFilterOptions = createSelector(
    selectProjectsState,
    (state: ProjectsState) => state.allFilters
)

export const selectGalleryActiveFilters = createSelector(
    selectProjectsState,
    (state: ProjectsState) => state.activeFilters
)

export const selectOperationSuccess = createSelector(
    selectProjectsState,
    (state: ProjectsState) => state.operationSuccess
)

export const selectSkeletonLoaders = createSelector(
    selectProjectsState,
    (state: ProjectsState) => state.skeletonLoaders
)
