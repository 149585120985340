import { DialogRef } from '@angular/cdk/dialog'
import { Component, Inject, OnDestroy } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ActivatedRoute, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { Subscription } from 'rxjs'
import { ItemComponentI } from 'src/app/state/models/components.models'
import { Item } from 'src/app/state/models/items.models'
import { Project } from 'src/app/state/models/projects.models'
import { AppState } from 'src/app/state/store'
import {
    generateItemComponents,
    likeItem,
    moveItemToProject,
} from 'src/app/state/store/projects/projects.actions'
import {
    selectActiveItem,
    selectAllProjects,
} from 'src/app/state/store/projects/projects.selectors'
import { environment } from 'src/environments/environment'
import { formatString } from '../../helpers/table-title-formatter'
import { convertStringToNumber } from '../../helpers/type-convertes'

const ELEMENT_DATA: MocDataComponentItemDialog[] = [
    {
        cost_component: 'Jersey knit fabric',
        details: '1.5 yard',
        unit_cost: '$3/yard',
        total: '$4.50',
    },
    {
        cost_component: 'Marble print panels',
        details: '0.5 yard',
        unit_cost: '$2/yard',
        total: '$1.00',
    },
    {
        cost_component: 'Thread & notions',
        details: '',
        unit_cost: '$4',
        total: '$1',
    },
    {
        cost_component: 'Zipper & Hook Closure',
        details: '',
        unit_cost: '$0.50',
        total: '$0.50',
    },
    {
        cost_component: 'Labels',
        details: '2 labels',
        unit_cost: '$0.10',
        total: '$0.20',
    },

    {
        cost_component: 'Cutting time',
        details: '0.5 hrs',
        unit_cost: '$5/hr',
        total: '$2.50',
    },
    {
        cost_component: 'Sewing Time',
        details: '0.5 hrs',
        unit_cost: '$5/hr',
        total: '$2.50',
    },
    {
        cost_component: 'Finishing Time',
        details: '0.5 hrs',
        unit_cost: '$5/hr',
        total: '$2.50',
    },

    {
        cost_component: 'Poly bag',
        details: '',
        unit_cost: '$0.10',
        total: '$0.10',
    },
    {
        cost_component: 'Tissue paper',
        details: '',
        unit_cost: '$0.10',
        total: '$0.10',
    },
    {
        cost_component: 'Hanger',
        details: '',
        unit_cost: '$0.10',
        total: '$0.10',
    },

    {
        cost_component: 'Ocean freight from China',
        details: '',
        unit_cost: '$2',
        total: '$2',
    },

    {
        cost_component: 'Import duties',
        details: '',
        unit_cost: '$1',
        total: '$1',
    },
]
@Component({
    selector: 'item-dialog',
    templateUrl: './item.dialog.html',
    styleUrls: ['./item.dialog.scss'],
})
export class ItemDialog implements OnDestroy {
    public dataSource: ItemComponentI[] = []
    public itemTooltip: string = ''
    public selectedItemId: string = ''
    public showElements: boolean = false
    public formatString = formatString

    displayedColumns: string[] = ['name', 'quantity', 'unit_cost', 'total']
    columnsToDisplay: string[] = this.displayedColumns.slice()
    public moveToProjectControl = new FormControl('', [Validators.required])

    public allProjects$: Array<Project> = []
    public activeItem$: Item | null = null

    private _activeProject$: Project | undefined
    get activeProject$(): Project | undefined {
        return this._activeProject$
    }
    set activeProject$(value: Project | undefined) {
        this.moveItemToProject(value)
        this._activeProject$ = value
    }

    private subscription = new Subscription()
    public activeItem: Item | undefined
    public thumbnailURL = ''
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { itemId: string },
        private store: Store<AppState>,
        private snackbar: MatSnackBar,
        private dialogRef: DialogRef,
        private router: Router,
        private route: ActivatedRoute
    ) {
        this.selectedItemId = data.itemId
    }

    ngOnInit() {
        this.subscription.add(
            this.store.select(selectAllProjects).subscribe((allProjects) => {
                this.allProjects$ = allProjects
            })
        )
        this.subscription.add(
            this.store.select(selectActiveItem).subscribe((allProjects) => {
                this.activeItem$ = allProjects
                if (this.activeItem$?.components) {
                    this.dataSource = this.activeItem$?.components.map(
                        (component) => {
                            return {
                                ...component,
                                total:
                                    convertStringToNumber(component.quantity) *
                                    component.unit_cost,
                            }
                        }
                    )
                }
                if (this.activeItem$?.images) {
                    this.thumbnailURL = `${environment.CDN_URL}/${this.activeItem$.images[0].img_hash}-0.png`
                }
                if (this.activeItem$?.ml_explain) {
                    this.itemTooltip = this.activeItem$.ml_explain
                }
            })
        )
    }

    public likeItem(e: Event) {
        if (this.activeItem$?.id) {
            this.store.dispatch(
                likeItem({
                    payload: {
                        itemId: this.activeItem$.id,
                        isLiked: !this.activeItem$.is_liked,
                    },
                })
            )
        }
        e.stopPropagation()
    }

    public moveItemToProject(project?: Project) {
        if (this.activeItem$?.id && project?.id) {
            this.store.dispatch(
                moveItemToProject({
                    payload: {
                        itemId: this.activeItem$.id,
                        projectId: project.id,
                    },
                })
            )
            this.snackbar.open(`Item was moved to ${project.name}`, '', {
                duration: 1300,
            })
        }
        this.dialogRef.close()
    }

    removeQueryParams(paramNames: string[]): void {
        const queryParams = { ...this.route.snapshot.queryParams }
        paramNames.forEach((paramName) => delete queryParams[paramName])
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams,
        })
    }

    generateComponents() {
        if (this.activeItem$?.id) {
            this.store.dispatch(
                generateItemComponents({
                    payload: { id: this.activeItem$?.id },
                })
            )
        }
    }

    ngOnDestroy(): void {
        this.removeQueryParams(['itemId'])
        this.subscription.unsubscribe()
    }
}

interface MocDataComponentItemDialog {
    cost_component: string
    details: string
    unit_cost: string
    total: string
}
