<div class="login-page">
    <div class="login-container">
        <div class="login-logo">
            <img src="assets/images/logo.png" alt="logo" />
        </div>
        <form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
            <div class="login-inputs">
                <mat-form-field class="mat-form-field" appearance="fill">
                    <mat-label class="label-color"
                        >Enter New Password</mat-label
                    >
                    <input
                        matInput
                        formControlName="newPassword"
                        type="password"
                    />
                    <mat-error
                        *ngIf="
                            resetPasswordForm
                                .get('newPassword')
                                ?.hasError('required')
                        "
                    >
                        New password is required
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="mat-form-field" appearance="fill">
                    <mat-label class="label-color"
                        >Confirm New Password</mat-label
                    >
                    <input
                        matInput
                        formControlName="confirmPassword"
                        type="password"
                    />
                    <mat-error
                        *ngIf="
                            resetPasswordForm
                                .get('confirmPassword')
                                ?.hasError('required')
                        "
                    >
                        Confirm password is required
                    </mat-error>
                </mat-form-field>
                <mat-error
                    *ngIf="
                        resetPasswordForm
                            .get('confirmPassword')
                            ?.hasError('mismatch') &&
                        resetPasswordForm.get('newPassword')?.dirty &&
                        resetPasswordForm.get('confirmPassword')?.dirty
                    "
                >
                    Passwords do not match
                </mat-error>
                <button
                    class="login-button"
                    mat-raised-button
                    color="primary"
                    type="submit"
                    [disabled]="resetPasswordForm.invalid || isLoading"
                >
                    <span>Change Password</span>
                    <mat-spinner
                        *ngIf="isLoading"
                        class="ml-2"
                        [diameter]="20"
                    ></mat-spinner>
                </button>
            </div>
        </form>
    </div>
</div>
