<div class="login-page">
    <div class="login-container">
        <div class="login-logo">
            <img src="assets/images/logo.png" alt="logo" />
        </div>
        <form [formGroup]="loginForm">
            <div class="login-inputs">
                <mat-form-field class="mat-form-field" appearance="fill">
                    <mat-label class="label-color">Enter Email</mat-label>
                    <input matInput formControlName="email" type="email" />
                    <mat-error
                        *ngIf="loginForm.get('email')?.hasError('required')"
                    >
                        Email is required
                    </mat-error>
                    <mat-error
                        *ngIf="loginForm.get('email')?.hasError('email')"
                    >
                        Email should be a valid email
                    </mat-error>
                </mat-form-field>
                <mat-form-field class="mat-form-field" appearance="fill">
                    <mat-label class="label-color">Enter Password</mat-label>
                    <input
                        matInput
                        formControlName="password"
                        type="password"
                    />
                    <mat-error
                        *ngIf="loginForm.get('password')?.hasError('required')"
                    >
                        Password is required
                    </mat-error>
                </mat-form-field>
                <div
                    class="d-flex justify-content-end forgot-password pointer"
                    (click)="goToForgotPassword()"
                >
                    Forgot Password?
                </div>
                <button
                    mat-raised-button
                    class="login-button"
                    color="primary"
                    [disabled]="isLoading"
                    (click)="loginRequest()"
                >
                    <span>Login</span>
                    <mat-spinner
                        *ngIf="isLoading"
                        class="ml-2"
                        [diameter]="20"
                    ></mat-spinner>
                </button>
            </div>
        </form>
    </div>
</div>
