<div class="sidebar-container d-flex flex-column justify-content-between">
    <div>
        <div
            class="page-link pointer"
            routerLinkActive="hovered"
            [class.active]="currentPage == 'top-attributes'"
            [routerLinkActiveOptions]="{ exact: false }"
            (click)="goTo('/top-attributes')"
        >
            <div class="d-flex align-items-center">
                <img
                    src="../../../../assets/images/icons/design-board-icon-official.svg"
                    alt=""
                />
                <span>Trends</span>
            </div>
        </div>
        <div
            class="page-link pointer"
            routerLinkActive="hovered"
            [class.active]="currentPage == 'gallery'"
            [routerLinkActiveOptions]="{ exact: false }"
            (click)="goTo('/gallery')"
        >
            <div class="d-flex align-items-center">
                <img
                    src="../../../../assets/images/icons/design-gallery-icon-official.svg"
                    alt=""
                />
                <span>Design Gallery</span>
            </div>
        </div>
    </div>
    <div
        *ngIf="isAdmin"
        class="page-link align-self-end pointer"
        routerLinkActive="hovered"
        [class.active]="currentPage == 'manage-users'"
        [routerLinkActiveOptions]="{ exact: false }"
        (click)="goTo('/manage-users')"
    >
        <div class="d-flex align-items-center">
            <mat-icon class="settings-icon">settings</mat-icon>
            <span> Manage Users</span>
        </div>
    </div>
</div>
